<p class="chat-text">
  I love that! Now we just need a good name for it.
</p>

<div class="chat-white-container">
  <input (keydown.enter)="send()" [(ngModel)]="songName"
         [readOnly]="!currentActiveComponent"
         [readonly]="!currentActiveComponent"
         placeholder="I bet it's a good name :)"
         sbzCustomAutoFocus
         type="text">
  <button (click)="send()"
          [class.chat-button-generic-disabled]="!currentActiveComponent || (isUploading$ | async)"
          class="gradient-purple-button sm:!w-1/5">
    Send
  </button>
</div>

@if (isUploading$ | async) {
  <sbz-chat-loading-bar text="Saving song’s name..." [isLoading]="currentActiveComponent"></sbz-chat-loading-bar>
}
