import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-pre-upload-notice',
  templateUrl: './chat-pre-upload-notice.component.html',
  styleUrl: './chat-pre-upload-notice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatPreUploadNoticeComponent extends BaseChatComponent {
}
