import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {UserStateService} from '../services/user-state.service';

export const LoginGuard: CanActivateFn = (route, state) => {
  const userStateService = inject(UserStateService);
  const router = inject(Router);

  const user = userStateService.user$.getValue();
  const isTokenValid = !!user?.token;
  const isFinishedRegistration = user?.finishedRegistration;
  const isChatRoute = location.href.includes('/chat');
  const isLoginRoute = location.href.includes('/login') || location.href.includes('/register');

  if (isChatRoute && !isFinishedRegistration) {
    router.navigate(['/login']).then();
    console.log('LoginGuard: Access denied');
    return false;
  }

  if (isLoginRoute && isTokenValid && isFinishedRegistration) {
    router.navigate(['']).then();
    console.log('LoginGuard: Access denied');
    return false;
  }

  if (isTokenValid && !isFinishedRegistration) {
    return true;
  }

  if (!user) {
    return true;
  }

  console.log('LoginGuard: Access denied');
  return false;
};
