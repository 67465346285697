import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../../animations/fade-in-animation';

@Component({
  selector: 'sbz-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrl: './terms-of-use.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsOfUseComponent {

}
