<p class="chat-text">
  Ready to set the vibe? Choose the genre that will shape your song’s final sound!
</p>

<div class="chat-button-container !mb-[8px]">
  @for (genre of genres; track genre) {
    @if (genre === 'CHRISTMAS SONG!') {
      <button (click)="selectGenre(genre)" [class.selected-genre]="selectedGenre === genre"
              [class.chat-button-generic-disabled]="!currentActiveComponent && selectedGenre !== genre"
              class="chat-button-generic !w-fit !max-w-fit !min-w-fit christmas-button !px-[25px]">
        {{ genre }}
        <img class="button-hat"
             src="assets/christmas-hat.png"
             alt="christmas hat">
      </button>
    } @else {
      <button (click)="selectGenre(genre)" [class.selected-genre]="selectedGenre === genre"
              [ngClass]="{'!w-fit !max-w-fit sm:!w-fit sm:!max-w-fit 2xl:!w-fit 2xl:!max-w-fit': genre === 'Rhythm & Soul'}"
              [class.chat-button-generic-disabled]="!currentActiveComponent && selectedGenre !== genre"
              class="chat-button-generic !min-w-[153px] !max-w-[153px] sm:!min-w-[136px] sm:!max-w-[136px]
             2xl:!min-w-[153px] 2xl:!max-w-[153px] !px-[25px]">
        {{ genre }}
      </button>
    }
  }
</div>

<!--<div class="chat-white-container !mb-[4px]">-->
<!--  <input (keydown.enter)="send()" [(ngModel)]="promptValue"-->
<!--         [readOnly]="!currentActiveComponent || (isUploading$ | async)"-->
<!--         maxlength="200"-->
<!--         placeholder="Example: Modern country style, happy vibes"-->
<!--         sbzCustomAutoFocus-->
<!--         type="text">-->
<!--  <button (click)="send()"-->
<!--          [class.chat-button-generic-disabled]="!currentActiveComponent || (isUploading$ | async)"-->
<!--          class="gradient-purple-button sm:!w-1/5">-->
<!--    Send-->
<!--  </button>-->
<!--</div>-->

@if (isUploading$ | async) {
  <sbz-chat-loading-bar text="Analyzing your choice..." [isLoading]="currentActiveComponent"></sbz-chat-loading-bar>
}
