import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ModalService} from '../../../services/modal.service';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'sbz-eula-modal',
  templateUrl: './eula-modal.component.html',
  styleUrl: './eula-modal.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EulaModalComponent {
  constructor(public modalService: ModalService, private userStateService: UserStateService) {
  }

  acceptEula(accept: boolean) {
    if (accept) {
      this.userStateService.acceptedEula();
    }
    this.modalService.closeEulaModal();
  }

  readEula() {
    const width = 800;
    const height = 600;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    window.open('/assets/SaaS_Agreement.pdf', '_blank',
      `width=${width},height=${height},top=${top},left=${left}`);
  }
}
