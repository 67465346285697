import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'sbz-final-result-modal',
  templateUrl: './final-result-modal.component.html',
  styleUrl: './final-result-modal.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalResultModalComponent {
  constructor(public modalService: ModalService) {
  }
}
