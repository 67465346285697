import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ChatWelcomeComponent} from '../chat-parts/chat-welcome/chat-welcome.component';
import {ChatStartSessionComponent} from '../chat-parts/chat-start-session/chat-start-session.component';
import {ComponentMap} from '../../../interfaces/component-map';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ChatService} from '../../../services/chat.service';
import {ChatBubble} from '../../../interfaces/chat-bubble';
import {BehaviorSubject} from 'rxjs';
import {AudioService} from '../../../services/audio.service';
import {ChatPromptInputComponent} from '../chat-parts/chat-prompt-input/chat-prompt-input.component';
import {ChatStartEditorComponent} from '../chat-parts/chat-start-sketch-editor/chat-start-editor.component';
import {ChatLyricsInputComponent} from '../chat-parts/chat-lyrics-input/chat-lyrics-input.component';
import {ChatJumpComponent} from '../chat-parts/chat-jump/chat-jump.component';
import {ChatSongNameComponent} from '../chat-parts/chat-song-name/chat-song-name.component';
import {ChatSongLoadingComponent} from '../chat-parts/chat-song-loading/chat-song-loading.component';
import {MobileDetectionService} from '../../../services/mobile-detection.service';
import {ScrollService} from '../../../services/scroll.service';
import {ChatFinalResultsComponent} from '../chat-parts/chat-final-results/chat-final-results.component';
import {UserStateService} from '../../../services/user-state.service';
import {ChatSendEmailComponent} from '../chat-parts/chat-send-email/chat-send-email.component';
import {ModalService} from '../../../services/modal.service';
import {ChatPreUploadNoticeComponent} from '../chat-parts/chat-pre-upload-notice/chat-pre-upload-notice.component';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrl: './chat-container.component.scss',
  animations: [fadeTransition]
})
export class ChatContainerComponent implements OnInit, OnDestroy {
  @ViewChild('chatTopOpacity', {read: ElementRef, static: true}) chatTopOpacity!: ElementRef;
  showStartNewChat$ = new BehaviorSubject(false);
  showSketchId$: BehaviorSubject<undefined | string> = new BehaviorSubject<string | undefined>(undefined);
  chatComponentsMap: ComponentMap = {
    'welcome': ChatWelcomeComponent,
    'jump': ChatJumpComponent,
    'pre-upload-notice': ChatPreUploadNoticeComponent,
    'start-session': ChatStartSessionComponent,
    'start-sketch-editor': ChatStartEditorComponent,
    'prompt-input': ChatPromptInputComponent,
    'song-name': ChatSongNameComponent,
    'lyrics-input': ChatLyricsInputComponent,
    'song-loading': ChatSongLoadingComponent,
    'final-results': ChatFinalResultsComponent,
    'send-email': ChatSendEmailComponent
  };
  chatBubbles: ChatBubble[] = [];

  private firstLoad = true;
  private stopOnScroll = false;

  private sClickCount = 0;
  private lastClickTime = 0;
  private maxTimeBetweenClicks = 500;

  constructor(private chatService: ChatService,
              private audioService: AudioService,
              private mobileDetectionService: MobileDetectionService,
              private scrollService: ScrollService,
              private cdr: ChangeDetectorRef,
              private userStateService: UserStateService,
              private modalService: ModalService) {
  }

  ngOnInit() {
    this.startNewChat();
    this.chatService.currentlyActiveChatPart$.subscribe(componentName => {
      if (!this.stopOnScroll) {
        this.createChatBubble(componentName);
        if (this.firstLoad) {
          setTimeout(() => {
            this.chatService.currentlyActiveChatPart$.next('jump');
            this.cdr.markForCheck();
            this.cdr.detectChanges();
          }, 2500);
          this.firstLoad = false;
        }
      }
    });

    this.chatService.createUserBubbleWIthText$
      .subscribe(data => {
        if (data && !this.stopOnScroll) {
          this.createUserChatBubble(data);
          if (data.event === 'learn-more-produce') {
            setTimeout(() => {
              this.chatService.currentlyActiveChatPart$.next('ready-produce-learn');
              this.cdr.detectChanges();
            }, 2500);
          }
        }
      });

    this.scrollService.scrollToBottomClicked$
      .subscribe((scroll) => {
        if (scroll) {
          this.scrollToBottom();
        }
      });

    this.userStateService.startNewChatFromModal$
      .subscribe((startNewChatFromModal) => {
        if (startNewChatFromModal) {
          this.startNewChat();
          this.userStateService.startNewChatFromModal$.next(false);
        }
      });

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      const currentTime = Date.now();

      // Check if the pressed key is "S"
      if (event.key.toLowerCase() === 's') {
        // Check if this click happened within the allowed time frame (500ms)
        if (currentTime - this.lastClickTime <= this.maxTimeBetweenClicks) {
          this.sClickCount++;
        } else {
          // If more than 500ms passed, reset the count
          this.sClickCount = 1;
        }

        // Update lastClickTime
        this.lastClickTime = currentTime;

        // Check if the count reached 3
        if (this.sClickCount === 3) {
          if (this.audioService.promptSketchId !== '') {
            if (this.showSketchId$.getValue() === undefined) {
              this.showSketchId$.next(this.audioService.promptSketchId);
            } else {
              this.showSketchId$.next(undefined);
            }
          }
          // Reset count after success
          this.sClickCount = 0;
        }
      }
    });
  }

  ngOnDestroy() {
    this.stopOnScroll = true;
    this.startNewChat();
    this.sClickCount = 0;
    this.lastClickTime = 0;
    this.showSketchId$.next(undefined);
  }

  popUpAlertForNewChat() {
    this.modalService.openNewChatModal();
  }

  startNewChat() {
    this.audioService.stopSong();
    this.audioService.resetAudioService();
    this.chatBubbles = [];
    this.firstLoad = true;
    this.chatService.resetChatService();
  }

  private createUserChatBubble(data: { event: string; text: string }) {
    this.chatBubbles.push({
      type: data.text,
      isLoading$: new BehaviorSubject<boolean>(true),
      isUserBubble: true
    });
    setTimeout(() => {
      this.createChatBubble(data.event);
      this.cdr.detectChanges();
    }, 300);
  }

  private createChatBubble(event: string) {
    this.showStartNewChat$.next(event === 'final-results');

    if (!this.stopOnScroll) {
      const componentType = this.chatComponentsMap[event];
      if (this.chatBubbles.length > 0) {
        const prev = this.chatBubbles[this.chatBubbles.length - 1];
        if (prev.component === componentType) {
          return;
        }
        if (prev.isLoading$.getValue()) {
          prev.isLoading$.next(false);
        }
      }
      this.chatBubbles.push({
        type: event,
        component: componentType,
        isLoading$: new BehaviorSubject<boolean>(true),
        isUserBubble: false
      });
      this.scrollToBottom();
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      setTimeout(() => {
        this.chatBubbles[this.chatBubbles.length - 1].isLoading$.next(false);
        this.scrollToBottom();
      }, 1500);
    }
  }

  private scrollToBottom() {
    setTimeout(() => {
      if (this.chatBubbles.length < 3 && (this.mobileDetectionService.isMobile$.getValue())) {
        return;
      }

      if (this.mobileDetectionService.isMobile$.getValue()) {
        const mainContent = document.getElementById('main-content')!;
        mainContent.scrollTo({
          top: mainContent.scrollHeight,
          behavior: 'smooth'
        });
        return;
      }

      const chatContainer = document.getElementById('chat-container')!;
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  }
}
