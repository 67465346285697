<footer [@fadeTransition]>
  <div class="session-small-logo">
    <img [routerLink]="'/'" alt="Image distribute 1" class="cursor-pointer invert" fill
         ngSrc="../../../assets/images/logo.webp" priority>
  </div>
  <div class="socials-links">
    <a [routerLink]="'/articles/terms-of-use'" class="flex items-center">Terms of Use</a>
    <a [routerLink]="'/contact'" class="flex items-center">Contact Us</a>
    <a href="https://www.youtube.com/@HitCraft_ai" target="_blank">
      <img src="../../../assets/images/footer/yb.webp">
    </a>
    <a href="https://www.facebook.com/profile.php?id=61561797403318" target="_blank">
      <img src="../../../assets/images/footer/fb.webp">
    </a>
    <a href="https://www.instagram.com/hitcraft.ai/" target="_blank">
      <img src="../../../assets/images/footer/in.webp">
    </a>
    <a href="https://www.tiktok.com/@hitcraftai" target="_blank">
      <img src="../../../assets/images/footer/tik.webp">
    </a>
    <a href="https://x.com/Hitcraft_ai" target="_blank">
      <img src="../../../assets/images/footer/x.webp">
    </a>
  </div>
</footer>
