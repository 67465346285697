<p class="chat-text">
  It's time to pick the genre for the produced song you're about to get
</p>

<div class="chat-button-container !mb-[8px]">
  @for (genre of genres; track genre) {
    <button (click)="selectGenre(genre)" [class.selected-genre]="selectedGenre === genre"
            [class.chat-button-generic-disabled]="!currentActiveComponent && selectedGenre !== genre"
            class="chat-button-generic !min-w-[153px] !max-w-[153px] sm:!min-w-[126px] sm:!max-w-[126px] 2xl:!min-w-[153px] 2xl:!max-w-[153px] !px-[25px]">
      {{ genre }}
    </button>
  }
</div>

<!--<div class="chat-white-container !mb-[4px]">-->
<!--  <input (keydown.enter)="send()" [(ngModel)]="promptValue"-->
<!--         [readOnly]="!currentActiveComponent || (isUploading$ | async)"-->
<!--         maxlength="200"-->
<!--         placeholder="Example: Modern country style, happy vibes"-->
<!--         sbzCustomAutoFocus-->
<!--         type="text">-->
<!--  <button (click)="send()"-->
<!--          [class.chat-button-generic-disabled]="!currentActiveComponent || (isUploading$ | async)"-->
<!--          class="gradient-purple-button sm:!w-1/5">-->
<!--    Send-->
<!--  </button>-->
<!--</div>-->

@if (isUploading$ | async) {
  <sbz-chat-loading-bar text="Analyzing your choice..." [isLoading]="currentActiveComponent"></sbz-chat-loading-bar>
}
