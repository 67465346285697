@if (modalService.isPromptLandingModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container landing-modal">
      <h2 class="modal-header">Ah, <strong class="font-bold">shoot</strong></h2>
      <p [innerHTML]="modalService.promptLandingMessage | safeHtml" class="modal-content"></p>
      <button (click)="modalService.closeLandingPromptModal()"
              class="main-button-design gradient-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px]">
        OK
      </button>
    </div>
  </div>
}
