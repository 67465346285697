import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-send-email',
  templateUrl: './chat-send-email.component.html',
  styleUrl: './chat-send-email.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatSendEmailComponent extends BaseChatComponent {
}
