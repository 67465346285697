import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject, takeUntil} from 'rxjs';
import {ModalService} from '../../../../services/modal.service';
import {ChatService} from '../../../../services/chat.service';
import {AudioService} from '../../../../services/audio.service';
import {UserStateService} from '../../../../services/user-state.service';
import {ScrollService} from '../../../../services/scroll.service';
import {ChatLoadingBarComponent} from '../chat-loading-bar/chat-loading-bar.component';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
  selector: 'sbz-start-session',
  templateUrl: './chat-start-session.component.html',
  styleUrl: './chat-start-session.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatStartSessionComponent extends BaseChatComponent {
  fileName$ = new BehaviorSubject<string | undefined>(undefined);
  isUploadingFile$ = new BehaviorSubject<boolean>(false);
  isAnalyzingFile$ = new BehaviorSubject<boolean>(false);
  fileUploadProgress$ = new BehaviorSubject<number | null>(null);
  title$ = new BehaviorSubject<string>(
    'Next, upload your vocal recording with playback as a single file, ensuring it is aligned with a metronome.');
  @ViewChild(ChatLoadingBarComponent) loadingBarComponent!: ChatLoadingBarComponent;

  constructor(modalService: ModalService,
              chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService,
              scrollService: ScrollService) {
    super(chatService, audioService, userStateService, modalService, scrollService);
  }

  startFileUpload() {
    if (this.isDemoSite) {
      return;
    }
    const userAcceptedEula = localStorage.getItem('userAcceptedEula');
    if (!this.userStateService.hasUserAcceptedEula() && !userAcceptedEula) {
      this.modalService.openEulaModal();
      return;
    }
    this.userStateService.startFileUpload()
      .then((file) => {
        this.fileName$.next(file.name);
        // this.audioService.createAudioFileFromSketch(URL.createObjectURL(file));
        if (!this.userStateService.user$.getValue()) {
          this.modalService.openErrorModal('You have to be a registered user and you have to be logged in.');
          return;
        }

        this.isUploadingFile$.next(true);
        this.scrollService.scrollToBottomClicked$.next(true);

        this.userStateService.uploadSketch(file)
          .pipe(takeUntil(this.userStateService.getCancelHttpRequest$()))
          .subscribe({
            next: (event: HttpEvent<any>) => {
              if (event.type === HttpEventType.UploadProgress) {
                // Calculate the progress percentage
                const percentDone = Math.round(100 * event.loaded / (event.total ?? 1));
                // Update progress bar or indicator
                this.fileUploadProgress$.next(percentDone);
                if (percentDone === 100) {
                  this.isUploadingFile$.next(false);
                  this.isAnalyzingFile$.next(true);
                }
              } else if (event.type === HttpEventType.Response) {
                // Handle the final response
                const data = event.body;
                this.audioService.sketchBpm = data.bpm;
                if (data.sketch_id) {
                  this.audioService.promptSketchId = data.sketch_id;
                  this.loadingBarComponent.finishLoading();
                  setTimeout(() => {
                    this.isAnalyzingFile$.next(false);
                    this.isUploadingFile$.next(false);
                    this.goToNext('after-song-upload');
                  }, 3000);
                } else {
                  this.showError('We could not upload your song. Please try again.');
                }
              }
            },
            error: (error: any) => {
              this.showError('We could not upload your song. Please try again.');
            }
          });
      })
      .catch(err => {
        this.showError(err);
      });
  }

  protected override afterViewInit() {
    super.afterViewInit();
    if (this.isDemoSite) {
      this.fileName$.next('sketch.mp3');
      this.scrollService.scrollToBottomClicked$.next(true);
      setTimeout(() => {
        this.isUploadingFile$.next(false);
        this.audioService.promptSketchId = this.audioService.promptMockSketchId;
        this.goToNext('after-song-upload');
      }, this.fakeCallsTimeout);
    }
  }

  private showError(title: string) {
    this.isUploadingFile$.next(false);
    this.isAnalyzingFile$.next(false);
    this.title$.next(title);
  }
}
