import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {SketchResult} from '../../../../interfaces/sketch-result';

@Component({
  selector: 'sbz-chat-results-players',
  templateUrl: './chat-results-players.component.html',
  styleUrl: './chat-results-players.component.scss'
})
export class ChatResultsPlayersComponent extends BaseChatComponent {
  currentlyPlayingSongName = '';
  sketchResults: SketchResult[] = [];
  loadingSongId: string | null = null;
  failedDynamicButcherResponses = 0;
  isLoadingFinished = false; // New flag to track loading state

  private cdr = inject(ChangeDetectorRef);

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.audioService.dynamicButcherResponses$.subscribe({
      next: value => {
        if (value) {
          this.addNewResults(value);
          this.scrollService.scrollToBottomClicked$.next(true);
        }
      }
    });

    this.audioService.dynamicButcherResponsesFinished$.subscribe({
      next: finished => {
        if (finished) {
          this.failedDynamicButcherResponses = this.audioService.failedDynamicButcherResponses$.getValue();
          this.isLoadingFinished = true; // Set the flag to true when loading is finished
          this.cdr.detectChanges();
        }
      }
    });
  }

  toggleSong(result: SketchResult) {
    if (result.id === this.currentlyPlayingSongName) {
      this.audioService.togglePlayPause();
      this.currentlyPlayingSongName = '';
    } else {
      this.loadingSongId = result.id;  // Set the loading state
      this.audioService.createAudioFileFromUrl(result.url);

      // Once the audio is ready, play it and clear the loading state
      this.audioService.playSong(result.id, true).then(() => {
        this.currentlyPlayingSongName = result.id;
        this.loadingSongId = null; // Clear the loading state
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      }).catch(error => {
        console.error('Error playing the audio file:', error);
        this.loadingSongId = null; // Clear the loading state on error
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      });
    }
  }

  getOption(index: number, isMystery: boolean = false) {
    if (isMystery) {
      return 'Mystery option';
    }
    switch (index) {
      case 0:
        return 'Option One';
      case 1:
        return 'Option Two';
      case 2:
        return 'Option Three';
      default:
        return 'Mystery option';
    }
  }

  produceSong(result: SketchResult) {
    this.currentlyPlayingSongName = '';
    this.loadingSongId = null;
    this.audioService.togglePlayPause();
    this.audioService.chosenSketchResult = result;
    this.audioService.produceTrackClicked = true;
    this.goToNext('final-song');
  }

  getClass(isMystery: boolean) {
    return isMystery;
  }

  private addNewResults(newResults: SketchResult[]) {
    const existingResultsMap = new Map(this.sketchResults.map(result => [result.id, result]));
    let isNewResultAdded = false;

    newResults.forEach(newResult => {
      if (!existingResultsMap.has(newResult.id)) {
        this.sketchResults.push(newResult);
        existingResultsMap.set(newResult.id, newResult);
        isNewResultAdded = true;
      }
    });

    if (isNewResultAdded) {
      this.moveMysteryToEnd();
      this.cdr.detectChanges();
    }
  }

  private moveMysteryToEnd() {
    const mysteryIndex = this.sketchResults.findIndex(result => result.isMystery);
    if (mysteryIndex > -1 && mysteryIndex !== this.sketchResults.length - 1) {
      const mysteryResult = this.sketchResults.splice(mysteryIndex, 1)[0];
      this.sketchResults.push(mysteryResult);
    }
  }
}
