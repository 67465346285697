import {Injectable} from '@angular/core';
import {DescopeAuthService} from '@descope/angular-sdk';
import {jwtDecode} from 'jwt-decode';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {User} from '../interfaces/user';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoadingService} from './loading.service';
import {ModalService} from './modal.service';
import {catchError, finalize, map, tap} from 'rxjs/operators';

export enum Roles {
  Admin = 'Tenant Admin'
}

const ADMINS = ['yadin@session-42.com', 'sandman@session42.ai', 'illia.h@5bsoftware.com'];

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  startNewChatFromModal$ = new BehaviorSubject<boolean>(false);
  userNeedToVerifyEmail$ = new BehaviorSubject<boolean>(false);
  userNeedToRegister$ = new BehaviorSubject<boolean>(true);
  user$ = new BehaviorSubject<User | null>(null);
  descopeUser$ = new BehaviorSubject<any | null>(null);
  sessionToken$ = new BehaviorSubject<string | null>(null);
  isWhiteList$ = new BehaviorSubject<boolean | null>(null);
  logo$ = new BehaviorSubject<string>('assets/images/betalogo.png')!;
  isSuperAdmin = new BehaviorSubject<boolean>(false);
  private cancelHttpRequest$ = new Subject<void>();

  constructor(private authService: DescopeAuthService,
              private router: Router,
              private httpClient: HttpClient,
              private loadingService: LoadingService,
              private modalService: ModalService) {
    this.authService.session$.subscribe((session) => {
      this.authService.user$.pipe(
        map(user => user)
      ).subscribe(user => {
        this.descopeUser$.next(user.user);
      });
      console.log('session.sessionToken: ', session);
      if (session.sessionToken) {
        this.updateUserFromDescope(session.sessionToken);
      }
    });
  }

  // Method to trigger cancellation
  cancelHttpRequest() {
    this.cancelHttpRequest$.next();
  }

  getCancelHttpRequest$() {
    return this.cancelHttpRequest$;
  }

  changeImage() {
    const imageElement = document.querySelector('#logo')! as HTMLImageElement;
    const navBarContainer = document.querySelector('.nav-bar-container')! as HTMLDivElement;
    let currentPaddingBottom = parseFloat(window.getComputedStyle(navBarContainer).paddingBottom);

    imageElement.style.opacity = '0';

    const currentHeight = imageElement.offsetHeight;
    setTimeout(() => {
      navBarContainer.style.paddingBottom = (currentPaddingBottom - 20) + 'px';
      // imageElement.style.height = `${currentHeight + 20}px`;
      this.logo$.next('assets/images/betalogo.png');
      imageElement.style.opacity = '1';
    }, 300);
  }

  updateUserFromDescope(sessionToken: string, pictureUrl: string | null = null) {
    this.sessionToken$.next(sessionToken);
    const decodedJwt: any = jwtDecode(sessionToken);
    const verifiedEmail = this.descopeUser$.getValue()?.verifiedEmail;

    const userData = {
      ...decodedJwt,
      ...this.descopeUser$.getValue(),
      name: decodedJwt.displayName || this.descopeUser$.getValue()?.name,
      pictureUrl: decodedJwt.pictureUrl || this.descopeUser$.getValue()?.picture,
      roles: decodedJwt.roles || this.descopeUser$.getValue()?.roleNames,
      phone: decodedJwt.phone || this.descopeUser$.getValue()?.phone,
      customAttributes: {
        ...this.descopeUser$.getValue()?.customAttributes,
        isWhiteList: false,
        // isWhiteList: decodedJwt.isWhiteList || this.descopeUser$.getValue()?.isWhiteList,
        finishedRegistration: decodedJwt.finishedRegistration || this.descopeUser$.getValue()?.finishedRegistration || this.descopeUser$.getValue()?.customAttributes?.finishedRegistration,
        pendingVerification: decodedJwt.pendingVerification || this.descopeUser$.getValue()?.customAttributes?.pendingVerification,
        customEmail: this.descopeUser$.getValue()?.customAttributes?.customEmail,
      },
    };

    console.log('decodedJwt: ', decodedJwt);
    console.log('this.descopeUser$.getValue(): ', this.descopeUser$.getValue());

    this.user$.next({
      loginId: this.descopeUser$.getValue()?.loginIds[0],
      email: userData?.customAttributes?.customEmail || userData.email,
      phone: userData.phone,
      displayName: userData.name,
      youtubeChannel: userData.youtubeChannel,
      instagramMusicProfile: userData.instagramMusicProfile,
      spotifyProfileLink: userData.spotifyProfileLink,
      appleMusicProfile: userData.appleMusicProfile,
      origin: userData.customAttributes.origin,
      pictureUrl: pictureUrl ? pictureUrl : userData.pictureUrl,
      finishedRegistration: userData.customAttributes.finishedRegistration,
      token: userData.sub,
      userHasSubscribed: userData.userHasSubscribed,
      selectedPackageId: userData.selectedPackageId,
      isWhiteList: userData.customAttributes.isWhiteList,
      queuePosition: userData.queuePosition,
      roles: userData.roles,
      userRoles: userData.userRoles,
      userAcceptedEula: userData.customAttributes.userAcceptedEula,
      verifiedEmail,
    });

    if ((!userData.email || userData.customAttributes.pendingVerification) && !userData.customAttributes.finishedRegistration) {
      this.userNeedToVerifyEmail$.next(true);
    }

    // const userNeedToRegister = !decodedJwt.email || !decodedJwt.phone || !decodedJwt.finishedRegistration || !verifiedEmail;
    let userNeedToRegister = !userData.email || !userData.phone || !userData.customAttributes.finishedRegistration;
    if (userData.customAttributes.finishedRegistration) {
      userNeedToRegister = false;
    }

    this.userNeedToRegister$.next(userNeedToRegister);
    this.isWhiteList$.next(userData.customAttributes.isWhiteList);
    this.isSuperAdmin.next(ADMINS.indexOf(userData.email) >= 0);

    const url = location.href;
    if (url.includes('verify-email')) {
      return;
    }

    if (localStorage.getItem('t')) {
      console.log('t: ', localStorage.getItem('t'));
      return;
    }


    console.log('userNeedToRegister: ', userNeedToRegister);

    if (!userNeedToRegister) {
      this.router.navigate(['/']);
      // if (userData.customAttributes.isWhiteList) {
      //   this.router.navigate(['/']); // changed to navigate user from queue to home page
      //   return;
      // } else {
      //   if (url.includes('/login')) {
      //     this.router.navigate(['/']).then();
      //   }
      //   // if (!url.includes('/chat')
      //   //   && !url.includes('/verify-email')
      //   //   && !url.includes('/demo')
      //   //   && !url.includes('/pre-alpha')) {
      //   //   this.router.navigate(['/']).then();
      //   // }
      //   return;
      // }
      // if (!decodedJwt.userHasSubscribed) {
      //   this.router.navigate(['/subscribe']).then();
      // } else {
      //   console.log('location.href: ', location.href);
      //   if (!location.href.includes('/chat')) {
      //     this.router.navigate(['']).then();
      //   }
      // }
    } else {
      // this.router.navigate(['/login']);
    }
  }

  updateUserFromRegister(user: User) {
    this.user$.next(user);
    this.userNeedToRegister$.next(false);
    location.reload();
  }

  hasUserAcceptedEula(): boolean {
    const user = this.user$.getValue();
    if (!user) {
      return false;
    }
    return user.userAcceptedEula;
  }

  loginWithEmail(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.signIn(email, password)
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Login error: ', error);
            observer.error(error);
          }
        });
    });
  }

  registerWithEmail(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.signUp(
        email,
        password,
        {email},
        {
          templateOptions: {
            subject: 'Verify Your Email for Our App',
            text: 'Click the link below to verify your email for Our App:',
            domain: location.origin,
          }
        }
      )
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Registration error: ', error);
            observer.error(error);
          }
        });
    });
  }

  updatePassword(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.update(email, password)
        .subscribe({
          next: (response) => {
            console.log('updatePassword response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Update password error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendMfaCodeToEmail(loginId: string, email: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.otp.update.email(loginId, email, undefined, {
        templateOptions: {
          domain: location.origin,
          'deviceOS': 'Value',
          'startHostName': 'Value2',
        }
      })
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('MFA code error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendMfaCodeToRegisteredEmail(email: string): Observable<any> {
    return new Observable((observer) => {
      // this.authService.descopeSdk.otp.signIn.email(email)
      this.authService.descopeSdk.password.sendReset(
        email,
        location.origin + '/login',
        {
          redirectUrl: location.origin + '/login',
          'deviceOS': 'Value',
          'startHostName': 'Value2',
        })
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('MFA code error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendPasswordResetEmail(email: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.sendReset(email)
        .subscribe({
          next: (response) => {
            alert('Password reset email sent! Please check your inbox.');
          },
          error: (error: any) => {
            console.error('Error sending password reset email:', error);
            alert('Failed to send password reset email.');
          }
        });
    });
  }

  verifyEmailCode(loginId: string, code: string): Observable<any> {

    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/verify/confirm-email?loginId=${loginId}&code=${code}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.post(url, {}, options).pipe(
      tap((response) => {
        console.log('Response:', response);
        // Optionally perform additional actions, such as updating the user
        // localStorage.setItem('userAcceptedEula', 'true');
        // this.authService.refreshSession();
        // @ts-ignore
        // this.user$.next({...this.user$.getValue(), userAcceptedEula: true});
      }),
      catchError((err: any) => {
        console.error('Error:', err);
        // this.modalService.openErrorModal('We could not update user info. Please try again later.');
        return err; // Ensure error is re-thrown after being handled
      }),
      finalize(() => {
        this.loadingService.toggleLoading(false); // Ensure loading is turned off
      })
    );
    // return this.authService.descopeSdk.otp.verify.email(loginId, code);
  }

  verifyMfaCode(email: string, code: string): Observable<any> {
    return this.authService.descopeSdk.otp.verify.email(email, code);
  }

  verifyMagicLink(code: string): Observable<any> {
    const data = this.authService.descopeSdk.magicLink.verify(code);
    console.log('data: ', data);

    return data;
  }

  loginWithFacebook() {
    this.authService.descopeSdk.oauth.start('facebook', location.origin + '/login').subscribe({
      next: (response) => {
        window.location.href = response?.data?.['url'];
      },
      error: (error) => {
        console.error('error: ', error);
      }
    });
  }

  loginWithGoogle() {
    return this.authService.descopeSdk.oauth.start('google', location.origin + '/login').subscribe({
      next: (response) => {
        window.location.href = response?.data?.['url'];
      },
      error: (error) => {
        console.error('error: ', error);
      }
    });
  }

  handleOAuthResponse(code: string) {
    return new Promise((resolve, reject) => {
      this.authService.descopeSdk.oauth.exchange(code).subscribe({
        next: (response) => {
          console.log('handleOAuthResponse response: ', response);
          if (!response.ok) {
            console.error('Error during OAuth response handling:', response);
            reject(response);
          }
          resolve(response);
        },
        error: (error) => {
          console.error('Error during OAuth response handling:', error);
          reject(error);
        }
      });
    });
  }

  sendVerificationLink(email: string) {
    // this.authService.descopeSdk.password.sendSignUpLink(email, { redirectURL: 'https://your-app.com/verify-email' })
    //   .then(() => {
    //     console.log('Verification link sent successfully');
    //   })
    //   .catch((error) => {
    //     console.error('Error sending verification link: ', error);
    //   });
  }

  logoUt() {
    this.authService.descopeSdk.logout();
    this.user$.next(null);
  }

  checkUserInfoAndNavigate(): boolean {
    this.loadingService.toggleLoading(true);
    const userNeedToRegister = this.userNeedToRegister$.getValue();
    const user = this.user$.getValue();

    if (user?.userHasSubscribed) {
      this.loadingService.toggleLoading(false);
      return false;
    }

    this.loadingService.toggleLoading(false);

    if (location.href.includes('verify-email')) {
      return true;
    }

    if (userNeedToRegister || !user) {
      this.router.navigate(['/login']).then();
      return true;
    }

    if (user?.isWhiteList) {
      this.router.navigate(['']).then(); // changed to navigate user from queue to home page, prevously was '/queue' target
      return true;
    }

    // this.router.navigate(['/subscribe']).then();
    if (user && !user.isWhiteList) {
      this.router.navigate(['/subscribe']).then();
    }
    return true;
  }

  updateUserInfo(user: User) {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, user, options)
      .subscribe({
        next: () => {
          this.updateUserFromRegister(user);
          this.authService.refreshSession();
          this.router.navigate(['']).then();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }

  acceptedEula() {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/acceptedEula`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, {}, options)
      .subscribe({
        next: () => {
          localStorage.setItem('userAcceptedEula', 'true');
          this.authService.refreshSession();
          // @ts-ignore
          this.user$.next({...this.user$.getValue(), userAcceptedEula: true});
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }

  verifyNewEmail(email: string, token: string): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/mail/${token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.patch(url, { email }, options).pipe(
      tap({
        next: () => {
          this.loadingService.toggleLoading(false);
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not verify your email. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      })
    );
  }

  updateUserMetadata() {
    const url = `${environment.baseUrl}/users/location/${this.user$.getValue()?.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, {}, options)
      .subscribe({
        next: () => {
          this.authService.refreshSession();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          // this.loadingService.toggleLoading(false);
        }
      });
  }

  getUsersWhiteList(page: number): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/users/whitelist?page=${page}`);
  }

  updateUserWhiteList(user: any, sendEmail: boolean, access: boolean): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.userId}/whitelist`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.patch(url, {customAttributes: user.customAttributes, sendEmail, access}, options);
  }

  searchWhiteListUsers(query: string): Observable<any> {
    const url = `${environment.baseUrl}/users/whitelist/search`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.post(url, {query}, options);
  }

  refreshDescopeToken() {
    return this.authService.refreshSession();
  }

  grandAccesses(range: { from: number, to: number }, sendEmail: boolean): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/grand-access`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.post(url, {range, sendEmail}, options);
  }

  updateSubscriptionStatus(packageId: string) {
    const user = this.user$.getValue();
    if (user) {
      user.userHasSubscribed = true;
      user.selectedPackageId = packageId;
    }
  }

  startFileUpload(): Promise<File> {
    return new Promise((resolve, reject) => {
      // Create a hidden file input element
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'audio/mpeg, audio/wav'; // Accept audio files only
      // fileInput.accept = 'audio/*';
      fileInput.style.display = 'none'; // Hide the input element

      // Append the file input to the body
      document.body.appendChild(fileInput);

      // Add an event listener to handle the file selection
      fileInput.addEventListener('change', (event: any) => {
        const file = event.target.files[0];
        if (file) {
          // Check file size
          const MAX_SIZE = 50 * 1024 * 1024; // 50 MB in bytes
          if (file.size > MAX_SIZE) {
            reject(new Error('The file is too large. Maximum allowed size is 50 MB.'));
            fileInput.remove();
            return;
          }

          // Check audio duration
          this.getAudioDuration(file)
            .then((duration) => {
              const MAX_DURATION = 6 * 60; // 6 minutes in seconds
              if (duration > MAX_DURATION) {
                reject(new Error('The audio file is too long. Maximum allowed duration is 6 minutes.'));
              } else {
                resolve(file);
              }
              fileInput.remove();
            })
            .catch((error) => {
              console.error('Error getting audio duration:', error);
              reject(new Error('Could not read the audio file. Please try again with a valid audio file.'));
              fileInput.remove();
            });
        } else {
          reject(new Error('No file selected.'));
          fileInput.remove();
        }
      });

      // Programmatically click the file input
      fileInput.click();
    });
  }

  uploadSketch(file: File) {
    // Prepare form data
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('full_analysis', 'false');
    formData.append('user_id', this.user$.getValue()!.email);

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.sessionToken$.getValue()}`,
      'enctype': 'multipart/form-data'
    });

    // Send the POST request with progress reporting enabled
    return this.httpClient.post('https://sketch-upload.session42.xyz/sketch-upload', formData, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

  private getAudioDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        const audio = document.createElement('audio');
        audio.preload = 'metadata';

        // Create a URL for the audio file
        const objectUrl = URL.createObjectURL(file);
        audio.src = objectUrl;

        audio.onloadedmetadata = () => {
          // Chrome bug: audio.duration is NaN for certain audio types
          if (isNaN(audio.duration) || audio.duration === Infinity) {
            // Try to force the browser to calculate the duration
            audio.currentTime = Number.MAX_SAFE_INTEGER;
            audio.ontimeupdate = () => {
              audio.ontimeupdate = null;
              resolve(audio.duration);
              URL.revokeObjectURL(objectUrl);
            };
          } else {
            resolve(audio.duration);
            URL.revokeObjectURL(objectUrl);
          }
        };

        audio.onerror = (error) => {
          reject(error);
          URL.revokeObjectURL(objectUrl);
        };
      } catch (error) {
        reject(error);
      }
    });
  }
}
