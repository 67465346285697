<div [@fadeTransition] class="article-container">
  <div class="flex flex-col lg:flex-row lg:pt-[51px] pt-[80px]">
    <div class="article-content">
      <p class="hidden md:block article-content__title xl:-mt-[11px] -mt-[50px]">
        Our Mission
      </p>

      <p class="article-content__text">
        At HitCraft, our mission is to democratize music production by leveraging advanced AI technology. We aim to
        empower artists and producers by providing them with cutting-edge tools that enhance creativity, streamline the
        production process, and deliver top-quality music. By integrating seamlessly with the existing music industry
        and utilizing decades of innovation, HitCraft is dedicated to making professional music production accessible to
        all, opening new possibilities for artistic expression and collaboration.
      </p>

      <p class="article-content__subtitle">
        About the Product
      </p>
      <p class="article-content__text">
        HitCraft is the world's first AI-powered virtual producer, designed to revolutionize music creation. By
        seamlessly integrating with traditional production workflows, HitCraft harnesses the power of artificial
        intelligence to assist artists and producers in crafting high-quality tracks effortlessly. The platform
        continuously learns and evolves, utilizing a vast library of music to refine its capabilities. With HitCraft,
        the once-exclusive domain of professional music production is now accessible to all, enabling unparalleled
        creativity and collaboration in the music industry.
      </p>

      <p class="article-content__subtitle">
        About Session42
      </p>
      <p class="article-content__text">
        Session42 is on a mission to redefine the music industry through innovative technology and AI-driven solutions.
        The company was founded by internationally acclaimed producers Tal Forer, Yinon Yahel, and Stav Beger, along
        with music business insider Amit Shine and tech entrepreneur Oudi Antebi. With a foundation built on years of
        success as a traditional music label, we have evolved into a tech company focused on empowering artists. Our
        flagship products, HitCraft and DSTRO, streamline music production and distribution, providing artists with the
        tools to independently manage their creative and commercial endeavors. Led by a team of renowned producers,
        industry insiders, and tech entrepreneurs, Session42 is committed to democratizing the music industry and
        creating an autonomous ecosystem where every artist can thrive.
      </p>
    </div>
    <div class="articles">
      <div [routerLink]="'/contact'" class="action cursor-pointer">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">Contact Us:</p>
        <p class="hidden lg:block action--subtitle">Need help to get started? reach out to us and we'll be happy to
          assist!</p>
        <p class="block lg:hidden action--subtitle">Need help to get started?<br> reach out to us and we'll be happy to
          assist!</p>
      </div>
      <div [routerLink]="'/articles/terms-of-use'" class="action cursor-pointer !mt-4">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">Terms of Use</p>
        <p class="hidden lg:block action--subtitle">Want to read our End-user license agreement? Click here for the full
          document</p>
        <p class="block lg:hidden action--subtitle">Want to read our End-user license agreement?<br> Click here for the
          full document</p>
      </div>
    </div>
  </div>
  <sbz-footer></sbz-footer>
</div>
