export class Metronome {
  isPlaying: boolean = false;
  playOne: boolean = false;
  private audioContext: AudioContext;
  private bpm: number;
  private beatsPerBar: number;
  private lookahead: number = 25.0; // How frequently to call scheduling function (ms)
  private scheduleAheadTime: number = 0.1; // How far ahead to schedule audio (sec)
  private nextNoteTime: number = 0.0; // When the next note is due
  private timerID: any;
  private currentBeatInBar: number = 0;

  constructor(bpm: number, beatsPerBar: number) {
    this.bpm = bpm;
    this.beatsPerBar = beatsPerBar;
    this.audioContext = new AudioContext();
  }

  public start() {
    this.stop(); // Stop any currently running metronome
    this.ensureAudioContextRunning().then((e) => console.log('ensureAudioContextRunning: ', e)); // Ensure the AudioContext is running

    if (!this.isPlaying) {
      this.isPlaying = true;
      // Start the metronome from the next immediate beat
      this.nextNoteTime = this.audioContext.currentTime;
      this.currentBeatInBar = 0; // Reset to the first beat in the bar

      // Play the additional sound if setOne is true (only once when starting)
      if (this.playOne) {
        this.playAdditionalSoundOnce('assets/music/one.mp3');
        this.playOne = false;
      }

      this.scheduler(); // Start scheduling the metronome notes
    }
  }

  public stop() {
    this.isPlaying = false;
    if (this.timerID) {
      clearTimeout(this.timerID);
    }
  }

  public setBpmAndBeatsPerBar(bpm: number, beatsPerBar: number) {
    this.bpm = bpm;
    this.beatsPerBar = beatsPerBar;
  }

  private async ensureAudioContextRunning() {
    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume();
    }
  }

  private scheduler() {
    while (
      this.nextNoteTime <
      this.audioContext.currentTime + this.scheduleAheadTime
      ) {
      this.scheduleNote();
      this.nextNoteTime += 60.0 / this.bpm;
      this.currentBeatInBar = (this.currentBeatInBar + 1) % this.beatsPerBar;
    }
    this.timerID = setTimeout(() => this.scheduler(), this.lookahead);
  }

  private scheduleNote() {
    const osc = this.audioContext.createOscillator();
    const gainNode = this.audioContext.createGain();

    // Emphasize the downbeat
    if (this.currentBeatInBar === 0) {
      osc.frequency.value = 1000; // Higher pitch for downbeat
    } else {
      osc.frequency.value = 800; // Lower pitch for other beats
    }

    gainNode.gain.setValueAtTime(1, this.nextNoteTime);
    gainNode.gain.exponentialRampToValueAtTime(
      0.001,
      this.nextNoteTime + 0.05
    );

    osc.connect(gainNode);
    gainNode.connect(this.audioContext.destination);

    osc.start(this.nextNoteTime);
    osc.stop(this.nextNoteTime + 0.05);
  }

  private playAdditionalSoundOnce(fileUrl: string) {
    const audioElement = new Audio(fileUrl);
    audioElement.crossOrigin = 'anonymous'; // Enable CORS if needed
    audioElement.play().catch(error => console.error('Error playing additional sound:', error));
  }
}
