<p class="chat-text">
  <strong>NOTE:</strong>
  The sketch you upload to HitCraft will be produced into a professional master recording—yours to own, distribute,
  license, and sell.</p>

<div class="chat-button-container">
  <button (click)="createChatBubbleWIthText('start-session', 'I understand.')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="gradient-purple-button sm:mr-auto sm:!max-w-fit sm:px-8">
    I understand
  </button>
</div>
