import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-after-song-upload',
  templateUrl: './after-song-upload.component.html',
  styleUrl: './after-song-upload.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AfterSongUploadComponent extends BaseChatComponent {
  override ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.goToNext('start-sketch-editor');
    }, 1000);
  }
}
