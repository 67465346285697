declare var lamejs: any;

export class Metronome {
  public audioContext: AudioContext;
  private isPlaying: boolean = false;
  private bpm: number;
  private beatsPerBar: number;
  private nextNoteTime: number = 0.0;
  private currentBeatInBar: number = 0;
  private totalBeatsCount: number = 0;
  private initialTotalBeatsCount: number = 0;
  private timerID: number | null = null;
  private lookahead: number = 25.0;
  private scheduleAheadTime: number = 0.1;
  private playOneBuffer: AudioBuffer | null = null;
  private oneBeatOffset: number | null = null;
  private downbeatOffset: number = 0;

  // Click sounds
  private clickBuffer: AudioBuffer | null = null;
  private downbeatClickBuffer: AudioBuffer | null = null;

  constructor(bpm: number, beatsPerBar: number) {
    this.bpm = bpm;
    this.beatsPerBar = beatsPerBar;
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    this.loadPlayOneSound();
  }

  public async start(currentTime: number, isSetParts = false) {
    this.stop(); // Stop any existing metronome
    if (isSetParts) {
      return;
    }
    await this.ensureAudioContextRunning();

    this.isPlaying = true;

    const secondsPerBeat = 60.0 / this.bpm;

    // Calculate totalBeatsCount based on currentTime
    let totalBeatsCount = Math.floor(currentTime / secondsPerBeat);

    // Calculate time since last beat
    const timeSinceLastBeat = currentTime % secondsPerBeat;

    // Small epsilon to account for floating-point errors
    const epsilon = 0.0001;

    // Adjust nextNoteTime and totalBeatsCount
    if (timeSinceLastBeat < epsilon) {
      // We're exactly on the beat
      this.nextNoteTime = this.audioContext.currentTime;
    } else {
      // Schedule the next note after the remaining time in the current beat
      this.nextNoteTime = this.audioContext.currentTime + (secondsPerBeat - timeSinceLastBeat);
      totalBeatsCount += 1; // Increment because we're moving to the next beat
    }

    // Set currentBeatInBar
    this.currentBeatInBar = totalBeatsCount % this.beatsPerBar;
    this.totalBeatsCount = totalBeatsCount;

    // Store the initial total beats count
    this.initialTotalBeatsCount = totalBeatsCount;

    this.scheduler(); // Start scheduling notes
  }

  public stop() {
    this.isPlaying = false;
    if (this.timerID !== null) {
      clearTimeout(this.timerID);
      this.timerID = null;
    }
  }

  public setBpmAndBeatsPerBar(bpm: number, beatsPerBar: number) {
    this.bpm = bpm;
    this.beatsPerBar = beatsPerBar;
  }

  public setDownbeatOffset(offset: number) {
    this.downbeatOffset = offset;
  }

  public setOneBeatOffset(offset: number | null) {
    this.oneBeatOffset = offset;
  }

  // Function to attach metronome to audio and return an MP3 file
  public async attachMetronomeToAudio(url: string): Promise<Blob> {
    // Load original audio
    const originalBuffer = await this.loadAudioBuffer(url);

    // Generate metronome buffer
    const duration = originalBuffer.duration;
    const metronomeBuffer = await this.createMetronomeBuffer(duration);

    // Mix the buffers
    const mixedBuffer = await this.mixAudioBuffers(originalBuffer, metronomeBuffer);

    // Convert mixed buffer to MP3 Blob
    return this.audioBufferToMp3(mixedBuffer);
  }

  public async generateAudioWithMetronome(audioUrl: string): Promise<string> {
    try {
      // Generate the MP3 Blob with metronome attached
      const mp3Blob = await this.attachMetronomeToAudio(audioUrl);

      // Create a URL for the Blob to use in WaveSurfer or elsewhere
      return URL.createObjectURL(mp3Blob);
    } catch (error) {
      console.error('Error generating audio with metronome:', error);
      return audioUrl;
    }
  }

  private async ensureAudioContextRunning() {
    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume();
    }
  }

  private scheduler = () => {
    if (!this.isPlaying) return;

    while (this.nextNoteTime < this.audioContext.currentTime + this.scheduleAheadTime) {
      this.scheduleClick(this.nextNoteTime);
      this.nextNote();
    }

    this.timerID = window.setTimeout(this.scheduler, this.lookahead);
  };

  private nextNote() {
    const secondsPerBeat = 60.0 / this.bpm;
    this.nextNoteTime += secondsPerBeat;
    this.currentBeatInBar = (this.currentBeatInBar + 1) % this.beatsPerBar;
    this.totalBeatsCount++;
  }

  private scheduleClick(time: number) {
    // Schedule the regular metronome click
    const osc = this.audioContext.createOscillator();
    const gainNode = this.audioContext.createGain();

    // Set frequency for downbeat and regular beats
    osc.frequency.value = this.currentBeatInBar === this.downbeatOffset ? 1000 : 800; // Downbeat shifted
    osc.type = 'square';

    gainNode.gain.setValueAtTime(1, time);
    gainNode.gain.exponentialRampToValueAtTime(0.001, time + 0.05);

    osc.connect(gainNode);
    gainNode.connect(this.audioContext.destination);

    osc.start(time);
    osc.stop(time + 0.05);

    // Schedule the "one.wav" sound if applicable (for real-time playback only)
    if (
      this.oneBeatOffset !== null &&
      this.currentBeatInBar === this.oneBeatOffset
    ) {
      this.schedulePlayOneSound(time);
    }
  }

  private schedulePlayOneSound(time: number) {
    let adjustedTime = time;

    // Move 64 milliseconds earlier if not at the start of the song
    if (this.totalBeatsCount > this.initialTotalBeatsCount) {
      adjustedTime -= 0.064; // 64 milliseconds
      // Ensure the adjusted time is not in the past
      if (adjustedTime < this.audioContext.currentTime) {
        adjustedTime = this.audioContext.currentTime;
      }
    }

    if (this.playOneBuffer) {
      const clonedBuffer = this.cloneAudioBuffer(this.playOneBuffer);
      const source = this.audioContext.createBufferSource();
      source.buffer = clonedBuffer;
      source.connect(this.audioContext.destination);
      source.start(adjustedTime);
    }
  }

  private cloneAudioBuffer(audioBuffer: AudioBuffer): AudioBuffer {
    const newBuffer = this.audioContext.createBuffer(
      audioBuffer.numberOfChannels,
      audioBuffer.length,
      audioBuffer.sampleRate
    );

    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      newBuffer.copyToChannel(audioBuffer.getChannelData(channel), channel);
    }

    return newBuffer;
  }

  private async loadPlayOneSound() {
    const audioUrls = ['assets/music/one.mp3', 'assets/music/one.aac', 'assets/music/one.ogg', 'assets/music/one.wav'];
    for (const url of audioUrls) {
      try {
        this.playOneBuffer = await this.loadAudioBuffer(url);
        if (this.playOneBuffer) {
          console.log(`Loaded audio file: ${url}`);
          break;
        }
      } catch (error) {
        console.warn(`Failed to load audio file ${url}:`, error);
      }
    }
    if (!this.playOneBuffer) {
      console.error('Failed to load any audio file for playOne sound.');
    }
  }

  private async loadAudioBuffer(url: string): Promise<AudioBuffer> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to load audio file: ${url}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    return await this.audioContext.decodeAudioData(arrayBuffer);
  }

  // Function to create metronome buffer
  private async createMetronomeBuffer(duration: number): Promise<AudioBuffer> {
    // Generate the click sounds if they haven't been created yet
    if (!this.clickBuffer) {
      this.clickBuffer = await this.createClickSound(800); // Regular beat frequency
    }
    if (!this.downbeatClickBuffer) {
      this.downbeatClickBuffer = await this.createClickSound(1000); // Downbeat frequency
    }

    const sampleRate = this.audioContext.sampleRate;
    const totalSamples = Math.ceil(duration * sampleRate);
    const buffer = this.audioContext.createBuffer(1, totalSamples, sampleRate);
    const data = buffer.getChannelData(0);

    const beatDuration = 60 / this.bpm;
    const totalBeats = Math.ceil(duration / beatDuration);

    for (let i = 0; i <= totalBeats; i++) {
      const clickStartTime = i * beatDuration;
      const clickStartSample = Math.floor(clickStartTime * sampleRate);

      // Use downbeat click for the first beat in the bar
      const isDownbeat = ((i + (this.oneBeatOffset || 0)) % this.beatsPerBar) === this.downbeatOffset;
      const clickBufferToUse = isDownbeat ? this.downbeatClickBuffer : this.clickBuffer;

      this.mixClickIntoBuffer(data, clickBufferToUse.getChannelData(0), clickStartSample);
    }

    return buffer;
  }

  // Function to create a click sound as an AudioBuffer
  private async createClickSound(frequency: number): Promise<AudioBuffer> {
    const duration = 0.05; // Click duration in seconds
    const sampleRate = this.audioContext.sampleRate;

    // Create an OfflineAudioContext for rendering the oscillator
    const offlineContext = new OfflineAudioContext(1, sampleRate * duration, sampleRate);

    const osc = offlineContext.createOscillator();
    const gainNode = offlineContext.createGain();

    osc.frequency.value = frequency;
    osc.type = 'square';

    gainNode.gain.setValueAtTime(1, 0);
    gainNode.gain.exponentialRampToValueAtTime(0.001, duration);

    osc.connect(gainNode);
    gainNode.connect(offlineContext.destination);

    osc.start(0);
    osc.stop(duration);

    // Render the audio
    const renderedBuffer = await offlineContext.startRendering();

    return renderedBuffer;
  }

  // Function to mix click sound into buffer
  private mixClickIntoBuffer(destination: Float32Array, source: Float32Array, offset: number) {
    for (let i = 0; i < source.length; i++) {
      if ((offset + i) < destination.length) {
        destination[offset + i] += source[i];
      } else {
        break;
      }
    }
  }

  // Function to mix two audio buffers
  private async mixAudioBuffers(buffer1: AudioBuffer, buffer2: AudioBuffer): Promise<AudioBuffer> {
    const numberOfChannels = Math.max(buffer1.numberOfChannels, buffer2.numberOfChannels);
    const length = Math.max(buffer1.length, buffer2.length);
    const sampleRate = buffer1.sampleRate;

    const offlineContext = new OfflineAudioContext(numberOfChannels, length, sampleRate);

    // Source for buffer1
    const source1 = offlineContext.createBufferSource();
    source1.buffer = buffer1;
    source1.connect(offlineContext.destination);

    // Source for buffer2
    const source2 = offlineContext.createBufferSource();
    source2.buffer = buffer2;
    source2.connect(offlineContext.destination);

    // Start playback
    source1.start(0);
    source2.start(0);

    // Render the mixed audio
    const mixedBuffer = await offlineContext.startRendering();
    return mixedBuffer;
  }

  // Function to convert AudioBuffer to MP3 Blob using lamejs
  private audioBufferToMp3(audioBuffer: AudioBuffer): Blob {
    const numChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const mp3Encoder = new lamejs.Mp3Encoder(numChannels, sampleRate, 128); // 128 kbps bitrate

    // Get PCM data from AudioBuffer
    const samples = this.getSamplesFromAudioBuffer(audioBuffer);

    const mp3Data: Uint8Array[] = [];

    // Encode PCM data to MP3
    let remaining = samples[0].length;
    const maxSamples = 1152; // Frame size for MP3 encoder

    let position = 0;

    while (remaining >= maxSamples) {
      const left = samples[0].subarray(position, position + maxSamples);
      const right = numChannels === 2 ? samples[1].subarray(position, position + maxSamples) : undefined;

      const mp3buf = mp3Encoder.encodeBuffer(left, right);
      if (mp3buf.length > 0) {
        mp3Data.push(new Uint8Array(mp3buf));
      }
      position += maxSamples;
      remaining -= maxSamples;
    }

    // Handle remaining samples
    if (remaining > 0) {
      const left = samples[0].subarray(position);
      const right = numChannels === 2 ? samples[1].subarray(position) : undefined;

      const mp3buf = mp3Encoder.encodeBuffer(left, right);
      if (mp3buf.length > 0) {
        mp3Data.push(new Uint8Array(mp3buf));
      }
    }

    // Finish encoding
    const mp3buf = mp3Encoder.flush();
    if (mp3buf.length > 0) {
      mp3Data.push(new Uint8Array(mp3buf));
    }

    // Create Blob from MP3 data
    const blob = new Blob(mp3Data, {type: 'audio/mp3'});
    return blob;
  }

  // Helper function to get PCM samples from AudioBuffer
  private getSamplesFromAudioBuffer(audioBuffer: AudioBuffer): Int16Array[] {
    const numChannels = audioBuffer.numberOfChannels;
    const samples: Int16Array[] = [];

    for (let channel = 0; channel < numChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      const bufferLength = channelData.length;
      const pcmData = new Int16Array(bufferLength);

      for (let i = 0; i < bufferLength; i++) {
        pcmData[i] = Math.max(-32768, Math.min(32767, channelData[i] * 32767));
      }

      samples.push(pcmData);
    }

    return samples;
  }
}
