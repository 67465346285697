@if (modalService.isPromptModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container error-modal sm:!w-[600px]">
      <h2 class="modal-header">User <strong class="font-bold">Prompt</strong></h2>
      <!--      <p [innerHTML]="modalService.promptMessage | safeHtml" class="modal-content"></p>-->
      <img src="assets/prompt_example.png" alt="example prompt" class="mx-auto rounded-2xl w-[98%] h-auto">
      <button (click)="modalService.closePromptModal()"
              class="main-button-design gradient-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px]">
        OK
      </button>
    </div>
  </div>
}
