export interface TeamMember {
  id: number;
  name: string;
  role: string;
  image: string;
}

export const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: 'Oudi Antebi',
    role: 'Co-Founder & CEO',
    image: 'assets/images/about-us/members/01_oudi.webp'
  },
  {
    id: 2,
    name: 'Amit Shine',
    role: 'Co-Founder & COO',
    image: 'assets/images/about-us/members/02_amit.webp'
  },
  {
    id: 3,
    name: 'Tal Forer',
    role: 'Co-Founder & Music Producer',
    image: 'assets/images/about-us/members/03_tal.webp'
  },
  {
    id: 4,
    name: 'Yinon Yahel',
    role: 'Co-Founder & Music Producer',
    image: 'assets/images/about-us/members/04_yinon.webp'
  },
  {
    id: 5,
    name: 'Stav Beger',
    role: 'Co-Founder & Music Producer',
    image: 'assets/images/about-us/members/05_stav.webp'
  },
  {
    id: 6,
    name: 'Osher Biton',
    role: 'Music Producer',
    image: 'assets/images/about-us/members/06_osher.webp'
  },
  {
    id: 7,
    name: 'Ran Hagai',
    role: 'Finance & Ops',
    image: 'assets/images/about-us/members/07_ran.webp'
  },
  {
    id: 8,
    name: 'Tamir Lavi',
    role: 'Product Manage & Sound',
    image: 'assets/images/about-us/members/08_tamir.webp'
  },
];
