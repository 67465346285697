@if ((showSketchId$ | async)) {
  <h3 class="fixed top-0 left-0 right-0 text-center text-red-600 z-[1500]">SketchId: {{ showSketchId$| async }}</h3>
}
<div class="robot-chat-container">
  <div [@fadeTransition] class="chat-container" id="chat-container">
    <div class="chats">
      @for (bubble of chatBubbles; track bubble) {
        @if (bubble.isUserBubble) {
          <div class="user-chat fade-out-section">
            <span>{{ bubble.type }}</span>
          </div>
        } @else {
          <div class="system-chat fade-out-section"
               [id]="bubble.type"
               [ngClass]="(bubble.isLoading$ | async) ||
              (bubble.type !== 'jump' && bubble.type !== 'welcome' && bubble.type !== 'start-sketch-editor'
                && bubble.type !== 'song-loading' && bubble.type !== 'lyrics-input' && bubble.type !== 'start-session'
                && bubble.type !== 'song-name' && bubble.type !== 'send-email' && bubble.type !== 'final-results')
              ? 'sm:!w-fit' : 'sm:!w-full'"
               [@fadeTransition]>
            @if (bubble.isLoading$ | async) {
              <div class="dot-container">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            } @else {
              <sbz-system-chat-bubble
                  [componentType]="bubble.component!"></sbz-system-chat-bubble>
            }
          </div>
        }
      }

      @if (showStartNewChat$ | async) {
        <div [@fadeTransition] class="chat-button-container mt-auto pb-[20px] w-full sm:w-fit !block sm:!hidden">
          <button class="black-button w-full sm:!w-[200px] sm:!h-[40px] sm:mt-[8px]" (click)="popUpAlertForNewChat()">
            start a new chat
          </button>
        </div>
      }
    </div>
  </div>

  @if (showStartNewChat$ | async) {
    <div [@fadeTransition] class="fixed-chat-button-container">
      <button class="black-button" (click)="popUpAlertForNewChat()">
        start a new chat
      </button>
    </div>
  }
</div>
