import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {SketchResult, SketchResultFromServer} from '../interfaces/sketch-result';
import {UserStateService} from './user-state.service';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  promptSketchId = '';
  promptMockSketchId = '66d0447b0d9e5f51c94d1ee9';
  chosenSketchResult: SketchResult | undefined = undefined;
  sketchBpm: any;
  songName = '';
  lyrics = '';
  butcherTaskArn = '';
  audioFileFromSketch: HTMLAudioElement | null = null;
  failedDynamicButcherResponses$ = new BehaviorSubject(0);
  produceTrackClicked = false;

  dynamicButcherResponses$ = new BehaviorSubject<SketchResult[] | undefined>(undefined);
  singleButcherResult$ = new BehaviorSubject<string | undefined>(undefined);
  dynamicButcherResponseBug$ = new BehaviorSubject<string | undefined>(undefined);
  singleButcherResultBug$ = new BehaviorSubject<boolean>(false);
  firstDynamicButcherResponseCame$ = new BehaviorSubject<boolean>(false);
  dynamicButcherResponsesFinished$ = new BehaviorSubject<boolean>(false);

  private currentAudio: HTMLAudioElement | null = null;
  private cancelHttpRequest$ = new Subject<void>();

  constructor(private httpClient: HttpClient,
              private userStateService: UserStateService) {
  }

  // Method to trigger cancellation
  cancelHttpRequest() {
    this.cancelHttpRequest$.next();
  }

  getCancelHttpRequest$() {
    return this.cancelHttpRequest$;
  }

  resetAudioService() {
    this.cancelHttpRequest(); // Cancel any ongoing HTTP requests

    // Reset all class properties
    this.promptSketchId = '';
    this.promptMockSketchId = '66d0447b0d9e5f51c94d1ee9'; // Reset to default mock ID
    this.chosenSketchResult = undefined;
    this.sketchBpm = null; // Reset BPM
    this.songName = '';
    this.lyrics = '';
    this.butcherTaskArn = '';
    this.audioFileFromSketch = null; // Reset audio file
    this.currentAudio = null; // Reset current playing audio
    this.produceTrackClicked = false;

    // Reset all BehaviorSubjects
    this.failedDynamicButcherResponses$.next(0);
    this.dynamicButcherResponses$.next(undefined);
    this.singleButcherResult$.next(undefined);
    this.dynamicButcherResponseBug$.next(undefined);
    this.firstDynamicButcherResponseCame$.next(false);
    this.dynamicButcherResponsesFinished$.next(false);
  }

  createAudioFileFromUrl(fileURL: string) {
    this.audioFileFromSketch = new Audio();
    this.audioFileFromSketch.crossOrigin = 'anonymous'; // Enable CORS
    this.audioFileFromSketch.src = fileURL;
  }

  playSong(songName: string, playFromUrl = false) {
    // Stop the current song if one is playing
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
    }

    // Create a new audio element
    this.currentAudio = playFromUrl ? this.audioFileFromSketch! : new Audio(`assets/music/${songName}.mp3`);

    // Play the new song
    return this.currentAudio.play();
  }

  stopSong(): void {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.currentTime = 0;
      this.currentAudio = null;
    }
  }

  togglePlayPause(): void {
    if (this.currentAudio) {
      if (this.currentAudio.paused) {
        this.currentAudio.play().catch(error => {
          console.error('Error resuming the audio file:', error);
        });
      } else {
        this.currentAudio.pause();
      }
    }
  }

  getValidPartNames() {
    return this.httpClient
      .get<any>(`https://sketch-upload.session42.xyz/get-valid-part-names`);
  }

  getStruct() {
    return this.httpClient
      .get<any>(`https://sketch-upload.session42.xyz/get-struct-and-file?sketch_id=${this.promptSketchId}`);
  }

  uploadPrompt(selectedGenre: string, isPreAlpha: boolean) {
    // Prepare form data
    const formData = new FormData();
    formData.append('sketch_id', this.promptSketchId);
    formData.append('preview_choice', selectedGenre);

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`,
      'enctype': 'multipart/form-data'
    });

    const url = isPreAlpha
      ? 'https://sketch-upload.session42.xyz/set-preview-choice-pre'
      : 'https://sketch-upload.session42.xyz/set-preview-choice';

    // Send the POST request
    return this.httpClient.post(url, formData, {headers});
  }

  setNewBpm() {
    // Prepare form data
    const formData = new FormData();
    formData.append('sketch_id', this.promptSketchId);
    formData.append('bpm', this.sketchBpm);

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`,
      'enctype': 'multipart/form-data'
    });

    // Send the POST request
    return this.httpClient.post('https://sketch-upload.session42.xyz/set-sketch-bpm', formData, {headers});
  }

  setNewOne(shiftBeat: number) {
    // Prepare form data
    const formData = new FormData();
    formData.append('sketch_id', this.promptSketchId);
    formData.append('shift_beat', shiftBeat.toString());

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`,
      'enctype': 'multipart/form-data'
    });

    // Send the POST request
    return this.httpClient.post('https://sketch-upload.session42.xyz/set-sketch-shift-beat', formData, {headers});
  }

  setSongName(songName: string) {
    // Prepare form data
    const formData = new FormData();
    formData.append('sketch_id', this.promptSketchId);
    formData.append('song_name', songName);

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`,
      'enctype': 'multipart/form-data'
    });

    // Send the POST request
    return this.httpClient.post('https://sketch-upload.session42.xyz/set-song-name', formData, {headers});
  }

  setSongLyrics(lyrics: string) {
    // Prepare form data
    const formData = new FormData();
    formData.append('sketch_id', this.promptSketchId);
    formData.append('lyrics', lyrics);

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`,
      'enctype': 'multipart/form-data'
    });

    // Send the POST request
    return this.httpClient.post('https://sketch-upload.session42.xyz/set-sketch-lyrics', formData, {headers});
  }

  getFinalProduction() {
    this.userStateService.refreshDescopeToken().subscribe({
      next: (res: any) => {
        this.userStateService.updateUserFromDescope(res.data.sessionJwt);
        // Now start the request sequence with the new token
        this.startSingleButcherResults();
      },
      error: (err) => {
        console.error('Error refreshing token:', err);
      }
    });
  }

  getDynamicButcher(butcherTaskArn: string) {
    this.userStateService.refreshDescopeToken().subscribe({
      next: (res: any) => {
        this.userStateService.updateUserFromDescope(res.data.sessionJwt);
        // Now start the request sequence with the new token
        this.startButcherRequest(butcherTaskArn);
      },
      error: (err) => {
        console.error('Error refreshing token:', err);
      }
    });
  }

  getSingleButcherMockData() {
    this.singleButcherResult$.next('https://wavesurfer.xyz/wavesurfer-code/examples/audio/demo.wav');
  }

  getPreviewsCompleteMockData() {
    const allResults: SketchResult[] = [
      {
        id: '1',
        isMystery: false,
        sketch_bpm: 101,
        url: 'https://chord-analyser-public-s3bucket-dev.s3.amazonaws.com/test1.wav'
      },
      {
        id: '2',
        isMystery: false,
        sketch_bpm: 101,
        url: 'https://chord-analyser-public-s3bucket-dev.s3.amazonaws.com/test2.wav'
      },
      {
        id: '3',
        isMystery: false,
        sketch_bpm: 90,
        url: 'https://chord-analyser-public-s3bucket-dev.s3.amazonaws.com/test3.wav'
      },
      {
        id: '4',
        isMystery: true,
        sketch_bpm: 92,
        url: 'https://chord-analyser-public-s3bucket-dev.s3.amazonaws.com/test4.wav'
      }
    ];

    allResults.forEach((result) => {
      setTimeout(() => {
        this.dynamicButcherResponses$.next([result]);
      }, 300);
    });

    setTimeout(() => {
      this.failedDynamicButcherResponses$.next(0);
      this.dynamicButcherResponsesFinished$.next(true);
    }, 1200);
  }

  getPreviewsCompleteAndFailedMockData() {
    const allResults: SketchResult[] = [
      {
        id: '66c48ade4ad5720153c3c4c6',
        isMystery: false,
        sketch_bpm: 76,
        url: 'https://chord-analyser-public-s3bucket-dev.s3.amazonaws.com/test1.wav'
      },
      {
        id: '66c48ade4ad5720153c3c414',
        isMystery: true,
        sketch_bpm: 76,
        url: 'https://chord-analyser-public-s3bucket-dev.s3.amazonaws.com/test4.wav'
      },
    ];

    let completedResults: SketchResult[] = [];
    let failedCount = 0;

    allResults.forEach((result, index) => {
      setTimeout(() => {
        // Simulate completion for the first two results
        if (index < 2) {
          completedResults.push(result);
        } else {
          // Simulate failure for the last two results
          failedCount++;
        }

        const response: SketchResultFromServer = {
          completed: [...completedResults],
          pending: 2 - (completedResults.length + failedCount),
          failed: failedCount
        };

        // Simulate the server sending updates
        this.dynamicButcherResponses$.next(response.completed);

        if (completedResults.length + failedCount === 2) {
          this.failedDynamicButcherResponses$.next(response.failed);
          this.dynamicButcherResponsesFinished$.next(true);
        }
      }, (index + 1) * 2000); // 2 seconds interval between each result
    });
  }

  setStruct(struct: any[]) {
    const endpoint = 'https://sketch-upload.session42.xyz/set-struct';
    const formData: FormData = new FormData();
    formData.append('sketch_id', this.promptSketchId);
    formData.append('structure', JSON.stringify(struct));

    return this.httpClient.post(endpoint, formData);
  }

  private startSingleButcherResults() {
    const url = 'https://sketch-upload.session42.xyz/get-single-butcher-results';

    // Include 'butcher_task_arn' as a URL parameter
    const params = new HttpParams().set('butcher_task_id', this.chosenSketchResult!.id);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`
    });

    const makeRequest = () => {
      this.httpClient.get<any>(url, {params, headers})
        .pipe(takeUntil(this.cancelHttpRequest$)) // Attach cancellation
        .subscribe({
          next: (response: any) => {
            if (response.status?.toLowerCase() !== 'running') {
              this.singleButcherResult$.next(response);
              return;
            }

            // Schedule the next request after 5 seconds
            setTimeout(makeRequest, 5000);
          },
          error: (err) => {
            console.error('Error fetching single butcher results: ', err);
            // Retry after 5 seconds in case of an error
            this.singleButcherResultBug$.next(true);
          }
        });
    };

    // Start the first request immediately
    makeRequest();
  }

  private startButcherRequest(butcherTaskArn: string) {
    const url = 'https://sketch-upload.session42.xyz/get-dynamic-butcher-results';

    // Include 'butcher_task_arn' as a URL parameter
    const params = new HttpParams().set('butcher_task_arn', butcherTaskArn);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`
    });

    let accumulatedResponses: SketchResult[] = [];

    const makeRequest = () => {
      this.httpClient.get<SketchResultFromServer>(url, {params, headers})
        .pipe(takeUntil(this.cancelHttpRequest$)) // Attach cancellation
        .subscribe({
          next: (response: SketchResultFromServer) => {
            if (response.failed > 0) {
              this.failedDynamicButcherResponses$.next(response.failed);
            }

            if (response.completed.length > 0) {
              if (!this.firstDynamicButcherResponseCame$.getValue()) {
                this.firstDynamicButcherResponseCame$.next(true);
              }
              accumulatedResponses = [...response.completed];

              this.dynamicButcherResponses$.next(accumulatedResponses);
            }

            if (response.completed.length === 2) {
              this.failedDynamicButcherResponses$.next(response.failed);
              this.dynamicButcherResponsesFinished$.next(true);
              return;
            }

            // Check if we have 4 or more valid responses
            if (response.completed.length + response.failed >= 2) {
              // Stop further requests once the condition is met
              this.failedDynamicButcherResponses$.next(response.failed);
              this.dynamicButcherResponsesFinished$.next(true);
              return;
            } else {
              // Schedule the next request after 5 seconds
              if (!this.produceTrackClicked) {
                setTimeout(makeRequest, 5000);
              }
            }
          },
          error: (err) => {
            console.error('Error fetching dynamic butcher results:', err);
            // Retry after 5 seconds in case of an error
            this.dynamicButcherResponseBug$.next(butcherTaskArn);
          }
        });
    };

    // Start the first request immediately
    makeRequest();
  }
}
