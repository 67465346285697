import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../../animations/fade-in-animation';

@Component({
  selector: 'sbz-read-more',
  templateUrl: './read-more.component.html',
  styleUrl: './read-more.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadMoreComponent {
}
