import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-jump',
  templateUrl: './chat-jump.component.html',
  styleUrl: './chat-jump.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatJumpComponent extends BaseChatComponent {
  goToLink(link: string) {
    const url = `${window.location.origin}/${link}`;
    window.open(url, '_blank');
  }
}
