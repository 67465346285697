import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MouseTrackerService {
  private mousePosition = new Subject<{ x: number; y: number }>();
  mousePosition$ = this.mousePosition.asObservable();

  constructor() {
    // Listen to global mouse movements
    window.addEventListener('mousemove', this.onMouseMove.bind(this));
  }

  private onMouseMove(event: MouseEvent) {
    this.mousePosition.next({x: event.clientX, y: event.clientY});
  }
}
