@if ((changeToLandscape$ | async) || (changeToPortrait$ | async)) {
  <div class="modal-container w-[80vw] !absolute !bg-[#181819]">
    <div class="relative w-[300px] h-[533px]">
      <img class="rounded-[42px] top-0 left-0 right-0 bottom-0" fill ngSrc="assets/rotate.webp" alt="rotate phone">
    </div>
  </div>
}

@if (isLoading$ | async) {
  <sbz-chat-loading-bar
      text="We're getting everything ready for you..."
      [isLoading]="(isLoading$ | async)!">
  </sbz-chat-loading-bar>
} @else {
  @if (playerState === 'final') {
    <p class="chat-text">Great job. Now that we have the song locked in, let's move on.</p>
  } @else {
    <div class="relative" [class.hide-player]="(changeToLandscape$ | async) || (changeToPortrait$ | async)">

      <section class="transition-all w-full">
        <p class="chat-text text-[14.5px]">Sketch Editor | {{ audioService.songName }}</p>

        <div class="chat-white-container h-[72px] justify-around !flex-row !my-[8px]">
          <div class="step-container" [ngClass]="{'color-black': playerState === 'set-bpm'}">
            <span class="step-badge">1</span>
            <span class="step-text">Confirm or adjust BPM</span>
          </div>
          <div class="step-container" [ngClass]="{'color-black': playerState === 'set-one'}">
            <span class="step-badge">2</span>
            <span class="step-text">Adjust song to grid</span>
          </div>
          <div class="step-container" [ngClass]="{'color-black': playerState === 'set-parts'}">
            <span class="step-badge">3</span>
            <span class="step-text">Adjust song parts</span>
          </div>
        </div>

        <div class="w-full my-[16px]" [class.flex]="playerState === 'set-parts'">
          @if (flowMessage$ | async) {
            <div class="w-full">
              <p class="chat-text">{{ flowMessage$ | async }}</p>
            </div>
          } @else {
            @switch (playerState) {
              @case ('set-bpm') {
                @if (setBpmBugHappened) {
                  <p class="chat-text inline-block !font-medium !text-red-500">An issue occurred. please try setting bpm
                    again</p>
                }

                <p class="chat-text inline-block">We have identified your sketch as having a tempo of
                  <strong>{{ originalBpm }}</strong>
                  BPM.</p>

                <p class="chat-text">Play your sketch (we've added a metronome) and adjust the bpm if necessary.</p>
              }
              @case ('set-one') {
                @if (setOneBugHappened) {
                  <p class="chat-text inline-block !font-medium !text-red-500">An issue occurred. please try setting the
                    one
                    again</p>
                }

                <p class="chat-text">We need to ensure the song sits perfectly on the grid, out of the 4 options there
                  is 1 that sits perfectly, select and continue.</p>
              }
              @case ('set-parts') {
                @if (setPartsBugHappened) {
                  <p class="chat-text inline-block !font-medium !text-red-500">An issue occurred. please try setting the
                    structure
                    again</p>
                }

                <p class="chat-text">Let's confirm or adjust the structure of the song to get the perfect production.
                  You can "merge" parts into long ones, delete parts and change start and end points. Start by
                  clicking
                  on the colored headers to modify.
                </p>

                <button (click)="returnToDefaultStructure()"
                        class="continue-button-class sm:!min-w-fit chat-button-generic sm:ml-[32px] mt-auto">
                  <span class="mx-auto">reset changes</span>
                </button>
              }
            }
          }
        </div>

        <div class="player-wrapper relative">
          @if (flowMessage$ | async) {
            <div class="h-[120px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bottom-0">
              <div class="flow-loader"></div>
            </div>
          }
          <section [ngClass]="{'blur-custom': flowMessage$ | async}"
                   class="relative w-full h-[140px] flex items-center mb-[12px]">
            @switch (playerState) {
              @case ('set-bpm') {
                <div class="w-full relative flex items-center px-4">
                  <section class="bpm flex items-center justify-center flex-grow gap-8">
                    <div (click)="bpmUpOrDown(1)" class="bg-white cursor-pointer rounded-full p-[12px] group">
                      <svg fill="#808eb3" width="64px" height="64px" viewBox="0 0 256 256" id="Flat"
                           xmlns="http://www.w3.org/2000/svg" class="group-hover:fill-black group-focus:fill-black">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                              d="M231.39062,123.06152A8,8,0,0,1,224,128H184v80a16.01833,16.01833,0,0,1-16,16H88a16.01833,16.01833,0,0,1-16-16V128H32a8.00065,8.00065,0,0,1-5.65723-13.65723l96-96a8.003,8.003,0,0,1,11.31446,0l96,96A8.002,8.002,0,0,1,231.39062,123.06152Z"></path>
                        </g>
                      </svg>
                    </div>
                    <div class="chat-white-container flex flex-col justify-between h-full !pt-[4px] !pb-0">
                      <span class="text-sm self-start ml-[12px]">BPM</span>
                      <div class="ml-[16px]">
                        @if (bpmControl) {
                          <input id="bpm-input" type="number" name="bpmInput" sbzCustomAutoFocus
                                 [formControl]="bpmControl"
                                 class="appearance-none border-none outline-none focus:ring-0 p-0 m-0 bg-transparent
                              text-[26px] font-medium self-end w-[65px]"
                                 (keydown)="preventInvalidInput($event)"/>
                        } @else {
                          <input id="empty-input" type="number" name="bpmInput"
                                 class="appearance-none border-none outline-none focus:ring-0 p-0 m-0 bg-transparent
                              text-[26px] font-medium self-end w-[65px]"/>
                        }
                      </div>
                    </div>
                    <div (click)="bpmUpOrDown(-1)" class="bg-white cursor-pointer rounded-full p-[12px] group">
                      <svg fill="#808eb3" width="64px" height="64px" viewBox="0 0 256 256" id="Flat"
                           xmlns="http://www.w3.org/2000/svg" class="group-hover:fill-black group-focus:fill-black">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                              d="M229.65723,141.65723l-96,96a8.003,8.003,0,0,1-11.31446,0l-96-96A8.00065,8.00065,0,0,1,32,128H72V48A16.01833,16.01833,0,0,1,88,32h80a16.01833,16.01833,0,0,1,16,16v80h40a8.00066,8.00066,0,0,1,5.65723,13.65723Z"></path>
                        </g>
                      </svg>
                    </div>
                  </section>
                  <section class="multiplier absolute right-2 flex flex-col gap-1">
                      <span class="text-sm cursor-pointer hover:underline"
                            (click)="doubleOrHalfTimeBpm()">Double time</span>
                    <span class="text-sm cursor-pointer hover:underline"
                          (click)="doubleOrHalfTimeBpm(false)">Half time</span>
                  </section>
                </div>
              }
              @case ('set-one') {
                @if (setOneOptions.length > 0) {
                  <div class="w-full relative flex justify-between px-4">
                    @for (option of setOneOptions; track option.name) {
                      <button (click)="movePlayerToChosenOnePosition(option.name)"
                              [class.selected-one]="option.selected"
                              class="continue-button-class sm:!w-fit chat-button-generic">
                        <span class="mx-auto">{{ option.name }}</span>
                      </button>
                    }
                  </div>
                }
              }
              @case ('set-parts') {
                @if ((activeBlockIndex > -1) && playerState === 'set-parts') {
                  <p class="absolute -top-[6px] left-1/2 transform -translate-x-1/2">{{ (blocks$ | async)![activeBlockIndex!].text }}</p>
                  <div [style.backgroundColor]="activeBlockIndex > -1 ? blocks[activeBlockIndex].color : 'transparent'"
                       class="full-width-bar">
                    @if (previousBlockName) {
                      <p class="left-[6%]" (click)="performAction('mergeLeft',$event)">
                        Merge with {{ previousBlockName }}</p>
                    }
                    <p class="left-[35%]" (click)="openDropdown($event)">Rename</p>
                    <p class="right-[35%]" (click)="performAction('delete', $event)">
                      Delete {{ blocks[activeBlockIndex].text }}</p>
                    @if (nextBlockName) {
                      <p class="right-[6%]" (click)="performAction('mergeRight',$event)">
                        Merge with {{ nextBlockName }}</p>
                    }

                    @if (activeBlockIndex > 0) {
                      <span class="left-2" (mouseenter)="onArrowHover(true, false)"
                            (mouseleave)="onArrowHover(false, false)"
                            (click)="expandLeft($event)">&#9664;</span>
                    }
                    @if ((blocks$ | async)!.length - 1 !== activeBlockIndex) {
                      <span class="right-2" (mouseenter)="onArrowHover(true, true)"
                            (mouseleave)="onArrowHover(false, true)"
                            (click)="expandRight($event)"
                            [ngClass]="{'ml-auto': activeBlockIndex === 0}">&#9654;</span>
                    }
                  </div>
                }

                <div #playerBlocks [ngClass]="{'opacity-0 pointer-events-none': hideBlocks}" class="player-blocks">
                  @for (block of blocks$ | async; track block.index; let first = $first) {
                    <div [style.left]="block.left"
                         [style.fontSize]="(activeBlockIndex === block.index) ? '16px' : '14px'"
                         [style.width]="block.width"
                         [style.opacity]="(isDropdownVisible || activeBlockIndex > -1) &&
                        activeBlockIndex !== block.index ? '0.5' : '1'"
                         class="custom-block">
                      <div class="block-wrapper cursor-default" (mouseenter)="onBlockHover($index)"
                           (mouseleave)="onBlockLeave($index)">
                        <span class="mt-[9px]">{{ block.shortText }}</span>
                        <!-- Tooltip -->
                        @if (showTooltipIndex === $index) {
                          <div class="tooltip">
                            {{ block.text }}
                          </div>
                        }
                      </div>

                      <!-- Bottom bar within each block                           (mouseleave)="onMouseLeave()"-->
                      <div [style.backgroundColor]="block.color"
                           (mouseenter)="onMouseEnter($index, $event)"
                           (click)="onMouseEnter($index, $event)"
                           (mouseleave)="onMouseLeave()"
                           (dblclick)="onBlockDoubleClick(block, $event)"
                           [@itemAnimation]="{value: '',params: { duration: '600ms', delay: getAnimationDelay($index) }}"
                           (@itemAnimation.done)="onAnimationDone($event, block)"
                           [ngStyle]="getBottomBarStyle(block)"
                           class="bottom-bar">
                        @if (!first) {
                          <div class="black-line-on-block"
                               [style.height]="activeBlockIndex === block.index ? '23px' : '12px'"></div>
                        }
                      </div>
                    </div>
                  }
                </div>
              }
            }
          </section>

          <div [ngClass]="{'blur-custom': flowMessage$ | async}" class="waveform-wrapper">
            <!-- Progress Line -->
            <div #progressLine class="progress-line">
              @if (showButtons) {
                <div class="button-container">
                  <button (click)="performAction('split',$event)" class="top-button split-button">
                    <i class="fas fa-cut"></i>
                  </button>
                  <button (click)="seekByBarDuration(true)" class="left-middle-button error-button">
                    <i class="fa-solid fa-chevron-left"></i>
                  </button>
                  <button (click)="seekByBarDuration(false)" class="right-middle-button error-button">
                    <i class="fa-solid fa-chevron-right"></i>
                  </button>
                </div>
              }
            </div>
            <div class="waveform-inner-wrapper">
              @if (hoveringOverArrow) {
                <div [style.left.px]="linePosition" class="grey-vertical-line"></div>
              }
              <div #waveform (click)="onPlayerClick($event)"
                   [ngClass]="{'pointer-events-none': playerState === 'set-one'}"
                   id="waveform"></div>
              <!--    <div #timestampDisplay [class.hidden]="!hoverTimestamp" class="timestamp-display">-->
              <!--      {{ hoverTimestamp }}-->
              <!--    </div>-->
              <div #progressDiv class="progress-div"></div>
              @if (!(flowMessage$ | async)) {
                <button>
                  <fa-icon class="play-button-class" (click)="playPause()"
                           [icon]="isPlaying ? 'pause' : 'play'"></fa-icon>
                </button>
              }
            </div>
          </div>

          @if (isDropdownVisible) {
            <div (outsideClick)="onClickOutside()"
                 [style.left.px]="dropdownPosition.x" [style.top.px]="dropdownPosition.y"
                 class="custom-menu">
              <ul aria-labelledby="dropdownButton" class="py-1">
                <!-- Rename with submenu -->
                @for (part of partsList; track part) {
                  <li class="relative" (click)="updateBlockName(part, $event)">
                    <a class="custom-menu-item" [ngClass]="{'!text-red-600': getColor(part)}"
                       href="#">
                      {{ part }}
                    </a>
                  </li>
                }
              </ul>
            </div>
          }
        </div>

      </section>

      <div [class.blur-custom]="flowMessage$ | async" class="flex flex-wrap w-full mt-[40px]">
        @if (playerState !== 'set-bpm') {
          <button (click)="takeStepBack()"
                  class="continue-button-class sm:!w-fit chat-button-generic !mr-auto">
            <span class="mx-auto">Back</span>
          </button>
        }
        <button (click)="confirmAndContinue()"
                class="continue-button-class sm:!w-fit chat-button-generic !ml-auto">
          <span class="mx-auto">Confirm and continue</span>
        </button>
      </div>
    </div>
  }
}
