@if (modalService.isErrorModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container error-modal">
      <h2 class="modal-header">Error <strong class="font-bold">Occurred</strong></h2>
      <p [innerHTML]="modalService.errorMessage | safeHtml" class="modal-content"></p>
      <p class="modal-content -mb-[4rem]">If the error persists<br>
        <a (click)="modalService.closeErrorModal()" [routerLink]="'/contact'"
           class="font-bold cursor-pointer underline">Click Here</a>
        to contact us</p>
      <button (click)="modalService.closeErrorModal()"
              class="main-button-design black-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px] !bg-[#d92525]">
        OK
      </button>
    </div>
  </div>
}
