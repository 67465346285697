import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, debounce, switchMap, timer} from 'rxjs';
import WaveSurfer from 'wavesurfer.js';
import {AudioBlock} from '../../../../interfaces/audio-block';
import {Metronome} from './metronome';
import {FormControl, Validators} from '@angular/forms';
import {SetOneOption} from '../../../../interfaces/set-one-option';
import {MobileDetectionService} from '../../../../services/mobile-detection.service';
import {OrientationService} from '../../../../services/orientation.service';
import {ToastService} from '../../../../services/toast.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {HttpErrorResponse} from '@angular/common/http';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-chat-start-sketch-editor',
  templateUrl: './chat-start-editor.component.html',
  styleUrls: ['./chat-start-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('itemAnimation', [
      transition(':enter', [
        style({height: '8px'}), // Start at height 8px
        animate(
          '{{ duration }} {{ delay }} ease-out',
          keyframes([
            style({height: '8px', offset: 0}),
            style({height: '17px', offset: 0.5}), // Grow to 17px at halfway point
            style({height: '8px', offset: 1}), // Shrink back to 8px at end
          ])
        ),
      ]),
    ]),
  ],
})
export class ChatStartEditorComponent extends BaseChatComponent implements OnDestroy {
  isLoading$ = new BehaviorSubject(true);
  @ViewChild('waveform') waveformEl!: ElementRef;
  @ViewChild('playerBlocks') playerBlocksEl!: ElementRef<HTMLDivElement>;
  @ViewChild('progressDiv') progressDiv!: ElementRef;
  @ViewChild('progressLine') progressLine!: ElementRef;
  waveSurfer!: WaveSurfer;
  @ViewChild('timestampDisplay') timestampDisplay!: ElementRef<HTMLDivElement>;
  struct!: any;
  bpmPhaseStruct!: any;
  originalStruct!: any;
  time_signature = '';
  bpm = 0;
  originalBpm = 0;
  initialMinBpmForRange = 0;
  initialMaxBpmForRange = 0;
  currentUrl = '';
  blocks: AudioBlock[] = [];
  totalBars = 0;
  isPlaying = false;
  flowMessage$ = new BehaviorSubject<string | undefined>('Checking your BPM...');
  resetToDefaultIsActive$ = new BehaviorSubject<boolean>(false);
  setBpmBugHappened = false;
  setOneBugHappened = false;
  setPartsBugHappened = false;
  startTime = 0;
  barDuration = 0;
  isDropdownVisible = false;
  dropdownPosition = {x: 0, y: 0};
  activeBlockIndex: number = -1;
  hoveringOverArrow: boolean = false;
  linePosition: number = 0;
  linePositionPercent: number = 0;
  showButtons = false;
  clickPosition: number | null = null;
  showTooltipIndex: number | null = null;
  clickPercent = 0;
  blocks$ = new BehaviorSubject<AudioBlock[]>([]);
  previousBlockName: string | null = null;
  nextBlockName: string | null = null;
  partsList: string[] | undefined;
  colors = [
    '#9546ef', // Purple
    '#f93251', // Red
    '#fd8730', // Orange
    '#41d4aa', // Teal
    '#4f74ff', // Blue
    '#ee4caf', // Magenta
    '#fdbc40', // Amber
    '#42c6d6', // Cyan
  ];
  hideBlocks: boolean = false;
  partNames: { [key: string]: string; } | undefined;
  playerState: 'set-bpm' | 'set-one' | 'set-parts' | 'final' = 'set-bpm';
  metronome!: Metronome;
  bpmControl?: FormControl;
  chosenSelectedOne: SetOneOption | undefined = undefined;
  setOneOptions: SetOneOption[] = [];
  setOneTextButton: string = 'approve option 1';
  changeToLandscape$ = new BehaviorSubject<boolean>(false);
  changeToPortrait$ = new BehaviorSubject<boolean>(false);
  animationStates: { [key: number]: boolean } = {};

  private bpmIconsClicked = false;
  private keyboardListener: ((event: KeyboardEvent) => void) | null = null;
  private mobileService = inject(MobileDetectionService);
  private orientationService = inject(OrientationService);
  private toastService = inject(ToastService);
  private cdr = inject(ChangeDetectorRef);
  private renderer = inject(Renderer2);

  onBpmValueChanged(value: number) {
    this.initMetronome();
    // Validate the BPM input
    if (value > this.initialMaxBpmForRange) {
      // Set the value to the max if it exceeds the maximum allowed value
      this.bpmControl!.setValue(this.initialMaxBpmForRange);
    } else if (value < this.initialMinBpmForRange) {
      // Set the value to the min if it's below the minimum allowed value
      this.bpmControl!.setValue(this.initialMinBpmForRange);
    } else if (this.bpmControl!.valid) {
      // If the value is valid, proceed with your existing logic
      this.bpm = value;

      setTimeout(() => {
        this.metronome.setBpmAndBeatsPerBar(this.bpm, this.calculateBeatsPerBar());
        this.updateProgress();
        const currentTime = this.waveSurfer.getCurrentTime();
        this.seekToNearestBarOrBeat(currentTime);
        if (this.isPlaying) {
          this.startMetronome();
        }

        // Clear and re-add white lines to match the new BPM
        this.addWhiteLinesToBeats();
      }, 10);
    }
  }

  getAnimationDelay(index: number): string {
    return `${index * 100}ms`; // Adjust multiplier as needed
  }

  // Adjusted getBottomBarStyle method
  getBottomBarStyle(block: AudioBlock): { [key: string]: string } {
    if (!block) return {};

    const isActive = this.activeBlockIndex === block.index;

    if (this.animationStates[block.index]) {
      return {
        height: isActive ? '17px' : '8px',
      };
    }
    return {};
  }

  // Animation completion handler
  onAnimationDone(event: any, block: AudioBlock) {
    this.animationStates[block.index] = true;
    this.cdr.detectChanges(); // Since using OnPush change detection
  }

  preventInvalidInput(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowDown', 'ArrowUp', 'Delete', 'Tab'];

    if (
      (event.key < '0' || event.key > '9') &&
      !allowedKeys.includes(event.key) &&
      !(event.key === '-' && (event.target as HTMLInputElement).selectionStart === 0) // Allow negative at the start
    ) {
      event.preventDefault(); // Block non-numeric keys
    }
  }

  onClickOutside() {
    this.isDropdownVisible = false;
    this.hoveringOverArrow = false;
    this.linePosition = 0;
    this.clickPercent = 0;
  }

  onBlockHover(index: number) {
    this.showTooltipIndex = index;
  }

  onBlockLeave(index: number) {
    if (this.showTooltipIndex === index) {
      this.showTooltipIndex = null;
    }
  }

  playPause() {
    if (this.playerState === 'set-parts') {
      this.showButtons = true;
    }
    this.isPlaying = !this.isPlaying;

    this.waveSurfer.playPause().then();

    if (this.isPlaying) {
      this.initMetronome();
      this.startMetronome();
    } else {
      this.metronome.stop();
      this.seekToStartOfCurrentBarOrBeat();
    }
  }

  seekByBarDuration(seekLeft: boolean = true) {
    const currentTime = this.waveSurfer.getCurrentTime();
    const startTime = this.startTime;
    const barDuration = this.barDuration;
    const totalDuration = this.waveSurfer.getDuration();
    const totalBars = Math.floor((totalDuration - startTime) / barDuration);

    let barsSinceStart = Math.round((currentTime - startTime) / barDuration);

    if (seekLeft) {
      barsSinceStart = Math.max(0, barsSinceStart - 1);
    } else {
      barsSinceStart = Math.min(totalBars, barsSinceStart + 1);
    }

    const targetTime = startTime + barsSinceStart * barDuration;

    // Ensure the target time is within the song's duration
    const clampedTargetTime = Math.max(0, Math.min(targetTime, totalDuration));

    // Seek to the calculated target time
    this.waveSurfer.seekTo(clampedTargetTime / totalDuration);
  }

  onMouseEnter(index: number, event: MouseEvent) {
    this.isDropdownVisible = false;
    this.activeBlockIndex = index;

    this.previousBlockName = this.activeBlockIndex > 0 ? this.blocks[this.activeBlockIndex - 1].text : null;
    this.nextBlockName = this.activeBlockIndex < this.blocks.length - 1 ? this.blocks[this.activeBlockIndex + 1].text : null;
  }

  onMouseLeave() {
    this.hoveringOverArrow = false;
    this.linePosition = 0;
  }

  updateBlockName(newName: string, event: MouseEvent) {
    event.preventDefault();
    const index = this.activeBlockIndex;

    if (index !== null && index < this.struct.length) {
      this.updateAndAdjustBlockNames(newName, index, this.struct[index][0]);
    }

    this.onClickOutside();
  }

  eventPrevent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onPlayerClick(event: MouseEvent) {
    const waveformRect = this.waveformEl.nativeElement.getBoundingClientRect();
    const clickX = event.clientX - waveformRect.left;
    const clickPositionPercentage = clickX / waveformRect.width;

    const totalDuration = this.waveSurfer.getDuration();
    const clickTime = clickPositionPercentage * totalDuration;

    // Reuse the common logic for seeking by bar
    this.seekToNearestBarOrBeat(clickTime);
  }

  onBlockDoubleClick(block: AudioBlock, event: MouseEvent) {
    this.eventPrevent(event);

    this.onClickOutside();
    // Get the start time of the block
    const totalDuration = this.waveSurfer.getDuration();
    const blockStartPercent = parseFloat(block.left) / 100;
    const blockStartTime = blockStartPercent * totalDuration;

    // Seek to the block's start time
    this.waveSurfer.seekTo(blockStartTime / totalDuration);

    // Play the audio from that position
    if (!this.isPlaying) {
      this.waveSurfer.play().then();
      this.isPlaying = true;
    }
  }

  performAction(action: string, event: MouseEvent) {
    this.eventPrevent(event);
    this.setLinePositionForContextMenu(event);
    switch (action) {
      case 'split':
        this.onClickOutside();
        this.activeBlockIndex = this.getBlockIndexAtPosition(event);
        this.splitBlock(this.activeBlockIndex);
        console.log('strut: ', this.struct);
        break;
      case 'mergeLeft':
        this.mergeWithPreviousBlock(this.activeBlockIndex);
        console.log('strut: ', this.struct);
        break;
      case 'mergeRight':
        this.mergeWithNextBlock(this.activeBlockIndex);
        console.log('strut: ', this.struct);
        break;
      case 'delete':
        this.deleteBlock(this.activeBlockIndex);
        console.log('strut: ', this.struct);
        break;
      default:
        console.error('Unknown action');
    }
    this.fixOverlapsAndGapsInStruct();
    // this.showButtons = false;
    this.onClickOutside();
  }

  openDropdown(event: MouseEvent) {
    this.eventPrevent(event);

    this.onClickOutside();

    const boundingRect = this.playerBlocksEl.nativeElement.getBoundingClientRect();

    if (!event.currentTarget) {
      return;
    }

    const targetElement = event.currentTarget as HTMLElement;
    const blockRect = targetElement.getBoundingClientRect();

    let dropdownWidth = 272; // Default width

    // Determine the available space within playerBlocksEl
    const blockRightEdge = blockRect.right - boundingRect.left;

    // Calculate xPosition to align the dropdown
    let xPosition = Math.min(blockRightEdge, boundingRect.width - dropdownWidth) + 10;

    const yPosition = 118 / 2 - 20;

    // Set the final position for the dropdown
    this.dropdownPosition = {
      x: xPosition,
      y: yPosition
    };

    this.previousBlockName = this.activeBlockIndex > 0 ? this.blocks[this.activeBlockIndex - 1].text : null;
    this.nextBlockName = this.activeBlockIndex < this.blocks.length - 1 ? this.blocks[this.activeBlockIndex + 1].text : null;

    this.isDropdownVisible = true;
  }

  onWaveformClick(event: MouseEvent) {
    if (this.playerState === 'set-parts') {
      this.showButtons = true;
      const waveformRect = this.waveformEl.nativeElement.getBoundingClientRect();
      this.clickPosition = (event.clientX - waveformRect.left) / waveformRect.width;
    }
  }

  getColor(part: string) {
    return part === this.blocks[this.activeBlockIndex].text.split(' ')[0];
  }

  attachKeyboardListener() {
    if (!this.keyboardListener) {
      this.keyboardListener = (event: KeyboardEvent) => {
        // Your existing handler code
        const isPlayerStateInSetBpm = this.playerState === 'set-bpm';

        if (this.currentActiveComponent && !this.flowMessage$.getValue()) {
          switch (event.code) {
            case 'Space':
            case ' ':
              event.preventDefault();
              event.stopPropagation();
              this.playPause();
              break;
            case 'ArrowRight':
              if (isPlayerStateInSetBpm) {
                this.movePlaybackByBeats(1);
              } else {
                this.seekByBarDuration(false);
              }
              break;
            case 'ArrowLeft':
              if (isPlayerStateInSetBpm) {
                this.movePlaybackByBeats(-1);
              } else {
                this.seekByBarDuration(true);
              }
              break;
          }
        }
      };

      window.addEventListener('keydown', this.keyboardListener);
    }
  }

  // Method to remove the listener
  detachKeyboardListener() {
    if (this.keyboardListener) {
      window.removeEventListener('keydown', this.keyboardListener);
      this.keyboardListener = null;
    }
  }

  expandLeft(event: MouseEvent) {
    event.stopPropagation();
    const index = this.activeBlockIndex;

    if (index === 0) return; // Can't expand left if it's the first block

    const currentBlock = this.blocks[index];
    const previousBlock = this.blocks[index - 1];
    const barWidth = (this.barDuration / this.waveSurfer.getDuration()) * 100;

    // Get the previous block's bars from the struct
    const previousStructBlock = this.struct[index - 1];
    const previousBlockBars = previousStructBlock[2] - previousStructBlock[1] + 1;

    if (previousBlockBars <= 2) return; // Prevent shrinking below 2 bars

    // Update the struct
    previousStructBlock[2] -= 1; // Reduce end bar of previous block
    this.struct[index][1] -= 1;  // Reduce start bar of current block

    // Use the helper function to adjust visuals
    this.expandBlock(currentBlock, previousBlock, barWidth, true);
    this.updateBlocks();
  }

  expandRight(event: MouseEvent) {
    event.stopPropagation();
    const index = this.activeBlockIndex;

    if (index === this.blocks.length - 1) return; // Can't expand right if it's the last block

    const currentBlock = this.blocks[index];
    const nextBlock = this.blocks[index + 1];
    const barWidth = (this.barDuration / this.waveSurfer.getDuration()) * 100;

    // Get the next block's bars from the struct
    const nextStructBlock = this.struct[index + 1];
    const nextBlockBars = nextStructBlock[2] - nextStructBlock[1] + 1;

    if (nextBlockBars <= 2) return; // Prevent shrinking below 2 bars

    // Update the struct
    nextStructBlock[1] += 1; // Increase start bar of next block
    this.struct[index][2] += 1; // Increase end bar of current block

    // Use the helper function to adjust visuals
    this.expandBlock(currentBlock, nextBlock, barWidth, false);
    this.updateBlocks();
  }

  expandBlock(currentBlock: AudioBlock, adjacentBlock: AudioBlock, barWidth: number, expandLeft: boolean = true) {
    if (expandLeft) {
      const newLeft = parseFloat(currentBlock.left) - barWidth;
      adjacentBlock.width = `${parseFloat(adjacentBlock.width) - barWidth}%`;
      currentBlock.left = `${newLeft}%`;
      currentBlock.width = `${parseFloat(currentBlock.width) + barWidth}%`;
    } else {
      adjacentBlock.left = `${parseFloat(adjacentBlock.left) + barWidth}%`;
      adjacentBlock.width = `${parseFloat(adjacentBlock.width) - barWidth}%`;
      currentBlock.width = `${parseFloat(currentBlock.width) + barWidth}%`;
    }
  }

  onArrowHover(isHovering: boolean, isRightArrow: boolean) {
    this.hoveringOverArrow = isHovering;

    if (isHovering) {
      this.setLinePosition(this.activeBlockIndex, isRightArrow);
    } else {
      this.linePosition = 0; // Reset line position when not hovering
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.waveformEl && this.progressLine) {
      const clickedInsideWaveform = this.waveformEl.nativeElement.contains(event.target);
      const clickedInsideProgressLine = this.progressLine.nativeElement.contains(event.target);

      const targetElement = event.target as HTMLElement;
      const clickedOnPlayButton =
        targetElement.closest('play-button-class') !== null ||
        targetElement.tagName.toLowerCase() === 'path';

      if (!clickedInsideWaveform && !clickedInsideProgressLine && !clickedOnPlayButton) {
        this.showButtons = false;
      }

      if (clickedInsideWaveform) {
        this.onWaveformClick(event);
      }
    }
  }

  // Re-index blocks after modification
  reindexBlocks(blocks: AudioBlock[]) {
    blocks.forEach((block, i) => {
      block.index = i;
    });
    this.detectChanges();
  }

  // Calculate progress percentage
  calculateProgress(): number {
    return (this.waveSurfer.getCurrentTime() / this.waveSurfer.getDuration()) * 100;
  }

  addWhiteLinesToBeats() {
    // Ensure the waveform is ready and the duration is available
    const totalDuration = this.waveSurfer.getDuration();
    if (!totalDuration) return;

    // Clear any previous lines
    this.clearLines();

    // Calculate the duration of one beat (in seconds)
    const beatDuration = 60 / this.bpm;

    // Calculate the total number of beats in the track
    const totalBeats = Math.floor(totalDuration / beatDuration);

    // Loop through each beat and add a white line at each beat's position
    for (let i = 0; i <= totalBeats; i++) {
      // Time position of each beat in the track (in seconds)
      const beatTime = i * beatDuration;

      // Convert the time to a percentage position of the waveform
      const positionPercent = (beatTime / totalDuration) * 100;

      // Add a white line at the calculated position
      this.addWaveformLine(positionPercent);
    }
    this.detectChanges();
  }

  approveBpm() {
    this.stopSongAndMetronome();
    this.setBpmBugHappened = false;
    this.flowMessage$.next('Setting the correct BPM');

    this.audioService.sketchBpm = this.bpm;

    if (this.isDemoSite || this.bpm === this.originalBpm) {
      setTimeout(() => {
        this.moveToNextPlayerState('set-one', this.bpmPhaseStruct);
      }, 1500);
      return;
    }

    this.audioService.setNewBpm().pipe(
      switchMap(() => this.audioService.getStruct())
    ).subscribe({
      next: (data: any) => {
        this.struct = data;
        this.moveToNextPlayerState('set-one', this.struct);
      },
      error: (err: any) => {
        this.setBpmBugHappened = true;
        this.flowMessage$.next(undefined);
      }
    });
  }

  approveOne() {
    this.stopSongAndMetronome();
    this.setOneBugHappened = false;
    this.flowMessage$.next('Placing your song perfectly on the grid');

    const selectedOne =
      this.setOneOptions.find((option) => option.selected)!;

    if (this.isDemoSite) {
      setTimeout(() => {
        this.handleStructData(this.originalStruct || this.struct);
      }, this.chosenSelectedOne ? 0 : 1500);
      return;
    }

    if (this.chosenSelectedOne?.name === selectedOne.name) {
      this.handleStructData(this.originalStruct || this.struct);
    } else {
      this.audioService.setNewOne(selectedOne.offset).pipe(
        switchMap(() => this.audioService.getStruct())
      ).subscribe({
        next: (data: any) => {
          if (!this.chosenSelectedOne) {
            this.chosenSelectedOne = {
              playingTime: selectedOne.playingTime,
              name: selectedOne.name,
              offset: selectedOne.offset,
              selected: true,
              positionPercent: selectedOne.positionPercent
            };
          }
          this.handleStructData(data);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 423) {
            this.modalService.openChatErrorModal({errorNumber: 423});
            return;
          }
          this.handleStructError(error);
        }
      });
    }
  }

  approveParts() {
    this.detachKeyboardListener();
    this.stopSongAndMetronome();
    const isPortrait = this.orientationService.isPortrait$.getValue();
    if (this.mobileService.isMobileDevice() && !isPortrait) {
      this.changeToPortrait$.next(true);
    }
    this.stopSongAndMetronome();
    this.destroyWaveSurferInstance();

    this.flowMessage$.next('Setting the correct song structure');

    if (this.isDemoSite) {
      setTimeout(() => {
        this.detachKeyboardListener();
        this.playerState = 'final';
        this.chatService.currentlyActiveChatPart$.next('prompt-input');
        this.detectChanges();
      }, 1500);
      return;
    }
    this.audioService.setStruct(this.struct)
      .subscribe({
        next: (res: any) => {
          this.detachKeyboardListener();
          this.playerState = 'final';
          this.chatService.currentlyActiveChatPart$.next('prompt-input');
          this.detectChanges();
        },
        error: err => {
          this.toastService.showToast('Something went wrong!, please start a new chat and try again', 'error');
          console.log('setStruct error: ', err);
        }
      });
  }

  isPlayerStateInSet() {
    return this.playerState === 'set-one' || this.playerState === 'set-bpm';
  }

  movePlayerToChosenOnePosition(name: string) {
    this.initMetronome();

    // Deselect all options first
    this.setOneOptions.forEach(option => option.selected = false);

    const selectedOption = this.setOneOptions.find((option) => option.name === name)!;

    // Mark the clicked option as selected
    selectedOption.selected = true;
    this.setOneTextButton = `approve ${selectedOption.name}`;

    // Update the metronome's oneBeatOffset and downbeatOffset
    this.metronome.setOneBeatOffset(selectedOption.offset);
    this.metronome.setDownbeatOffset(selectedOption.offset);

    // Seek to the position based on the positionPercent
    this.waveSurfer.seekTo(selectedOption.positionPercent / 100); // Seek based on the percentage
    this.updateProgress(); // Optionally update the progress visually
  }

  returnToDefaultStructure() {
    this.flowMessage$.next('Hang tight! We\'re resetting the changes to the structure so that you can start again.');
    this.resetToDefaultIsActive$.next(true);
    // Make a deep copy of the original struct to prevent reference issues
    this.struct = JSON.parse(JSON.stringify(this.originalStruct));

    // Move to set-parts state with the fresh copy of original struct
    this.moveToNextPlayerState('set-parts', this.struct);
  }

  bpmUpOrDown(value: number) {
    this.bpmIconsClicked = true;
    this.bpmControl?.setValue(this.bpm + value);
  }

  doubleOrHalfTimeBpm(isDouble = true) {
    this.bpmIconsClicked = true;
    if (isDouble) {
      this.bpmControl?.setValue(this.bpm * 2);
    } else {
      this.bpmControl?.setValue(Math.floor(this.bpm / 2));
    }
  }

  confirmAndContinue(event: MouseEvent | TouchEvent) {
    // Prevent the action if triggered by a keyboard
    if (event instanceof MouseEvent || event instanceof TouchEvent) {
      switch (this.playerState) {
        case 'set-bpm':
          this.approveBpm();
          break;
        case 'set-one':
          this.approveOne();
          break;
        case 'set-parts':
          this.approveParts();
          break;
      }
    }
  }

  takeStepBack() {
    this.stopSongAndMetronome();
    switch (this.playerState) {
      case 'set-one':
        this.flowMessage$.next('Going back to confirm or adjust BPM...');
        this.moveToNextPlayerState('set-bpm', this.bpmPhaseStruct);
        break;
      case 'set-parts':
        this.flowMessage$.next('Going back to adjust the song to the grid...');
        this.moveToNextPlayerState('set-one', this.originalStruct);
        break;
    }
  }

  getBackTitle() {
    if (this.playerState === 'set-one') {
      return 'adjust BPM';
    }
    return 'adjust song to grid';
  }

  ngOnDestroy() {
    this.detachKeyboardListener();
  }

  protected override afterViewInit() {
    if (this.isDemoSite) {
      this.audioService.sketchBpm = 76;
    }

    this.attachKeyboardListener();

    const isMobile = this.mobileService.isMobileDevice();

    if (isMobile) {
      this.orientationService.isPortrait$.subscribe((isPortrait) => {
        if (isPortrait) {
          this.changeToLandscape$.next(true);
          this.changeToPortrait$.next(false);
        } else {
          this.changeToLandscape$.next(false);
          if (this.playerState === 'final') {
            this.changeToPortrait$.next(true);
          }
        }
      });
    }

    this.audioService.getValidPartNames()
      .subscribe({
        next: (value) => {
          this.createPartNamesForMenu(value.part_names);
          if (value) {
            if (this.isDemoSite) {
              this.setDemoSiteValues();
            } else {
              this.setRealCharValues();
            }
          }
        }
      });
  }

  private detectChanges() {
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  private createPartNamesForMenu(partNames: any) {
    this.partNames = partNames;

    this.partsList = Object.keys(partNames)
      .map(key => key.replace(/\s\d+$/, '')) // Remove the numbers at the end
      .filter((value, index, self) => self.indexOf(value) === index) // Keep only unique values
      .sort(); // Sort alphabetically
  }

  private setDemoSiteValues() {
    const struct = {
      bpm: 76,
      s3_url: 'assets/Tears on the Pavement.mp3',
      struct:
        '{"76": [["Intro", 1, 5], ["Verse 1", 6, 12], ["Verse 2", 13, 16], ["Verse 3", 17, 20], ["Verse 4", 21, 24], ["Chorus 1", 25, 28], ["Chorus 2", 29, 30], ["Verse 5", 31, 38], ["Chorus 3", 39, 42], ["Chorus 4", 43, 46], ["Outro", 47, 54]], "152": [["Intro", 1, 9], ["Verse 1", 10, 24], ["Verse 2", 25, 32], ["Verse 3", 33, 40], ["Verse 4", 41, 48], ["Chorus 1", 49, 56], ["Chorus 2", 57, 60], ["Verse 5", 61, 76], ["Chorus 3", 77, 84], ["Chorus 4", 85, 91], ["Outro", 92, 108]]}',
      time_signature: '4/4',
    };
    this.struct = struct;
    this.bpmPhaseStruct = struct;
    this.originalStruct = JSON.parse(JSON.stringify(struct));
    this.isLoading$.next(false);
    setTimeout(() => {
      this.initDataFromGetStruct(this.struct);
    }, 3000);
    this.scrollService.scrollToBottomClicked$.next(true);
  }

  private setRealCharValues() {
    this.audioService.getStruct()
      .subscribe({
        next: (data: any) => {
          this.struct = data;
          this.originalStruct = JSON.parse(JSON.stringify(data));  // Deep copy
          this.bpmPhaseStruct = data;
        },
        complete: () => {
          this.isLoading$.next(false);
          setTimeout(() => {
            this.initDataFromGetStruct(this.struct);
          }, 3000);
          this.scrollService.scrollToBottomClicked$.next(true);
        }
      });
  }

  // Method to handle the common subscription logic
  private handleStructData(data: any) {
    this.struct = data;
    this.moveToNextPlayerState('set-parts', data);
  }

  // Method to handle the error scenario
  private handleStructError(err: any) {
    this.flowMessage$.next(undefined);
    this.setOneBugHappened = true;
    this.detectChanges();
  }

  private addWhiteLinesToFirstBarOnlyUsingTimeSignature() {
    const [beatsPerBar] = this.time_signature.split('/').map(Number); // Extract the number of beats per bar
    const beatDuration = 60 / this.bpm; // Duration of each beat in seconds

    // Clear the previous setOneOptions array
    this.setOneOptions = [];

    // Get the total duration of the song (for calculating position percentage)
    const totalDuration = this.waveSurfer.getDuration();

    // Clear any previous lines (if needed)
    this.clearLines();

    // Add white lines for each beat in the first bar
    for (let i = 0; i < beatsPerBar; i++) {
      const beatTime = i * beatDuration; // Time position of each beat in the first bar

      // Convert beat time to percentage of the total duration
      const positionPercent = (beatTime / totalDuration) * 100;

      // Add the white line at the calculated percentage
      this.addWaveformLine(positionPercent);

      // Add options to the setOneOptions array
      this.setOneOptions.push({
        name: `Option ${i + 1}`, // Name the option based on the index (Option 1, Option 2, etc.)
        offset: i,  // The offset corresponds to the position of the beat
        positionPercent: positionPercent,
        selected: i === 0,
        playingTime: this.truncateToDecimals(beatTime)
      });
    }

    // Initialize the metronome and set the oneBeatOffset after setOneOptions is populated
    this.initMetronome(); // Ensure metronome is initialized
    if (this.metronome && this.setOneOptions.length > 0) {
      const selectedOption = this.setOneOptions.find(option => option.selected)!;
      this.metronome.setOneBeatOffset(selectedOption.offset);
    }

    if (this.chosenSelectedOne) {
      this.movePlayerToChosenOnePosition(this.chosenSelectedOne.name);
    }

    this.detectChanges();
  }

  private truncateToDecimals(num: number): number {
    const factor = Math.pow(10, 6);
    return Math.trunc(num * factor) / factor;
  }

  private startMetronome() {
    if (this.metronome) {
      const currentTime = this.waveSurfer.getCurrentTime();
      this.metronome.start(currentTime, this.playerState === 'set-parts');
    }
  }

  private movePlaybackByBeats(beats: number) {
    const beatDuration = 60 / this.bpm; // Duration of one beat in seconds
    const currentTime = this.waveSurfer.getCurrentTime();
    const newTime = currentTime + beats * beatDuration;

    // Ensure new time is within bounds
    const duration = this.waveSurfer.getDuration();
    if (newTime >= 0 && newTime <= duration) {
      this.waveSurfer.seekTo(newTime / duration);
    }
    if (newTime <= 0) {
      this.waveSurfer.seekTo(0);
    }
    if (newTime >= duration) {
      this.waveSurfer.seekTo(duration);
    }
  }

  private seekToNearestBarOrBeat(targetTime: number) {
    const barOrBeatDuration = this.isPlayerStateInSet() ? 60 / this.bpm : this.barDuration;
    const startTime = this.startTime;
    const totalDuration = this.waveSurfer.getDuration();

    // Calculate the nearest bar by rounding the target time to the nearest bar
    const barsOrBeatsSinceStart = Math.round((targetTime - startTime) / barOrBeatDuration);

    // Calculate the start time of the nearest bar
    const nearestBarOrBeatTime = startTime + barsOrBeatsSinceStart * barOrBeatDuration;

    // Ensure the nearest bar time is within the song's duration
    const clampedTime = Math.max(0, Math.min(nearestBarOrBeatTime, totalDuration));

    // Seek to the nearest bar
    this.waveSurfer.seekTo(clampedTime / totalDuration);

    // Update the progress line visually
    this.updateProgress();
  }

  private initDataFromGetStruct(data: any) {
    if (this.isBpmValueInvalid(this.audioService.sketchBpm) && this.isBpmValueInvalid(data['bpm'])) {
      this.bpm = 120;
    } else {
      this.bpm = this.audioService.sketchBpm || data['bpm'];
    }
    this.originalBpm = this.bpm;

    this.currentUrl = data['s3_url'];

    // Initialize metronome if player state requires it
    if (this.isPlayerStateInSet()) {

      // this.initialMinBpmForRange = this.bpm - 5;
      // this.initialMaxBpmForRange = this.bpm + 5;
      this.initialMinBpmForRange = 40;
      this.initialMaxBpmForRange = 300;

      this.bpmControl = new FormControl(this.bpm, [
        Validators.required,
        Validators.min(this.initialMinBpmForRange),
        Validators.max(this.initialMaxBpmForRange)
      ]);

      // Listen for value changes and handle the changes dynamically
      this.bpmControl.valueChanges
        .pipe(
          debounce(() => timer(this.getBpmDebounceTime())) // Dynamically debounce based on bpmIconsClicked
        )
        .subscribe((value) => this.onBpmValueChanged(value));
    } else {
      const struct = JSON.parse(data['struct']);

      this.struct = struct[this.bpm] ? struct[this.bpm] : struct;
    }
    this.time_signature = data['time_signature'];

    this.initWaveSurfer(this.currentUrl);
  }

  private setLinePositionForContextMenu(event: MouseEvent) {
    const waveformRect = this.waveformEl.nativeElement.getBoundingClientRect();
    const clickPosition = event.clientX - waveformRect.left;

    this.linePosition = this.alignToNearestBar(clickPosition);
    this.linePositionPercent = (this.linePosition / waveformRect.width) * 100;
  }

  private seekToStartOfCurrentBarOrBeat() {
    const currentTime = this.waveSurfer.getCurrentTime();
    const barOrBeatDuration = this.isPlayerStateInSet() ? 60 / this.bpm : this.barDuration;
    const startTime = this.startTime;
    const totalDuration = this.waveSurfer.getDuration();

    // Calculate how many full bars or beats have elapsed since startTime
    const barsOrBeatsSinceStart = Math.round((currentTime - startTime) / barOrBeatDuration);

    // Calculate the start time of the nearest bar or beat
    const currentBarOrBeatStartTime = startTime + barsOrBeatsSinceStart * barOrBeatDuration;

    // Ensure the time is within the song's duration
    const clampedTime = Math.max(0, Math.min(currentBarOrBeatStartTime, totalDuration));

    // Seek to the start of the current bar or beat
    this.waveSurfer.seekTo(clampedTime / totalDuration);

    // Update the progress line visually
    this.updateProgress();
  }

  private setLinePosition(index: number, isRightArrow: boolean) {
    const block = this.blocks[index];
    const waveformRect = this.waveformEl.nativeElement.getBoundingClientRect();

    if (isRightArrow) {
      // Calculate position based on the end of the block for the right arrow
      const blockEndPosition =
        ((parseFloat(block.left) + parseFloat(block.width)) / 100) * waveformRect.width;
      this.linePosition = this.alignToNearestBar(blockEndPosition);
    } else {
      // Calculate position based on the start of the block for the left arrow
      const blockStartPosition = (parseFloat(block.left) / 100) * waveformRect.width;
      this.linePosition = this.alignToNearestBar(blockStartPosition);
    }
  }

  private initWaveSurfer(url: string) {
    // Destroy previous instance if it exists to prevent memory leaks
    if (this.waveSurfer) {
      this.destroyWaveSurferInstance();
    }

    // Initialize WaveSurfer with the WebAudio plugin
    this.waveSurfer = WaveSurfer.create({
      container: this.waveformEl.nativeElement,
      waveColor: '#393d44',
      progressColor: '#393d44',
      cursorColor: '#e53252',
      cursorWidth: 3,
      height: 60,
    });

    // Set up event listeners before loading
    this.waveSurfer.on('ready', () => {
      this.flowMessage$.next(undefined);
      this.calculateBarDuration();
      this.calculateTotalBars();
      switch (this.playerState) {
        case 'set-bpm':
          this.addWhiteLinesToBeats();
          break;
        case 'set-one':
          this.addWhiteLinesToFirstBarOnlyUsingTimeSignature();
          break;
        case 'set-parts':
          this.createBlocks();
          break;
      }
      this.scrollService.scrollToBottomClicked$.next(true);
    });

    this.waveSurfer.on('audioprocess', () => {
      this.updateProgress();
    });

    this.waveSurfer.on('seeking', () => {
      if (this.isPlaying && this.metronome) {
        this.startMetronome();
      }
      this.updateProgress();
    });

    this.waveSurfer.on('play', () => {
      this.initMetronome();
      this.startMetronome();
    });

    this.waveSurfer.on('pause', () => {
      this.isPlaying = false;
      if (this.metronome) {
        this.metronome.stop();
      }
    });

    this.waveSurfer.on('destroy', () => {
      this.isPlaying = false;
      this.metronome.stop();
    });

    this.waveSurfer.on('finish', () => {
      this.isPlaying = false;
      this.metronome.stop();
    });

    if (this.isPlayerStateInSet()) {
      this.sliceAudioToFirstSeconds(url, 30);
    } else {
      this.metronome.generateAudioWithMetronome(url)
        .then((res) => {
          this.waveSurfer.load(res);

          // Revoke the object URL after WaveSurfer has loaded it
          this.waveSurfer.on('ready', () => {
            URL.revokeObjectURL(res);
          });
        })
        .catch(error => {
          this.waveSurfer.load(url).catch((error) => {
            console.error('Error loading audio:', error);
          });
        });
    }

    this.waveSurfer.on('error', (e: any) => {
      console.error('WaveSurfer error:', e);
    });
  }

  private sliceAudioToFirstSeconds(url: string, seconds: number) {
    const audioContext = new AudioContext();

    fetch(url)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        const sampleRate = audioBuffer.sampleRate;
        const numberOfChannels = audioBuffer.numberOfChannels;
        const sliceDuration = seconds; // 30 seconds

        // Create a new empty buffer for the first 30 seconds
        const slicedBuffer = audioContext.createBuffer(
          numberOfChannels,
          sampleRate * sliceDuration,
          sampleRate
        );

        // Copy the first 30 seconds into the new buffer
        for (let channel = 0; channel < numberOfChannels; channel++) {
          slicedBuffer.copyToChannel(
            audioBuffer.getChannelData(channel).slice(0, sampleRate * sliceDuration),
            channel
          );
        }

        // Convert the sliced buffer to a Blob
        this.convertAudioBufferToBlob(slicedBuffer, audioContext.sampleRate)
          .then(blob => {
            // Load the Blob into WaveSurfer
            this.loadSlicedAudioToWaveSurfer(blob);
          })
          .catch(error => console.error('Error converting audio buffer to blob:', error));
      })
      .catch(error => console.error('Error loading and slicing audio:', error));
  }

  private convertAudioBufferToBlob(audioBuffer: AudioBuffer, sampleRate: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const numberOfChannels = audioBuffer.numberOfChannels;
      const length = audioBuffer.length * numberOfChannels * 2 + 44;
      const buffer = new ArrayBuffer(length);
      const view = new DataView(buffer);

      // Write WAV file header
      this.writeWavHeader(view, audioBuffer, sampleRate);

      // Write audio samples
      let offset = 44;
      for (let i = 0; i < audioBuffer.length; i++) {
        for (let channel = 0; channel < numberOfChannels; channel++) {
          const sample = audioBuffer.getChannelData(channel)[i];
          view.setInt16(offset, sample * 0x7fff, true);
          offset += 2;
        }
      }

      const blob = new Blob([view], {type: 'audio/wav'});
      resolve(blob);
    });
  }

  private writeWavHeader(view: DataView, audioBuffer: AudioBuffer, sampleRate: number) {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleBits = 16;
    const byteRate = sampleRate * numberOfChannels * sampleBits / 8;
    const blockAlign = numberOfChannels * sampleBits / 8;

    // Chunk ID "RIFF"
    this.setString(view, 0, 'RIFF');
    // Chunk Size
    view.setUint32(4, 36 + audioBuffer.length * numberOfChannels * 2, true);
    // Format "WAVE"
    this.setString(view, 8, 'WAVE');
    // Sub-chunk ID "fmt "
    this.setString(view, 12, 'fmt ');
    // Sub-chunk size
    view.setUint32(16, 16, true);
    // Audio format (PCM)
    view.setUint16(20, 1, true);
    // Number of channels
    view.setUint16(22, numberOfChannels, true);
    // Sample rate
    view.setUint32(24, sampleRate, true);
    // Byte rate
    view.setUint32(28, byteRate, true);
    // Block align
    view.setUint16(32, blockAlign, true);
    // Bits per sample
    view.setUint16(34, sampleBits, true);
    // Sub-chunk ID "data"
    this.setString(view, 36, 'data');
    // Sub-chunk size
    view.setUint32(40, audioBuffer.length * numberOfChannels * 2, true);
  }

  private setString(view: DataView, offset: number, string: string) {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  private loadSlicedAudioToWaveSurfer(blob: Blob) {
    this.waveSurfer.loadBlob(blob); // Use WaveSurfer's loadBlob method
  }

  private calculateBeatsPerBar(): number {
    const [beatsPerBar] = this.time_signature.split('/').map(Number);
    return beatsPerBar;
  }

  private calculateTotalBars() {
    this.calculateBarDuration(); // Update barDuration with new BPM
    const totalDuration = this.waveSurfer.getDuration();
    this.totalBars = Math.floor((totalDuration - this.startTime) / this.barDuration);
  }

  private calculateBarWidth(): number {
    const waveformWidth = this.waveformEl.nativeElement.getBoundingClientRect().width;
    return waveformWidth / this.totalBars;
  }

  private alignToNearestBar(position: number): number {
    // Calculate the width of one bar in pixels
    const waveformRect = this.waveformEl.nativeElement.getBoundingClientRect();
    const barWidth = this.calculateBarWidth();

    // Adjust the position based on where the bars start, which is from `this.startTime`
    const startOffset = (this.startTime / this.waveSurfer.getDuration()) * waveformRect.width;

    // Calculate the relative position from the `startTime`
    const relativePosition = position - startOffset;

    // Align the position to the nearest bar
    const alignedPosition = Math.round(relativePosition / barWidth) * barWidth;

    // Add the `startOffset` to ensure alignment starts from `this.startTime`
    return alignedPosition + startOffset;
  }

  private createBlocks() {
    this.blocks = [];
    this.clearLines();

    const totalDuration = this.waveSurfer.getDuration();

    this.struct.forEach((blockData: any, index: any) => {
      const [blockName, startBar, endBar] = blockData;

      const blockStartTime = this.startTime + (startBar - 1) * this.barDuration;
      const blockEndTime = this.startTime + endBar * this.barDuration;

      const leftPercent = parseFloat(((blockStartTime / totalDuration) * 100).toFixed(5));
      const widthPercent = parseFloat((((blockEndTime - blockStartTime) / totalDuration) * 100).toFixed(5));

      const newBlock: AudioBlock = {
        text: blockName,
        shortText: this.getShorthandName(blockName),
        left: `${leftPercent}%`,
        width: `${widthPercent}%`,
        color: this.colors[index % this.colors.length],
        index: index,
      };

      this.blocks.push(newBlock);

      if (index > 0) {
        this.addWaveformLine(leftPercent);
      }
    });

    this.blocks$.next(this.blocks);
    this.resetToDefaultIsActive$.next(false);

    if (this.blocks.length > 0 && this.flowMessage$.getValue()) {
      setTimeout(() => {
        this.flowMessage$.next(undefined);
        this.detectChanges();
      }, 3000);
    }
    this.detectChanges();
  }

  private addWaveformLine(positionPercent: number) {
    const line = document.createElement('div');
    line.classList.add('white-line-on-waveform');
    line.style.left = `${positionPercent}%`;
    this.waveformEl.nativeElement.appendChild(line);
  }

  private clearLines() {
    // Clear white lines from the waveform
    this.waveformEl.nativeElement
      .querySelectorAll('.white-line-on-waveform')
      .forEach((line: any) => line.remove());
  }

  private updateProgress() {
    if (this.progressLine?.nativeElement) {
      const progress = this.calculateProgress() || 0;
      this.progressDiv.nativeElement.style.width = `${progress}%`;
      if (progress > 0) {
        this.progressLine.nativeElement.style.left = `${progress}%`;
        this.progressLine.nativeElement.style.visibility = 'visible';
      } else {
        this.progressLine.nativeElement.style.visibility = 'hidden';
      }
    }
    this.detectChanges();
  }

  private getBlockIndexAtPosition(event: MouseEvent): number {
    const waveformRect = this.waveformEl.nativeElement.getBoundingClientRect();
    const clickX = event.clientX - waveformRect.left; // x position within the element.
    const clickPercent = (clickX / waveformRect.width) * 100;

    this.clickPercent = clickPercent;

    let closestIndex = -1;
    let closestDistance = Infinity;

    for (let i = 0; i < this.blocks.length; i++) {
      const block = this.blocks[i];
      const start = parseFloat(block.left);
      const end = start + parseFloat(block.width);

      // Check if the click is within the block's bounds
      if (clickPercent >= start && clickPercent <= end) {
        return i;
      }

      // If not, calculate the distance to the block's start or end
      const distanceToStart = Math.abs(clickPercent - start);
      const distanceToEnd = Math.abs(clickPercent - end);

      // Find the smallest distance
      const minDistance = Math.min(distanceToStart, distanceToEnd);

      if (minDistance < closestDistance) {
        closestDistance = minDistance;
        closestIndex = i;
      }
    }

    return closestIndex;
  }

  private mergeWithPreviousBlock(index: number | null) {
    if (index === null || index === 0) return;

    const currentBlock = this.blocks[index];
    const previousBlock = this.blocks[index - 1];

    // Merge the widths and update the previous block
    const newWidth =
      parseFloat(previousBlock.width) +
      parseFloat(currentBlock.width) +
      (parseFloat(currentBlock.left) - (parseFloat(previousBlock.left) + parseFloat(previousBlock.width)));
    previousBlock.width = `${newWidth}%`;

    // Update the end bar of the previous block in the struct
    this.struct[index - 1][2] = this.struct[index][2];

    // Remove the current block
    this.blocks.splice(index, 1);
    this.struct.splice(index, 1);

    // Adjust sequences for both block types
    const previousBlockType = this.getBlockType(previousBlock.text);
    const currentBlockType = this.getBlockType(currentBlock.text);

    this.adjustBlockSequence(previousBlockType);
    if (previousBlockType !== currentBlockType) {
      this.adjustBlockSequence(currentBlockType);
    }
  }

  private mergeWithNextBlock(index: number | null) {
    if (index === null || index === this.blocks.length - 1) return;

    const currentBlock = this.blocks[index];
    const nextBlock = this.blocks[index + 1];

    // Merge the widths and update the current block
    const newWidth =
      parseFloat(currentBlock.width) +
      parseFloat(nextBlock.width) +
      (parseFloat(nextBlock.left) - (parseFloat(currentBlock.left) + parseFloat(currentBlock.width)));
    currentBlock.width = `${newWidth}%`;

    // Update the end bar of the current block in the struct
    this.struct[index][2] = this.struct[index + 1][2];

    // Remove the next block
    this.blocks.splice(index + 1, 1);
    this.struct.splice(index + 1, 1);

    // Adjust sequences for both block types
    const currentBlockType = this.getBlockType(currentBlock.text);
    const nextBlockType = this.getBlockType(nextBlock.text);

    this.adjustBlockSequence(currentBlockType);
    if (currentBlockType !== nextBlockType) {
      this.adjustBlockSequence(nextBlockType);
    }
  }

  private splitBlock(index: number | null) {
    if (index === null) {
      return;
    }

    const block = this.blocks[index];
    const blockStartPercent = parseFloat(block.left);
    const blockEndPercent = blockStartPercent + parseFloat(block.width);

    // Ensure the split is within the block's boundaries
    if (this.linePositionPercent <= blockStartPercent || this.linePositionPercent >= blockEndPercent) {
      return;
    }

    const totalDuration = this.waveSurfer.getDuration();

    // Convert linePositionPercent to time
    let splitTime = (this.linePositionPercent / 100) * totalDuration;

    // Align splitTime to the nearest bar
    const splitBar = Math.round((splitTime - this.startTime) / this.barDuration);
    splitTime = this.startTime + splitBar * this.barDuration;

    // Recalculate splitPointPercent based on the aligned splitTime
    const splitPointPercent = (splitTime / totalDuration) * 100;

    // Update the current block's width to reflect the split
    block.width = `${splitPointPercent - blockStartPercent}%`;

    // Create a new block for the right half
    const newBlock: AudioBlock = {
      text: block.text, // Use the same text initially
      shortText: this.getShorthandName(block.text), // Use the same shorthand initially
      left: `${splitPointPercent}%`,
      width: `${blockEndPercent - splitPointPercent}%`,
      color: this.getUniqueColor(index),
      index: index + 1,
    };

    // Insert the new block into the array at the correct position
    this.blocks.splice(index + 1, 0, newBlock);

    // Update animation states for all blocks
    const updatedAnimationStates: { [key: number]: boolean } = {};
    this.blocks.forEach((block, i) => {
      if (i <= index + 1) {
        // Keep existing states for blocks before the split
        updatedAnimationStates[i] = this.animationStates[i];
      } else {
        // Set state to true for new and shifted blocks
        updatedAnimationStates[i] = true;
      }
    });
    this.animationStates = updatedAnimationStates;

    // Update the struct accordingly
    // Save original end bar before we modify it
    const originalEndBar = this.struct[index][2];

    // Update the end bar of the original block to be the bar right before the split point
    this.struct[index][2] = splitBar;

    // For the new block, set start bar to splitBar
    const newBlockStartBar = splitBar + 1;

    // Now, determine the end bar for the new block
    let newBlockEndBar;

    if (index + 1 < this.struct.length) {
      // If there are more blocks after the new block
      // The end bar is one less than the start bar of the next block
      newBlockEndBar = this.struct[index + 1][1] - 1;
    } else {
      // If it's the last block, use the original end bar of the original block
      newBlockEndBar = originalEndBar;
    }

    // Insert the new block into the struct with the appropriate start and end bars
    this.struct.splice(index + 1, 0, [newBlock.text, newBlockStartBar, newBlockEndBar]);

    // Now, adjust the sequences
    const originalBlockName = block.text;

    // Update and adjust names for both the original and new blocks
    this.updateAndAdjustBlockNames(block.text, index, originalBlockName);
    this.updateAndAdjustBlockNames(newBlock.text, index + 1, originalBlockName);

    // Update the observable to reflect changes
    this.addWaveformLine(splitPointPercent);
  }

  private deleteBlock(index: number | null) {
    if (index === null) return;

    const blockToDelete = this.blocks[index];
    const blockType = this.getBlockType(blockToDelete.text);

    if (index === 0 && this.blocks.length > 1) {
      // If deleting the first block and there are more blocks, adjust the next block
      const nextBlock = this.blocks[index + 1];

      // Update the start bar of the next block
      this.struct[index + 1][1] = this.struct[index][1];

      // Remove the current block
      this.blocks.splice(index, 1);
      this.struct.splice(index, 1);
    } else if (index > 0) {
      const previousBlock = this.blocks[index - 1];

      // Merge the widths
      const newWidth =
        parseFloat(previousBlock.width) +
        parseFloat(blockToDelete.width) +
        (parseFloat(blockToDelete.left) - (parseFloat(previousBlock.left) + parseFloat(previousBlock.width)));
      previousBlock.width = `${newWidth}%`;

      // Update the end bar of the previous block
      this.struct[index - 1][2] = this.struct[index][2];

      // Remove the current block
      this.blocks.splice(index, 1);
      this.struct.splice(index, 1);
    }

    // Adjust sequences for the block type
    this.adjustBlockSequence(blockType);
  }

  private fixOverlapsAndGapsInStruct() {
    if (this.struct.length === 0) return;

    // Calculate the total number of bars before adjustments
    const totalBars = this.struct[this.struct.length - 1][2];

    // Initialize the first block
    this.struct[0][1] = Math.max(1, this.struct[0][1]);
    // Ensure the end bar doesn't exceed total bars
    this.struct[0][2] = Math.min(this.struct[0][2], totalBars);

    for (let i = 1; i < this.struct.length; i++) {
      const previousBlock = this.struct[i - 1];
      const currentBlock = this.struct[i];
      const previousEndBar = previousBlock[2];

      // Adjust current block's start bar to prevent overlap
      if (currentBlock[1] <= previousEndBar) {
        currentBlock[1] = previousEndBar + 1;
      }

      // Calculate the maximum possible end bar without exceeding total bars
      const maxEndBar = totalBars - (this.struct.length - i - 1);

      // Adjust current block's end bar based on available bars
      currentBlock[2] = Math.min(currentBlock[1] + (currentBlock[2] - currentBlock[1]), maxEndBar);

      // Ensure end bar is not less than start bar
      if (currentBlock[2] < currentBlock[1]) {
        currentBlock[2] = currentBlock[1];
      }
    }

    // Adjust the last block's end bar to match total bars
    this.struct[this.struct.length - 1][2] = totalBars;
    this.updateBlocksAfterFixingStruct();
  }

  private updateBlocksAfterFixingStruct() {
    this.createBlocks(); // Recreate the blocks based on the updated struct
    this.updateBlocks(); // Update the observable and re-render the blocks
  }

  private updateAllLines() {
    this.clearLines(); // Clear all existing lines
    setTimeout(() => {
      this.blocks.forEach((block, index) => {
        const left = parseFloat(block.left);
        if (index > 0) {
          this.addWaveformLine(left); // Re-add the line for this block
        }
      });
      this.detectChanges();
    });
  }

  private getShorthandName(fullText: string): string {
    // Ensure there's no space before the numbers in the partNames keys
    const exactMatch = this.partNames![fullText];
    if (exactMatch) {
      return exactMatch; // If there's an exact match in partNames, return it
    }

    // In case there are variations in spacing, try to replace extra spaces and match
    const cleanedFullText = fullText.replace(/\s-\s/g, '-').replace(/\s+/g, ''); // Remove spaces around "-"
    return this.partNames![cleanedFullText] || fullText; // Fallback to the full text if no match found
  }

  private getFullNameFromShorthand(shorthandText: string): string {
    // Use Object.entries to find the full name for the given shorthand
    for (const [fullName, shortName] of Object.entries(this.partNames!)) {
      if (shortName === shorthandText) {
        return fullName; // Return the full name if the shorthand matches
      }
    }
    return shorthandText; // Fallback to the shorthand if no match is found
  }

  private getUniqueColor(index: number): string {
    const previousColor = index > 0 ? this.blocks[index - 1].color : null;
    const nextColor = index < this.blocks.length - 1 ? this.blocks[index + 1].color : null;
    const currentColor = this.blocks[index].color;

    for (const color of this.colors) {
      if (color !== previousColor && color !== nextColor && color !== currentColor) {
        return color;
      }
    }

    return (
      this.colors.find((color) => color !== currentColor) || currentColor
    );
  }

  private updateBlocks() {
    this.reindexBlocks(this.blocks);

    // Update the observable to reflect changes
    this.blocks$.next(this.blocks);

    // Re-add all lines to ensure they are in the correct positions
    this.updateAllLines();
  }

  private calculateBarDuration() {
    const [beatsPerBar] = this.time_signature.split('/').map(Number);
    const beatDuration = 60 / this.bpm;
    this.barDuration = beatsPerBar * beatDuration;
    this.detectChanges();
  }

  private updateAndAdjustBlockNames(newName: string, blockIndex: number, originalBlockName: string) {
    const originalBlockType = this.getBlockType(originalBlockName);
    const newBlockType = this.getBlockType(newName);

    // Rename the block in the struct and blocks array
    this.blocks[blockIndex].text = newName;
    this.blocks[blockIndex].shortText = this.getShorthandName(newName);
    this.struct[blockIndex][0] = this.getFullNameFromShorthand(this.blocks[blockIndex].shortText);

    // Adjust sequences for both original and new block types
    this.adjustBlockSequence(originalBlockType);
    if (originalBlockType !== newBlockType) {
      this.adjustBlockSequence(newBlockType);
    }

    // Update the blocks array to reflect the changes
    this.updateBlocks();
  }

  // Helper function to get the block type from its name (e.g., "Verse 1" -> "Verse")
  private getBlockType(blockName: string): string {
    // Use a regex to match the part of the block name before the sequence number
    return blockName.replace(/\s\d+$/, ''); // Removes the number and returns the full block type
  }

  // Adjust the sequence of the blocks for a given type (e.g., "Verse")
  private adjustBlockSequence(blockType: string) {
    const blockTypesWithoutNumbering = ['Intro', 'Outro', 'Instrumental', 'C - Part']; // Add any block types that shouldn't have numbering

    let counter = 1; // Start numbering from 1

    for (let i = 0; i < this.struct.length; i++) {
      if (this.getBlockType(this.struct[i][0]) === blockType) {
        // Construct the full name using the block type and counter
        const fullName = blockTypesWithoutNumbering.includes(blockType) ? blockType : `${blockType} ${counter}`;

        // Update the struct and blocks with the full name and corresponding shorthand name
        this.struct[i][0] = fullName;
        this.blocks[i].text = fullName;
        this.blocks[i].shortText = this.getShorthandName(fullName);

        counter++;
      }
    }
  }

  private moveToNextPlayerState(playerState: any, struct: any[]) {
    this.waveSurfer.seekTo(0);
    this.destroyWaveSurferInstance();
    this.updateProgress();

    this.playerState = playerState;
// Reset animation states
    this.animationStates = {};
    // Clear existing blocks and lines
    this.blocks = [];
    this.clearLines();
    this.initDataFromGetStruct(struct);
  }

  private stopSongAndMetronome() {
    this.isPlaying = false;
    this.metronome?.stop();
    this.waveSurfer.stop();
  }

  private destroyWaveSurferInstance() {
    this.waveSurfer.unAll(); // Clear all event listeners
    this.waveSurfer.empty();
    this.waveSurfer.destroy();
  }

  private isBpmValueInvalid(value: any) {
    return value === null ||
      value === undefined ||
      value === 0 ||
      value === '';
  }

  private getBpmDebounceTime() {
    const time = this.bpmIconsClicked ? 0 : 1000;
    this.bpmIconsClicked = false;
    return time;
  }

  private initMetronome() {
    if (!this.metronome) {
      this.metronome = new Metronome(this.bpm, this.calculateBeatsPerBar());
    } else {
      // Update BPM and beats per bar in case they have changed
      this.metronome.setBpmAndBeatsPerBar(this.bpm, this.calculateBeatsPerBar());
    }

    // Set the oneBeatOffset and downbeatOffset based on the player state and selected option
    if (this.playerState === 'set-one' && this.setOneOptions && this.setOneOptions.length > 0) {
      const selectedOption = this.setOneOptions.find((option) => option.selected)!;
      this.metronome.setOneBeatOffset(selectedOption.offset);
      this.metronome.setDownbeatOffset(selectedOption.offset); // Set downbeatOffset
    } else {
      // Disable the "one" sound outside the 'set-one' phase
      this.metronome.setOneBeatOffset(null);
      this.metronome.setDownbeatOffset(0); // Reset downbeatOffset to zero
    }
  }
}
