import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { fadeTransition } from '../../../animations/fade-in-animation';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment.prod';
import { UserStateService } from '../../../services/user-state.service';

@Component({
  selector: 'sbz-email-verification-modal',
  templateUrl: './email-verification-dialog.component.html',
  styleUrls: ['./email-verification-dialog.component.scss'],
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVerificationModalComponent {
  isEmailVerificationModalVisible$ = this.modalService.isEmailVerificationModalVisible$;
  verificationCodeIsInvalid$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(false);
  verificationCodeSent = false;

  verificationForm = new FormGroup({
    verificationCode: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private modalService: ModalService,
              private httpClient: HttpClient,
              private userStateService: UserStateService) {
  }

  sendVerificationCode() {
    const email = this.verificationForm.get('email')?.value;
    if (email) {
      this.isLoading$.next(true);
      const sessionToken = this.userStateService.sessionToken$.getValue();

      const url = `${environment.baseUrl}/send-verification-code?email=${email}`;
      const options = {
        headers: { 'Authorization': `Bearer ${sessionToken}` }
      };

      this.httpClient.post(url, {}, options).subscribe({
        next: () => {
          this.isLoading$.next(false);
          this.verificationCodeSent = true;
        },
        error: () => {
          this.isLoading$.next(false);
        }
      });
    }
  }

  submitVerificationCode() {
    const code = this.verificationForm.get('verificationCode')?.value;
    const email = this.verificationForm.get('email')?.value;
    if (code && email) {
      this.isLoading$.next(true);
      const sessionToken = this.userStateService.sessionToken$.getValue();

      const url = `${environment.baseUrl}/verify-email?code=${code}&email=${email}`;
      const options = {
        headers: { 'Authorization': `Bearer ${sessionToken}` }
      };

      this.httpClient.post(url, {}, options).subscribe({
        next: () => {
          this.isLoading$.next(false);
          this.modalService.closeEmailVerificationModal();
        },
        error: () => {
          this.verificationCodeIsInvalid$.next(true);
          this.isLoading$.next(false);
        }
      });
    }
  }

  closeEmailVerificationModal() {
    this.modalService.closeEmailVerificationModal();
  }

  closeModal() {
    this.verificationForm.reset();
    this.isLoading$.next(false);
    this.verificationCodeIsInvalid$.next(false);
    this.verificationCodeSent = false;
    this.modalService.closeEmailVerificationModal();
  }
}