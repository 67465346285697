import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[sbzFadeOutSection]'
})
export class FadeOutSectionDirective implements OnInit {
  private visibilityThreshold!: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.setVisibilityThreshold();
  }

  ngOnInit() {
    this.checkVisibility();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkVisibility();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.setVisibilityThreshold();
    this.checkVisibility();
  }

  private setVisibilityThreshold() {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth;
    this.visibilityThreshold = screenWidth < 768 ? -100 : 166; // Adjust the value -100 as needed for smaller screens
  }

  private checkVisibility() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    if (rect.top < this.visibilityThreshold) {
      this.renderer.addClass(this.el.nativeElement, 'is-hidden');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'is-hidden');
    }
  }
}
