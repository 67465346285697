import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ModalService} from '../../../services/modal.service';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'sbz-new-chat-modal',
  templateUrl: './new-chat-modal.component.html',
  styleUrl: './new-chat-modal.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewChatModalComponent {
  constructor(public modalService: ModalService,
              private userStateService: UserStateService) {
  }

  confirm() {
    this.userStateService.startNewChatFromModal$.next(true);
    this.closeModal();
  }

  closeModal(): void {
    this.modalService.closeNewChatModal();
  }
}
