<div class="management-queueu-container mt-[119px] pl-14 pr-14">
  <h1 class="text-center text-xl font-bold mb-10">Management Queue</h1>
  <div class="info">
    <div class="mb-4">
      Real users in queue: <span class="text-2xl font-bold">{{ queueInfo.queue }}</span>
      Fake users in queue: <span class="text-2xl font-bold">{{ queueInfo.fakeQueue }}</span>
    </div>
    <div class="mb-4">
      Total Queue Size (fake + real): <span class="text-2xl font-bold">{{ queueInfo.totalQueue }}</span>
      @if (editSettings) {
        <input
            class="block rounded-lg focus:outline-none focus:border-none border-none"
            type="number"
            [min]="queueInfo.queue"
            (change)="_calculateTotalQueue()"
            [(ngModel)]="queueInfo.totalQueue"
            [value]="queueInfo.totalQueue"
        >
      }
    </div>
    <div class="mb-4">
      Buffer increment size amount (add fake user after registration): <span
        class="text-2xl font-bold">{{ bufferQueue }}</span>
      @if (editSettings) {
        <input
            class="block rounded-lg focus:outline-none focus:border-none border-none"
            type="number"
            min="0"
            (change)="_calculateTotalQueue()"
            [(ngModel)]="queueInfo.bufferQueue"
            [value]="queueInfo.bufferQueue"
        >
      }
    </div>
    <div>
      Buffer increment size random factor (%): <span class="text-2xl font-bold">{{ queueInfo.randomFactor }}</span>
      @if (editSettings) {
        <input
            class="block rounded-lg focus:outline-none focus:border-none border-none"
            type="number"
            min="0"
            (change)="_calculateTotalQueue()"
            [(ngModel)]="queueInfo.randomFactor"
            [value]="queueInfo.randomFactor"
        >
      }
    </div>
    @if (!editSettings) {
      <button class="main-button-design black-button my-[2vh] mx-auto" (click)="editSettings = true">
        Edit
      </button>
    } @else {
      @if (queueInfo.totalQueue <= queueInfo.fakeQueue) {
        <div class="text-red-700">Total Queue Size (fake + real) should be more than Faked users in queue
          ({{ queueInfo.fakeQueue }})
        </div>
      }
      <div class="flex items-center mb-4 mt-4">
        <input [(ngModel)]="queueInfo.showTotalQueue" id="show-queue-checkbox" type="checkbox" value=""
               class="w-4 h-4 text-blue-600 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
        <label for="show-queue-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Show Total
          Queue</label>
      </div>
      <div>Last user in whitelist will see: <span class="font-bold">You're current position is <span
          class="text-xl">{{ queueInfo.fakeQueue }}</span> out of <span
          class="text-xl">{{ queueInfo.totalQueue }}</span> musicians.</span></div>
      <button [disabled]="queueInfo.totalQueue <= queueInfo.fakeQueue"
              class="main-button-design black-button my-[2vh] mx-auto disabled:cursor-not-allowed" (click)="saveInfo()">
        Save
      </button>
      <button class="inline-block main-button-design black-button my-[2vh] mx-auto disabled:cursor-not-allowed"
              (click)="editSettings = false">
        Discard
      </button>
    }
  </div>
  <div class="users-table">
    <div class="table-actions flex justify-between">
      <label class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white" for="default-search">Search</label>
      <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg aria-hidden="true" class="w-4 h-4 text-gray-500 dark:text-gray-400" fill="none"
               viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" stroke="currentColor" stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"/>
          </svg>
        </div>
        <input [(ngModel)]="query"
               class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               id="default-search"
               placeholder="Search users ..."
               required type="search"/>
        <button (click)="searchUser()"
                class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                name="query"
                type="submit">
          Search
        </button>
      </div>
      <div class="grand-access flex justify-end mb-3 items-center">
        <span class="mr-2">Grant access:</span>
        <span class="flex items-center">From: <input
            [(ngModel)]="grandAccess.from" [max]="grandAccess.to - 1"
            class="w-24 lock rounded-lg focus:outline-none focus:border-none border-none"
            min="1" type="number"></span>
        <span class="flex items-center ml-2">To: <input
            [(ngModel)]="grandAccess.to" [max]="queueInfo.fakeQueue"
            [min]="grandAccess.from" class="w-24 block rounded-lg focus:outline-none focus:border-none border-none"
            type="number"></span>
        <button
            (click)="grandAccesses()"
            [disabled]="grandAccess.from <= 0 || grandAccess.from > grandAccess.to || !users.length || grandAccess.to > queueInfo.fakeQueue"
            class="disabled:opacity-50 disabled:cursor-not-allowed ml-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Provide accesses
        </button>
      </div>
    </div>
    <div class="flex justify-end items-center mb-4 mt-4">
      <input [(ngModel)]="sendEmail"
             class="w-4 h-4 text-blue-600 bg-white rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
             id="send-email-checkbox" type="checkbox"
             value="">
      <label class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="send-email-checkbox">
        <Section>Send email via update user</Section>
      </label>
    </div>
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white border border-gray-200">
        @if (users.length) {
          <thead>
          <tr>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              In Queue
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Email
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Action
            </th>
          </tr>
          </thead>
        }
        <tbody>
          @for (user of users; track user.userId) {
            <tr class="bg-white">
              <td class="px-6 py-4 border-b border-gray-200 text-sm text-gray-900">
                @if (editUserId === user.userId) {
                  <input
                      class="block rounded-lg focus:outline-none"
                      type="number"
                      [(ngModel)]="user.customAttributes.queuePosition"
                      [value]="user.customAttributes.queuePosition"
                  >
                } @else {
                  <span>#{{ user.customAttributes.queuePosition }}</span>
                }
              </td>
              <td class="px-6 py-4 border-b border-gray-200 text-sm text-gray-900">
                {{ user.name }}
              </td>
              <td class="px-6 py-4 border-b border-gray-200 text-sm text-gray-900">
                {{ user.email }}
              </td>
              <td class="px-6 py-4 border-b border-gray-200 text-sm text-gray-900">
                @if (editUserId === user.userId) {
                  <button (click)="updateUserWhiteList(user, false)"
                          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-3">
                    save
                  </button>
                } @else {
                  <button (click)="updateUserWhiteList(user, true)"
                          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                    Provide access
                  </button>
                  <button class="bg-white text-gray-400 font-bold py-2 px-4 rounded ml-3"
                          (click)="editUserId = user.userId">
                    edit
                  </button>
                }
              </td>
            </tr>
          } @empty {
            <div class="mt-10 mb-10 text-center text-4xl w-full">
              Whitelist empty
            </div>
          }
        </tbody>
      </table>
      @if (pagination.limit < pagination.totalItems) {
        <sbz-pagination
            [totalItems]="pagination.totalItems"
            [itemsPerPage]="pagination.limit"
            [currentPage]="pagination.currentPage"
            (onClick)="changePage($event)"
        ></sbz-pagination>
      }
    </div>
  </div>
  <sbz-confirm-dialog></sbz-confirm-dialog>
