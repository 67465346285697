import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sbz-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnInit {
  @Input() totalItems!: number;
  @Input() currentPage!: number;
  @Input() itemsPerPage!: number;
  @Output() onClick: EventEmitter<number> = new EventEmitter();
  totalPages = 0;
  goto = 0;
  public pages: number[] = [];

  constructor() {

  }

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.pages = Array.from({length: this.totalPages}, (_, i) => i + 1);
  }

  pageClick(page: number) {
    if (page > this.pages.length) {
      page = 1;
    } else if (page <= 0) {
      page = this.pages.length;
    }
    this.onClick.emit(page);
  }

  getPagesToShow(): number[] {
    const total = this.totalPages;
    const current = this.currentPage;
    const delta = 2;
    const range = [];

    for (let i = Math.max(2, current - delta); i <= Math.min(total - 1, current + delta); i++) {
      range.push(i);
    }

    return range;
  }
}
