import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../animations/fade-in-animation';

@Component({
  selector: 'sbz-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
}
