import {animate, style, transition, trigger} from '@angular/animations';

export const fadeTransition = trigger('fadeTransition', [
  transition(':enter', [
    style({opacity: 0}),
    animate('300ms 301ms', style({opacity: 1}))
  ]),
  transition(':leave', [
    animate('300ms', style({opacity: 0}))
  ])
]);
