import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserStateService} from '../../services/user-state.service';
import {fadeTransition} from '../../animations/fade-in-animation';
import {highlightActive} from '../../animations/highlight-active-animation';
import {User} from '../../interfaces/user';
import {LoadingService} from '../../services/loading.service';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'sbz-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  animations: [fadeTransition, highlightActive],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() activeStep: number = 1;
  @Input() submitForm = {valid: false};
  @Output() forceStepChange = new EventEmitter<number>();
  @ViewChild('phoneField') phoneField?: ElementRef;
  form!: FormGroup;
  userImage: string | undefined;
  validRoles = [
    'I write lyrics',
    'I compose music',
    'I produce songs',
    'I\'m a music enthusiast',
    'I\'m a label rep',
    'I\'m an artist manager',
    'I\'m a performer',
    'I play an instrument',
    'Other'
  ];

  isLoading$ = this.loadingService.isLoading$;
  intlTelInputRef: any;
  private userSignUpForAffiliation = false;

  constructor(private fb: FormBuilder,
              private userStateService: UserStateService,
              private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    const user = this.userStateService.user$.getValue();
    console.log('user: ', user);
    this.form = this.fb.group({
      fullName: [user?.displayName, Validators.required],
      phone: [user?.phone, [Validators.required, this.phoneNumberValidator()]],
      // email: [user?.email, [Validators.required, Validators.email]],
      email: [{value: user?.email, disabled: true}, [Validators.required, Validators.email]],
      userRoles: [[], [Validators.required, this.roleValidator()]],
      youtubeChannel: [''],
      instagramUrl: [''],
      spotifyArtistPage: [''],
      appleMusicProfile: [''],
      description: ['']
    });
    this.form.get('email')?.disable();
    this.userImage = user?.pictureUrl;

    if (!user?.origin) {
      this.userStateService.updateUserMetadata();
    }

    if (user?.email) {
      this.userStateService.sendRegInfoToDb();
      this.userSignUpForAffiliation = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.phoneField) {
      this.intlTelInputRef = intlTelInput(this.phoneField.nativeElement, {
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/24.3.4/js/utils.min.js',
        autoPlaceholder: 'aggressive',
        separateDialCode: true,
        nationalMode: true,
        initialCountry: 'auto',
        geoIpLookup: function (callback: any) {
          fetch('https://ipapi.co/json')
            .then(function (res) {
              return res.json();
            })
            .then(function (data) {
              callback(data.country_code);
            })
            .catch(function () {
              callback('us');
            });
        }
      });
    }

    this.intlTelInputRef.promise.then(() => {
      setTimeout(() => {
        if (this.form.get('phone')?.value) {
          let prefilledPhone = this.form.get('phone')?.value;
          if (!prefilledPhone.startsWith('+')) {
            const countryCode = this.intlTelInputRef.getSelectedCountryData().dialCode;
            prefilledPhone = `+${countryCode}${prefilledPhone}`;
          }
          this.intlTelInputRef.setNumber(prefilledPhone);
          const valid = this.intlTelInputRef.isValidNumber();

          // Optionally set form control errors based on the validity
          this.form.get('phone')?.setErrors(valid ? null : {invalidPhoneNumber: true});
        }
      }, 100);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeStep']) {
      switch (this.activeStep) {
        case 2:
          if (!this.areFormStepValid(['fullName', 'phone', 'email', 'userRoles'])) {
            this.forceStepChange.emit(1);
          }
          break;
        case 3:
          if (!this.areFormStepValid(['youtubeChannel', 'instagramUrl', 'spotifyArtistPage', 'appleMusicProfile'], true)) {
            this.forceStepChange.emit(2);
          }
          break;
      }
    }
    if (changes['submitForm'] && this.submitForm.valid) {
      this.onSubmit();
    }
  }

  onSelectionChange(selectedValues: string[]) {
    this.form.get('userRoles')?.patchValue(selectedValues);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.get('phone')?.patchValue(this.intlTelInputRef.getNumber());
    }
    const user: User = this.form.value;
    user.token = this.userStateService.user$.getValue()!.token;
    user.pictureUrl = this.userImage || '';
    user.finishedRegistration = true;
    user.isWhiteList = true;
    if (this.form.valid) {
      this.userStateService.updateUserInfo(user);

      if (!this.userSignUpForAffiliation) {
        this.userStateService.sendRegInfoToDb();
        this.userSignUpForAffiliation = true;
      }
    }
  }

  getErrorForField(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return !!(control && control.errors && control.touched);
  }

  base64ImageCreated(userImage: string) {
    this.userImage = userImage;
  }

  private phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // This regex allows digits, plus signs, and minus signs. Adjust as needed.
      let valid;
      if (this.intlTelInputRef) {
        valid = this.intlTelInputRef.isValidNumber();
      }
      return valid ? null : {'invalidPhoneNumber': {value: control.value}};
    };
  }

  private roleValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value.length ? null : {'invalidRole': {value: control.value}};
    };
  }

  private urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // This regex is a basic pattern for matching URLs. It's simplified for demonstration purposes.
      // You might need a more comprehensive pattern depending on your validation needs.
      const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      const valid = pattern.test(control.value);
      return valid ? null : {'invalidUrl': {value: control.value}};
    };
  }

  private areFormStepValid(fields: string[], checkValue = false): boolean {
    let allFieldsValid = true;

    fields.forEach(field => {
      const control = this.form.get(field);
      const shouldCheckValidity = control && (!checkValue || (checkValue && control.value));
      if (shouldCheckValidity && control.invalid) {
        allFieldsValid = false;
        // Mark the control as touched to trigger the display of error messages
        control.markAsTouched();
      }
    });

    return allFieldsValid;
  }
}
