export enum RobotAnimation {
  None = 'animation-none'
}

export enum RobotPosition {
  Center = 'robot-center',
  Left = 'robot-left',
  Contact = 'robot-contact',
  Sent = 'robot-sent',
}

export enum RobotImageSrc {
  Sent = 'assets/images/contact-us/sent.webp'
}
