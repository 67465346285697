import {Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject} from 'rxjs';
import {ModalService} from '../../../../services/modal.service';
import {ChatService} from '../../../../services/chat.service';
import {AudioService} from '../../../../services/audio.service';
import {UserStateService} from '../../../../services/user-state.service';
import {ScrollService} from '../../../../services/scroll.service';

@Component({
  selector: 'sbz-chat-welcome',
  templateUrl: './chat-welcome.component.html',
  styleUrl: './chat-welcome.component.scss',
})
export class ChatWelcomeComponent extends BaseChatComponent {
  currentlyPlayingSong = '';
  sketchIsPlaying$ = new BehaviorSubject(false);
  finalIsPlaying$ = new BehaviorSubject(false);

  constructor(modalService: ModalService,
              chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService,
              scrollService: ScrollService) {
    super(chatService, audioService, userStateService, modalService, scrollService);
  }

  toggleSong(song: string) {
    switch (song) {
      case 'sketch':
        if (this.currentlyPlayingSong !== song) {
          this.audioService.playSong(song);
          this.currentlyPlayingSong = song;
          this.sketchIsPlaying$.next(true);
          this.finalIsPlaying$.next(false);
        } else {
          this.audioService.togglePlayPause();
          this.sketchIsPlaying$.next(!this.sketchIsPlaying$.getValue());
        }
        break;
      case 'final':
        if (this.currentlyPlayingSong !== song) {
          this.audioService.playSong(song);
          this.currentlyPlayingSong = song;
          this.sketchIsPlaying$.next(false);
          this.finalIsPlaying$.next(true);
        } else {
          this.audioService.togglePlayPause();
          this.finalIsPlaying$.next(!this.finalIsPlaying$.getValue());
        }
        break;
      case 'prompt':
        this.modalService.openPromptModal('');
        // this.iframeLink = 'https://www.youtube.com/watch?v=SBjQ9tuuTJQ';
        // this.sketchIsPlaying$.next(false);
        // this.finalIsPlaying$.next(false);
        this.audioService.stopSong();
        break;
    }
  }
}
