<p class="chat-text">Jump right in or, explore more about HitCraft.</p>

<div class="chat-button-container">
  <button (click)="createChatBubbleWIthText('pre-upload-notice', 'I`m ready to start the session.')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="gradient-purple-button">
    start the session
  </button>

  <button (click)="goToLink('')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="chat-button-generic">
    about hitcraft
  </button>

  <button (click)="goToLink('about')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="chat-button-generic">
    about us
  </button>

  <button (click)="goToLink('contact')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="chat-button-generic">
    contact us
  </button>
</div>
