import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {RobotStateService} from '../../../services/robot-state.service';
import {RobotImageSrc, RobotPosition} from '../../../interfaces/robot-animation';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserStateService} from '../../../services/user-state.service';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {BehaviorSubject} from 'rxjs';
import {ModalService} from '../../../services/modal.service';
import {environment} from '../../../../environments/environment.prod';

@Component({
  selector: 'sbz-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  messageSent$ = new BehaviorSubject<boolean>(false);

  constructor(private robotStateService: RobotStateService,
              private fb: FormBuilder,
              private userStateService: UserStateService,
              private modalService: ModalService) {
  }

  ngOnInit() {
    this.robotStateService.changeRobotPosition(RobotPosition.Contact);

    const user = this.userStateService.user$.getValue();

    this.form = this.fb.group({
      // Other form controls...
      name: [user?.displayName, [Validators.required]], // Example validator for a 16-digit card number
      email: [user?.email, [Validators.required, Validators.email]],
      message: ['', [Validators.required]] // 3 or 4 digits for CVV
    });
  }

  ngOnDestroy() {
    this.robotStateService.resetRobotPosition();
    this.robotStateService.resetRobotImage();
    this.robotStateService.toggleRobot(true);
  }

  onSubmit() {
    if (!this.areFormStepValid()) {
      return;
    }
    this.sendData().then();
  }

  getErrorForField(fieldName: string): boolean {
    const control = this.form.get(fieldName);
    return !!(control && control.errors && control.touched);
  }

  private async sendData() {
    try {
      const name = this.form.get('name')!.value;
      const email = this.form.get('email')!.value;
      const message = this.form.get('message')!.value;
      const url = `${environment.baseUrl}/users/contact-us`;

      const res = await fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          name,
          email,
          message
        })
      });

      this.messageSent$.next(true);
      this.robotStateService.toggleRobot(false);
      this.robotStateService.changeRobotPosition(RobotPosition.Sent);
      this.robotStateService.changeRobotImage(RobotImageSrc.Sent);

      setTimeout(() => {
        this.robotStateService.toggleRobot(true);
      }, 500);
    } catch (e) {
      this.modalService.openErrorModal('There was an error sending your message. Please try again later.');
    }
  }

  private areFormStepValid(): boolean {
    const fields = ['name', 'email', 'message'];
    let allFieldsValid = true;

    fields.forEach(field => {
      const control = this.form.get(field);
      if (control && control.invalid) {
        allFieldsValid = false;
        // Mark the control as touched to trigger the display of error messages
        control.markAsTouched();
      }
    });

    return allFieldsValid;
  }
}
