export const environment = {
  production: false,
  descopeProjectId: 'P2c5c3JHvZ8w5oiRpMMZnRKRtP2e',
  descopeFlowId: 'sign-up-or-in-copy',
  baseUrl: 'https://f1mhn1g3nh.execute-api.us-east-1.amazonaws.com/dev',
  // baseUrl: 'http://localhost:3000',
  googleAnalyticsTrackingId: 'G-R55TYDLGNB',
  stripeKey: 'pk_live_51OiwdoDrORm5vMmL4NWXW54fTJRD14tATVU6vjld6oIFOZQvrLkKO8rLejMwBJbEQA8ONLfuRncv7uGG3F1uHAbq006bl2eZ3f',
  logUrl: 'https://elk.session42.xyz/',
  eventsUrl: 'https://elk.session42.xyz/'
};
