import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ModalService} from '../../../services/modal.service';

@Component({
  selector: 'sbz-under-maintenance-modal',
  templateUrl: './under-maintenance-modal.component.html',
  styleUrl: './under-maintenance-modal.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnderMaintenanceModalComponent {
  constructor(public modalService: ModalService) {
  }
}
