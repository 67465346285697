@if (isEmailVerificationModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div [@fadeTransition] class="modal-container verification-modal">
      <div class="x-container">
        <img (click)="closeEmailVerificationModal()" alt="close modal" fill ngSrc="assets/images/close.webp">
      </div>
      <h2 class="modal-header verification-header">Enter your <strong class="font-bold">Verification Code</strong></h2>
      <p class="modal-content w-[90%]">
        {{
          (isLoading$ | async)
            ? 'Sending verification code...'
            : 'Please check your email and enter the code you received to verify your email'
        }}
      </p>
      <form [formGroup]="verificationForm" class="relative xl:w-[504px] xl:max-w-none lg:w-[450px] w-[80%] max-w[450px]"
            [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}">
        <div class="relative w-full">
          <input class="input-style" formControlName="verificationCode" placeholder="Verification code" type="text">
          @if (verificationCodeIsInvalid$ | async) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">Invalid verification code</span>
            </div>
          }
        </div>
      </form>
      <button (click)="submitVerificationCode()"
              [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}"
              class="main-button-design black-button mx-auto
              xl:!w-[185px] lg:!w-[148px] xl:mt-[37px] lg:mt-[30px] !text-[18px] xl:!h-[61px] lg:!h-[55px]">
        Verify
      </button>
    </div>
  </div>
}