import {ChangeDetectorRef, Component, ElementRef, inject, ViewChild} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {SketchResult} from '../../../../interfaces/sketch-result';
import WaveSurfer from 'wavesurfer.js';
import {BehaviorSubject} from 'rxjs';
import {NotifyServerService} from '../../../../services/notify-server.service';
import {AudioService} from '../../../../services/audio.service';
import {ChatService} from '../../../../services/chat.service';
import {ModalService} from '../../../../services/modal.service';
import {ScrollService} from '../../../../services/scroll.service';
import {UserStateService} from '../../../../services/user-state.service';

@Component({
  selector: 'sbz-chat-final-results',
  templateUrl: './chat-final-results.component.html',
  styleUrl: './chat-final-results.component.scss'
})
export class ChatFinalResultsComponent extends BaseChatComponent {
  @ViewChild('waveformFinal') waveformEl!: ElementRef;
  @ViewChild('progressDivFinal') progressDiv!: ElementRef;
  @ViewChild('progressLineFinal') progressLine!: ElementRef;

  sketchResults: SketchResult[] = [];
  failedDynamicButcherResponses = 0;
  isLoadingFinished = false; // New flag to track loading state
  isPlaying = false;
  waveSurfer!: WaveSurfer;
  currentActiveUrl = '';
  playerLoading$ = new BehaviorSubject(true);
  private noSongLoadedYet = true;
  private popupShown = false;
  private keyboardListener: ((event: KeyboardEvent) => void) | null = null;
  private cdr = inject(ChangeDetectorRef);

  constructor(chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService,
              modalService: ModalService,
              scrollService: ScrollService,
              public notifyServerService: NotifyServerService) {
    super(chatService, audioService, userStateService, modalService, scrollService);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.initWaveSurfer();
    this.notifyServerService.notifySongProduced(this.audioService.promptSketchId);
    this.audioService.dynamicButcherResponses$.subscribe({
      next: sketchResults => {
        if (sketchResults) {
          if (this.noSongLoadedYet) {
            this.loadUrlToPlayer(sketchResults[0]);
            this.noSongLoadedYet = false;
          }
          this.addNewResults(sketchResults);
          this.scrollService.scrollToBottomClicked$.next(true);
        }
      }
    });

    this.audioService.dynamicButcherResponsesFinished$.subscribe({
      next: finished => {
        if (finished) {
          this.failedDynamicButcherResponses = this.audioService.failedDynamicButcherResponses$.getValue();
          this.isLoadingFinished = true; // Set the flag to true when loading is finished
          this.cdr.detectChanges();
        }
      }
    });
  }

  attachKeyboardListener() {
    if (!this.keyboardListener) {
      this.keyboardListener = (event: KeyboardEvent) => {
        if (event.code === 'Space') {
          event.preventDefault();
          event.stopPropagation();
          this.playPause();
        }
      };
    }
  }

  // Method to remove the listener
  detachKeyboardListener() {
    if (this.keyboardListener) {
      window.removeEventListener('keydown', this.keyboardListener);
      this.keyboardListener = null;
    }
  }

  selectDifferentGenre() {
    this.detachKeyboardListener();
    this.audioService.cancelHttpRequest();
    this.audioService.resetForDifferentGenre();
    this.destroyWaveSurferInstance();
    this.createChatBubbleWIthText('prompt-input', 'You have chosen to try a different genre.');
  }

  playPause() {
    this.waveSurfer.playPause().then();
  }

  downloadSong(option: SketchResult, $event: MouseEvent) {
    $event.stopPropagation();
    option.downloadSpinnerIsOn = true;
    this.cdr.detectChanges();
    const downloadLink = option.url;

    setTimeout(() => {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (isIOS) {
        window.location.href = downloadLink;
        option.downloadSpinnerIsOn = false;
        this.cdr.detectChanges();
      } else {
        fetch(downloadLink, {mode: 'cors', credentials: 'omit'})
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
            }
            option.downloadSpinnerIsOn = false;
            this.cdr.detectChanges();
            return response.blob();
          })
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = this.audioService.songName + downloadLink!.slice(-4);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .then(() => this.notifyServerService.notifyDownloadSong(this.audioService.promptSketchId, option.id))
          .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
            option.downloadSpinnerIsOn = false;
            this.cdr.detectChanges();
          });
      }
    }, this.isDemoSite ? 500 : 0);
  }

  loadUrlToPlayer(sketchResult: SketchResult, playSong = false, secondProductionClicked = false) {
    console.log('Total User Uploads: ', this.userStateService.totalUserUploads$.getValue());
    if (!this.popupShown && this.userStateService.totalUserUploads$.getValue() >= 1 && secondProductionClicked) {
      console.log('openReferralModal from load url');
      this.modalService.openReferralModal(3);
      this.popupShown = true;
      return;
    }
    const {url, id} = sketchResult;
    if (url === this.currentActiveUrl) {
      return;
    }
    this.playerLoading$.next(true);
    const failSafeUrl = this.currentActiveUrl;
    this.currentActiveUrl = url;
    this.waveSurfer.stop();
    this.waveSurfer.load(url)
      .then(() => {
        this.playerLoading$.next(false);
        this.cdr.detectChanges();
        if (playSong) {
          // this.waveSurfer.seekTo(0);
          // this.updateProgress();
          this.waveSurfer.play().then(
            () => this.notifyServerService.notifyPlaySong(this.audioService.promptSketchId, id)
          );
        }
      })
      .catch((error) => {
        console.error('Error loading audio:', error);
        this.currentActiveUrl = failSafeUrl;
      });
  }

  getTitle(i: number) {
    const text = this.audioService.isChristmasGenre ? 'CHRISTMAS SONG' : 'option';
    return `Play ${text} ${i + 1}`;
  }

  private destroyWaveSurferInstance() {
    this.waveSurfer.unAll(); // Clear all event listeners
    this.waveSurfer.empty();
    this.waveSurfer.destroy();
  }

  private updateProgress() {
    if (this.progressLine?.nativeElement) {
      const progress = ((this.waveSurfer.getCurrentTime() / this.waveSurfer.getDuration()) * 100) || 0;

      this.progressDiv.nativeElement.style.width = `${progress}%`;
      if (progress > 0) {
        this.progressLine.nativeElement.style.left = `${progress}%`;
        this.progressLine.nativeElement.style.visibility = 'visible';
      } else {
        this.progressLine.nativeElement.style.visibility = 'hidden';
      }
    }
    this.cdr.detectChanges();
  }

  private initWaveSurfer() {
    this.attachKeyboardListener();
    // Initialize WaveSurfer with the WebAudio plugin
    this.waveSurfer = WaveSurfer.create({
      container: this.waveformEl.nativeElement,
      waveColor: '#393d44',
      progressColor: '#393d44',
      cursorColor: '#e53252',
      cursorWidth: 3,
      height: 60,
    });

    this.waveSurfer.on('play', () => {
      this.isPlaying = true;
      this.cdr.detectChanges();
    });

    this.waveSurfer.on('pause', () => {
      this.isPlaying = false;
      this.cdr.detectChanges();
    });

    this.waveSurfer.on('destroy', () => {

    });

    this.waveSurfer.on('finish', () => {
      this.isPlaying = false;
      this.cdr.detectChanges();
    });

    this.waveSurfer.on('audioprocess', () => {
      this.updateProgress();
    });

    this.waveSurfer.on('seeking', () => {
      this.updateProgress();
    });
  }

  private addNewResults(newResults: SketchResult[]) {
    const existingResultsMap = new Map(this.sketchResults.map(result => [result.id, result]));
    let isNewResultAdded = false;

    newResults.forEach(newResult => {
      if (!existingResultsMap.has(newResult.id)) {
        this.sketchResults.push(newResult);
        existingResultsMap.set(newResult.id, newResult);
        isNewResultAdded = true;
      }
    });

    if (isNewResultAdded) {
      this.moveMysteryToEnd();
      this.cdr.detectChanges();
    }
  }

  private moveMysteryToEnd() {
    const mysteryIndex = this.sketchResults.findIndex(result => result.isMystery);
    if (mysteryIndex > -1 && mysteryIndex !== this.sketchResults.length - 1) {
      const mysteryResult = this.sketchResults.splice(mysteryIndex, 1)[0];
      this.sketchResults.push(mysteryResult);
    }
  }
}
