@if (isLoadingResult$ | async) {
  <sbz-chat-loading-bar text="Getting your production..."
                        [loadingTimeout]="5"
                        [isLoading]="(isLoadingResult$ | async)!"></sbz-chat-loading-bar>
} @else {
  @if (noResultsBug) {
    <p class="chat-text">Something went wrong while producing song. Please try again.</p>
    <div class="chat-button-container">
      <button (click)="tryAgain()"
              class="gradient-purple-button ml-auto sm:!w-fit">produce again
      </button>
    </div>
  } @else {
    <p class="chat-text">Amazing job. Enjoy your song.</p>
    <div class="chat-white-container">
      <button (click)="toggleSong()"
              class="gradient-purple-button relative sm:!pl-[25px] sm:!w-[200px]">
        <svg fill="#fff" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round"
             stroke-width="2" viewBox="0 0 24 24">
          @if (songIsPlaying$ | async) {
            <rect x="6" y="4" width="4" height="16"/>
            <rect x="14" y="4" width="4" height="16"/>
          } @else {
            <polygon points="5 3 19 12 5 21 5 3"/>
          }
        </svg>
        play your song
      </button>

      <button (click)="downloadSong()"
              class="gradient-button sm:!w-fit sm:!px-2 relative">
        download it
        <!-- New spinner logic -->
        @if (isDownloadingSpinnerVisible$ | async) {
          <div class="spinner-overlay">
            <div class="spinner"></div>
          </div>
        }
      </button>
    </div>
  }
}
