import {Component} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {fadeTransition} from '../../../animations/fade-in-animation';

@Component({
  selector: 'sbz-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss',
  animations: [fadeTransition],
})
export class ErrorModalComponent {
  constructor(public modalService: ModalService) {
  }
}
