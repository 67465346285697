import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[sbzCustomAutoFocus]',
  standalone: true
})
export class CustomAutoFocusDirective {
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    this.el.nativeElement.focus(); // Automatically focus the element
  }
}
