import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AudioService} from '../../../../services/audio.service';

@Component({
  selector: 'sbz-chat-loading-bar',
  templateUrl: './chat-loading-bar.component.html',
  styleUrls: ['./chat-loading-bar.component.scss']
})
export class ChatLoadingBarComponent implements OnInit, OnDestroy {
  @Input() isLoading!: boolean;
  @Input() randomizeText = false;
  @Input() isChristmasGenre = false;
  @Input() text!: string;
  @Input() loadingTimeout: number = 3; // in minutes
  @Input() fileUploadProgress$ = new BehaviorSubject<number | null>(null);
  progress$ = new BehaviorSubject<number>(0);
  noPreviewsBug = false;
  private progress = 0;
  private intervalId: any;
  private textIntervalId: any;
  private stopRotating = false;
  private butcherTaskArnFromBug: string | undefined = undefined;

  private messages = [
    'Putting it all together...',
    'Sketching out the musical blueprint...',
    'Setting the stage for your production...',
    'Exploring sound possibilities...',
    'Selecting the perfect tones...',
    'Adding just the right effects...',
    'Fine-tuning the mix...',
    'Balancing everything out...',
    'Polishing the final touches...',
    'Wrapping it all up...',
    'Almost there, hang tight...'
  ];

  constructor(private cdr: ChangeDetectorRef, private audioService: AudioService) {
  }

  get loadingDuration(): number {
    return this.loadingTimeout > 0 ? this.loadingTimeout * 60 * 1000 : 3000;
  }

  ngOnInit() {
    if (this.isChristmasGenre) {
      this.messages = [
        'We\'re fine-tuning the jingles...',
        'Carol-fully crafting your track...',
        'What do you call an elf who sings? A wrapper.',
        'Frosty is working on your production. He asked you chill.',
      ];
    }
    if (this.loadingTimeout > 0) {
      this.startProgress();
    }

    if (this.randomizeText) {
      this.startTextRotation();
    }

    this.audioService.firstDynamicButcherResponseCame$
      .subscribe((firstDynamicButcherResponseCame) => {
        if (this.randomizeText && firstDynamicButcherResponseCame) {
          this.text = 'Amazing... now you can listen to your previews';
          this.stopRotating = true;
        }
      });

    this.audioService.dynamicButcherResponseBug$
      .subscribe({
        next: dynamicButcherResponseBug => {
          if (dynamicButcherResponseBug) {
            this.noPreviewsBug = true;
            this.butcherTaskArnFromBug = dynamicButcherResponseBug;
            this.cdr.detectChanges();
            this.audioService.dynamicButcherResponseBug$.next(undefined);
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.textIntervalId) {
      clearInterval(this.textIntervalId);
    }
  }

  finishLoading() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Stop the current progress update
    }

    const remainingProgress = 100 - this.progress;
    const updateSteps = 3;
    const interval = 900; // 2.7 seconds divided into 3 steps (2.7 / 3 = 0.9 seconds per step)

    let steps = 0;

    const finishInterval = setInterval(() => {
      if (steps < updateSteps) {
        this.progress += remainingProgress / updateSteps;
        this.progress = Math.ceil(this.progress); // Round the progress up to the nearest whole number

        // Ensure progress does not exceed 100
        if (this.progress > 100) {
          this.progress = 100;
        }

        this.progress$.next(this.progress);
        this.cdr.detectChanges();
        steps++;
      } else {
        clearInterval(finishInterval);
        setTimeout(() => {
          this.progress = 100; // Ensure the final value is 100
          this.progress$.next(this.progress);
          this.isLoading = false;
          this.cdr.detectChanges();
        }, 300); // Last 0.3 seconds to reach 100%
      }
    }, interval);
  }

  regeneratePreviews() {
    this.audioService.dynamicButcherResponseBug$.next(undefined);
    this.audioService.getDynamicButcher(this.butcherTaskArnFromBug!);
    this.progress$.next(0);
    this.startProgress();
    this.startTextRotation();
    this.noPreviewsBug = false;
    this.butcherTaskArnFromBug = undefined;
    this.cdr.detectChanges();
  }

  private startProgress() {
    const interval = this.loadingDuration / 90; // Stop at 90%
    this.intervalId = setInterval(() => {
      if (this.progress < 90) {
        this.progress++;
        this.progress$.next(this.progress);
        this.cdr.detectChanges(); // Ensure Angular detects the change in progress
      } else {
        this.isLoading = false;
        this.cdr.detectChanges();
        clearInterval(this.intervalId);
      }
    }, interval);
  }

  private startTextRotation() {
    let index = 0;
    this.text = this.messages[index];

    this.textIntervalId = setInterval(() => {
      if (this.stopRotating) {
        clearInterval(this.textIntervalId);
        return;
      }
      index++;

      // If we reach the end of the messages array, display the final message and stop rotation
      if (index >= this.messages.length - 1) {
        this.text = this.audioService.isChristmasGenre ? 'OK, we\'re all set! Ready to hear your holiday cheer?' : 'Almost there, hang tight...';
        clearInterval(this.textIntervalId); // Stop further rotations
      } else {
        this.text = this.messages[index];
      }

      this.cdr.detectChanges(); // Ensure Angular updates the view
    }, 20000); // Change text every 20 seconds
  }
}
