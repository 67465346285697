@if (modalService.isFinalResultModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container final-result-modal">
      <h2 class="modal-header text-center">Hang Tight – Your Track is on the Way!</h2>
      <p class="modal-content">
        Loading is taking a bit longer than usual, but no worries! You can close your browser, and we’ll email your
        track as soon as it’s ready. Thanks for your patience!
      </p>
      <button (click)="modalService.closeFinalResultModal()"
              class="main-button-design gradient-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px]">
        OK
      </button>
    </div>
  </div>
}
