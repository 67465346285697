<div class="sketch-results-container">
  @for (i of [0, 1]; track i) {
    @if (sketchResults[i]) {
      <div class="result" [ngClass]="{'is-mystery': getClass(sketchResults[i].isMystery)}">
        <span class="result-name">{{ getOption(i, sketchResults[i].isMystery) }}</span>
        <button (click)="toggleSong(sketchResults[i])"
                [disabled]="!currentActiveComponent || loadingSongId === sketchResults[i].id"
                class="black-button !mb-[10px]">

          <!-- Play/Pause Icon or Loading Spinner -->
          @if (loadingSongId !== sketchResults[i].id) {
            <svg fill="#fff" stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
              @if (currentlyPlayingSongName === sketchResults[i].id) {
                <rect x="6" y="4" width="4" height="16"/>
                <rect x="14" y="4" width="4" height="16"/>
              } @else {
                <polygon points="5 3 19 12 5 21 5 3"/>
              }
            </svg>
          }

          <!-- Loading Spinner -->
          @if (loadingSongId === sketchResults[i].id) {
            <div class="spinner"></div>
            <span>Loading track</span>
          }

          @if (loadingSongId !== sketchResults[i].id) {
            <span class="!px-[25px] 2xl:!px-0">listen to track</span>
          }
        </button>

        <!-- Produce Track Button -->
        <button class="black-button" (click)="produceSong(sketchResults[i])"
                [disabled]="!currentActiveComponent">
          <span class="mx-auto">produce track {{ i + 1 }}</span>
        </button>
      </div>
    } @else {
      @if (!isLoadingFinished) {
        <!-- Conditionally render the skeletons based on the loading state -->
        <div class="result skeleton">
          <div class="skeleton-text w-1/2">Loading...</div>
          <div class="skeleton-button w-full"></div>
          <div class="skeleton-button w-full"></div>
        </div>
      }
    }
  }
</div>
