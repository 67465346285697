@if (noPreviewsBug) {
  <p class="chat-text">Something went wrong while generating previews. click
    <span (click)="regeneratePreviews()" class="underline cursor-pointer">here</span>
    to regenerate previews
  </p>
} @else {
  <p class="chat-text mb-2">{{ text }}</p>

  <div class="loading-container">
    @if (fileUploadProgress$ | async) {
      <div
          [ngClass]="{'loading-done': !isLoading}"
          [ngStyle]="{'background-size': (fileUploadProgress$ | async) + '% 100%'}"
          class="loading-bar">
      </div>
      <p class="progress-text">{{ fileUploadProgress$ | async }}%</p>
    } @else {
      <div
          [ngClass]="{'loading-done': !isLoading}"
          [ngStyle]="{'background-size': (progress$ | async) + '% 100%'}"
          class="loading-bar">
      </div>
      @if (loadingTimeout > 0) {
        <p class="progress-text">{{ progress$ | async }}%</p>
      }
    }
  </div>
}

