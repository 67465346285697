import {animate, style, transition, trigger} from '@angular/animations';

export const slideMenu = trigger('slideMenu', [
  transition(':enter', [
    style({opacity: 0, transform: 'translateY(-100%)'}),
    // Using a custom cubic-bezier for elastic effect
    animate('0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275)', style({opacity: 1, transform: 'translateY(0)'})),
  ]),
  transition(':leave', [
    animate('0.3s cubic-bezier(0.175, 0.320, 0.885, 1.275)', style({opacity: 0, transform: 'translateY(-100%)'})),
  ]),
]);
