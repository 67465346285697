import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, ViewChild} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject, takeUntil} from 'rxjs';
import {ChatLoadingBarComponent} from '../chat-loading-bar/chat-loading-bar.component';

@Component({
  selector: 'sbz-chat-lyrics-input',
  templateUrl: './chat-lyrics-input.component.html',
  styleUrls: ['./chat-lyrics-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatLyricsInputComponent extends BaseChatComponent {
  lyrics: string = '';
  isUploading$ = new BehaviorSubject<boolean>(false);
  showLyricsChangedMessage: boolean = false;
  isValid: boolean = false;
  errorMessage: string = '';
  @ViewChild(ChatLoadingBarComponent) loadingBarComponent!: ChatLoadingBarComponent;
  private cdr = inject(ChangeDetectorRef);

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    // this.runTests(); // Remove or comment out this line in production
  }

  verifyLyrics(): void {
    this.isValid = this.validateLyrics(this.lyrics);
    if (this.isValid) {
      const processedLyrics = this.processLyrics(this.lyrics);
      this.showLyricsChangedMessage = processedLyrics !== this.lyrics;
      this.lyrics = processedLyrics;
      this.errorMessage = '';
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    }
  }

  submitLyrics(): void {
    this.finalizeLyricsSubmission();
  }

  finalizeLyricsSubmission(): void {
    if (this.lyrics !== '' && !this.sendClicked) {
      this.sendClicked = true;
      this.isUploading$.next(true);
      this.scrollService.scrollToBottomClicked$.next(true);

      if (this.isDemoSite) {
        setTimeout(() => {
          this.isUploading$.next(false);
          this.audioService.lyrics = this.lyrics;
          this.goToNext('after-lyrics');
        }, this.fakeCallsTimeout);
      } else {
        this.audioService
          .setSongLyrics(this.lyrics)
          .pipe(takeUntil(this.audioService.getCancelHttpRequest$())) // Attach cancellation stream
          .subscribe({
            next: (data: any) => {
              this.loadingBarComponent.finishLoading();
              setTimeout(() => {
                this.isUploading$.next(false);
                this.audioService.lyrics = this.lyrics;
                this.goToNext(this.isPreAlphaSite ? 'send-email' : 'after-lyrics');
              }, 3000);
            },
            error: (event: any) => {
              this.isUploading$.next(false);
              this.modalService.openErrorModal('We could not save the song name. Please try again.');
            },
          });
      }
    }
  }

  validateLyrics(lyrics: string): boolean {
    const lines = lyrics.split(/\r?\n/).filter((line) => line.trim() !== '');
    const nonSectionLines = lines.filter((line) => !this.isSectionHeader(line));

    if (lyrics.toLowerCase() === 'na') {
      return true;
    }

    if (nonSectionLines.length < 3) {
      this.errorMessage = 'A minimum of three lines is required.';
      return false;
    }

    if (lyrics.length > 7000) {
      this.errorMessage = 'Lyrics exceed the maximum allowed length of 7,000 characters.';
      return false;
    }

    for (const line of lines) {
      const trimmedLine = line.trim();
      if (/\S\s{3,}\S/.test(trimmedLine)) {
        this.errorMessage = 'Please avoid including chord transcriptions or musical notations.';
        return false;
      }
    }

    for (const line of lines) {
      const trimmedLine = line.trim();
      if (trimmedLine.endsWith('...')) {
        this.errorMessage = 'Please avoid using ellipses to indicate incomplete lines.';
        return false;
      }
    }

    const htmlTagRegex = /<\/?[^>]+(>|$)/g;
    if (htmlTagRegex.test(lyrics)) {
      this.errorMessage = 'Please avoid using HTML or script tags in the lyrics.';
      return false;
    }

    this.errorMessage = '';
    return true;
  }

  processLyrics(lyrics: string): string {
    // Remove leading/trailing whitespace from each line
    let processedLyrics = lyrics
      .split(/\r?\n/)
      .map((line) => line.trim())
      .join('\n');

    // Remove chord notations (lines with three or more consecutive spaces)
    processedLyrics = processedLyrics
      .split(/\r?\n/)
      .filter((line) => !line.match(/.*\s{3,}.*/))
      .join('\n');

    // Implement automatic completion of section headers
    processedLyrics = this.handleSectionHeaders(processedLyrics);

    // Remove HTML tags
    processedLyrics = processedLyrics.replace(/<\/?[^>]+(>|$)/g, '');

    // Preserve double newlines to maintain section breaks
    processedLyrics = processedLyrics.replace(/\n{3,}/g, '\n\n');

    // Remove leading/trailing whitespace again after processing
    processedLyrics = processedLyrics
      .split(/\r?\n/)
      .map((line) => line.trim())
      .join('\n');

    return processedLyrics;
  }

  handleSectionHeaders(lyrics: string): string {
    const lines = lyrics.split(/\r?\n/).map((line) => line.trim());
    const sectionLyricsMap = new Map<string, string[]>();
    let currentSectionName = '';
    let currentSectionLyrics: string[] = [];
    let processedLines: string[] = [];

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];

      if (this.isSectionHeader(line)) {
        const sectionName = line.trim().toLowerCase().replace(/[\[\]\(\)\{\}]/g, '');

        // If section already exists, replace header with stored lyrics
        if (sectionLyricsMap.has(sectionName)) {
          const existingLyrics = sectionLyricsMap.get(sectionName)!;
          processedLines.push(...existingLyrics);
          currentSectionName = '';
          currentSectionLyrics = [];
        } else {
          // Start a new section
          currentSectionName = sectionName;
          currentSectionLyrics = [];
        }
      } else if (line.match(/.*\s{3,}.*/)) {
        // Skip chord notation lines
        continue;
      } else {
        if (currentSectionName) {
          currentSectionLyrics.push(line);
        } else {
          processedLines.push(line);
        }
      }

      // If at the end of the section or last line, store the lyrics and add them to processedLines
      if (
        currentSectionName &&
        (i === lines.length - 1 || this.isSectionHeader(lines[i + 1]))
      ) {
        if (currentSectionLyrics.length > 0) {
          sectionLyricsMap.set(currentSectionName, [...currentSectionLyrics]);
          processedLines.push(...currentSectionLyrics); // Add lyrics to processedLines
          currentSectionName = '';
          currentSectionLyrics = [];
        }
      }
    }

    // Handle any remaining section lyrics
    if (currentSectionName && currentSectionLyrics.length > 0) {
      sectionLyricsMap.set(currentSectionName, [...currentSectionLyrics]);
      processedLines.push(...currentSectionLyrics); // Add lyrics to processedLines
    }

    return processedLines.join('\n');
  }

  isSectionHeader(line: string): boolean {
    const baseSectionHeaders = [
      'intro',
      'verse',
      'pre-chorus',
      'pre chorus',
      'chorus',
      'post-chorus',
      'post chorus',
      'bridge',
      'hook',
      'drop',
      'outro',
    ];

    // Allow for section headers with or without numbers, and ignore case
    const sectionHeaderRegex = new RegExp(`^(${baseSectionHeaders.join('|')})( \\d+)?$`, 'i');

    const trimmedLine = line.trim().toLowerCase().replace(/[\[\]\(\)\{\}]/g, '');

    return sectionHeaderRegex.test(trimmedLine);
  }

  private runTests(): void {
    interface TestCase {
      name: string;
      input: string;
      expectedProcessedLyrics: string;
      expectedValidationResult: boolean;
    }

    const testCases: TestCase[] = [
      {
        name: 'Test 1: Lyrics Without Section Headers',
        input: `We sing together under the sky
Our voices reach up, flying high

Walking paths unknown to us
We trust the wind without a fuss

We sing together under the sky
Our voices reach up, flying high`,
        expectedProcessedLyrics: `We sing together under the sky
Our voices reach up, flying high

Walking paths unknown to us
We trust the wind without a fuss

We sing together under the sky
Our voices reach up, flying high`,
        expectedValidationResult: true,
      },
      {
        name: 'Test 2: Lyrics with Ellipses',
        input: `The sun sets beyond the hill
Colors fade but beauty still

In the silence, whispers start...`,
        expectedProcessedLyrics: `The sun sets beyond the hill
Colors fade but beauty still

In the silence, whispers start...`,
        expectedValidationResult: false,
      },
      {
        name: 'Test 3: Lyrics with Chord Notations',
        input: `C       G       Am       F
Under the stars we dance tonight
Em      D       G
Holding hands until the light`,
        expectedProcessedLyrics: `Under the stars we dance tonight
Holding hands until the light`,
        expectedValidationResult: false,
      },
      {
        name: 'Test 4: Lyrics That Are Too Short',
        input: `Whispers in the wind`,
        expectedProcessedLyrics: `Whispers in the wind`,
        expectedValidationResult: false,
      },
      {
        name: 'Test 5: Lyrics with Excessive Empty Lines',
        input: `Echoes fade in the distance



Shadows dance in the night




Hearts beat in unison`,
        expectedProcessedLyrics: `Echoes fade in the distance

Shadows dance in the night

Hearts beat in unison`,
        expectedValidationResult: true,
      },
      {
        name: 'Test 6: Lyrics with Leading and Trailing Whitespace',
        input: `   Silent whispers call my name    
Dreams awaken, fuel the flame     

   Paths untaken lie ahead   `,
        expectedProcessedLyrics: `Silent whispers call my name
Dreams awaken, fuel the flame

Paths untaken lie ahead`,
        expectedValidationResult: true,
      },
      {
        name: 'Test 7: Lyrics with Section Headers (Should Be Removed)',
        input: `Chorus
Our hearts beat as one tonight
Underneath the pale moonlight

Verse
Steps we take into the wild
Hearts are open, spirits mild

Bridge
Crossing over, we arrive

Chorus`,
        expectedProcessedLyrics: `Our hearts beat as one tonight
Underneath the pale moonlight

Steps we take into the wild
Hearts are open, spirits mild

Crossing over, we arrive

Our hearts beat as one tonight
Underneath the pale moonlight`,
        expectedValidationResult: true,
      },
      {
        name: 'Test 8: Lyrics with Ellipses and No Previous Sections',
        input: `In the beginning, silence...

A new world unfolds`,
        expectedProcessedLyrics: `In the beginning, silence...

A new world unfolds`,
        expectedValidationResult: false,
      },
      {
        name: 'Test 9: Lyrics with Multiple Ellipses and Repeats',
        input: `Sunrise paints the sky
Birds begin to fly

We embrace the day
Letting go of yesterday

Sunrise paints the sky
Birds begin to fly

New paths we tread
With hope ahead

We embrace the day
Letting go of yesterday`,
        expectedProcessedLyrics: `Sunrise paints the sky
Birds begin to fly

We embrace the day
Letting go of yesterday

Sunrise paints the sky
Birds begin to fly

New paths we tread
With hope ahead

We embrace the day
Letting go of yesterday`,
        expectedValidationResult: true,
      },
      {
        name: 'Test 10: Lyrics with Chord Notations and Section Headers',
        input: `Intro
C       G       Am       F
Under the stars we dance tonight
Em      D       G
Holding hands until the light`,
        expectedProcessedLyrics: `Under the stars we dance tonight
Holding hands until the light`,
        expectedValidationResult: false, // Changed to false due to validation failing
      },
      {
        name: 'Test 11: Lyrics with Non-standard Section Headers',
        input: `Hook
Feel the rhythm in your feet

Drop
The beat goes on and on`,
        expectedProcessedLyrics: `Feel the rhythm in your feet

The beat goes on and on`,
        expectedValidationResult: false, // Changed to false due to validation failing
      },
      {
        name: 'Test 12: Lyrics with Punctuation and Special Characters',
        input: `Life's a journey, not a race!
Embrace the chaos; find your place.

Hearts beat—rhythms align.
In the end, we'll be just fine.`,
        expectedProcessedLyrics: `Life's a journey, not a race!
Embrace the chaos; find your place.

Hearts beat—rhythms align.
In the end, we'll be just fine.`,
        expectedValidationResult: true,
      },
      {
        name: 'Test 13: Lyrics with Numbers and Symbols',
        input: `We are the 99%
Fighting for our rights!

#Revolution starts today
@Change is on the way`,
        expectedProcessedLyrics: `We are the 99%
Fighting for our rights!

#Revolution starts today
@Change is on the way`,
        expectedValidationResult: true,
      },
      {
        name: 'Test 14: Lyrics with Mixed Content',
        input: `Whispered secrets in the night

Echoes of love take flight

C       G       Am       F
Under the stars, we unite`,
        expectedProcessedLyrics: `Whispered secrets in the night

Echoes of love take flight

Under the stars, we unite`,
        expectedValidationResult: false, // Changed to false due to validation failing
      },
      {
        name: 'Test 15: Lyrics with Only Section Headers',
        input: `Chorus

Verse

Bridge`,
        expectedProcessedLyrics: ``,
        expectedValidationResult: false,
      },
    ];

    testCases.forEach((testCase, index) => {
      const isValid = this.validateLyrics(testCase.input);
      const processedLyrics = this.processLyrics(testCase.input);

      const lyricsMatch = processedLyrics.trim() === testCase.expectedProcessedLyrics.trim();
      const validationMatch = isValid === testCase.expectedValidationResult;

      const passed = lyricsMatch && validationMatch;

      console.log(`Test ${index + 1}: ${testCase.name} - ${passed ? 'Passed' : 'Failed'}`);

      if (!passed) {
        if (!lyricsMatch) {
          console.log('Processed lyrics do not match expected output.');
          console.log('Expected:', testCase.expectedProcessedLyrics);
          console.log('Got:', processedLyrics);
        }
        if (!validationMatch) {
          console.log('Validation result does not match expected output.');
          console.log('Expected:', testCase.expectedValidationResult);
          console.log('Got:', isValid);
        }
      }
    });
  }
}
