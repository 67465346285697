@if (audioService.isChristmasGenre) {
  <p class="chat-text">
    Merry Christmas! And what’s your Christmas hit going to be called?
  </p>
} @else {
  <p class="chat-text">
    Awesome! And what are you going to name it?
  </p>
}

<div class="chat-white-container">
  <input (keydown.enter)="send()" [(ngModel)]="songName"
         [placeholder]="getPlaceholder()"
         [readOnly]="!currentActiveComponent"
         [readonly]="!currentActiveComponent"
         sbzCustomAutoFocus
         type="text">
  <button (click)="send()"
          [class.chat-button-generic-disabled]="!currentActiveComponent || (isUploading$ | async)"
          class="gradient-purple-button sm:!w-1/5">
    Send
  </button>
</div>

@if (isUploading$ | async) {
  <sbz-chat-loading-bar text="Saving song’s name..." [isLoading]="currentActiveComponent"></sbz-chat-loading-bar>
}
