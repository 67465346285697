import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserStateService} from './user-state.service';

@Injectable({
  providedIn: 'root'
})
export class NotifyServerService {
  private apiUrl = 'https://sketch-upload.session42.xyz';

  constructor(private httpClient: HttpClient, private userStateService: UserStateService) {
  }

  notifySongProduced(sketch_id: string) {
    const formData = new FormData();
    formData.append('sketch_id', sketch_id);

    const url = `${this.apiUrl}/notify-song-produced`;

    return this.makePostCall(formData, url);
  }

  notifyPlaySong(sketch_id: string, butcher_id: string) {
    const formData = new FormData();
    formData.append('sketch_id', sketch_id);
    formData.append('butcher_id', butcher_id);

    const url = `${this.apiUrl}/notify-play-song`;

    return this.makePostCall(formData, url);
  }

  notifyDownloadSong(sketch_id: string, butcher_id: string) {
    const formData = new FormData();
    formData.append('sketch_id', sketch_id);
    formData.append('butcher_id', butcher_id);

    const url = `${this.apiUrl}/notify-download-song`;

    return this.makePostCall(formData, url);
  }

  private makePostCall(formData: FormData, url: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userStateService.sessionToken$.getValue()!}`,
      'enctype': 'multipart/form-data'
    });
    return this.httpClient.post(url, formData, {headers}).subscribe({
      error: this.handleError
    });
  }

  private handleError(error: any) {
    console.error('Error notifying backend:', error);
  }
}
