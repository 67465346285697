import {AfterViewInit, Component, OnInit} from '@angular/core';
import {initFlowbite} from 'flowbite';
import {SubscriptionService} from './services/subscription.service';
import {MobileDetectionService} from './services/mobile-detection.service';
import {UserStateService} from './services/user-state.service';
import Hotjar from '@hotjar/browser';

@Component({
  selector: 'sbz-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  private siteId = 5170357;
  private hotjarVersion = 6;

  constructor(private subscriptionService: SubscriptionService,
              private mobileDetectionService: MobileDetectionService,
              private userStateService: UserStateService) {
  }

  ngOnInit() {
    Hotjar.init(this.siteId, this.hotjarVersion);

    initFlowbite();

    this.subscriptionService.fetchSubscriptionPackages().then();

    this.mobileDetectionService.startBreakpointObserver();
  }

  ngAfterViewInit() {
    this.userStateService.changeImage();
  }
}
