// orientation.service.ts
import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrientationService {
  public isPortrait$ = new BehaviorSubject<boolean>(false);

  private orientationTimeout: any;

  constructor(private ngZone: NgZone) {
    this.checkOrientation();

    // Listen for orientation changes
    window.addEventListener('orientationchange', () => {
      // Use NgZone to ensure change detection runs
      this.ngZone.run(() => {
        // Clear any existing timeout to prevent multiple executions
        if (this.orientationTimeout) {
          clearTimeout(this.orientationTimeout);
        }
        // Add a delay to ensure the orientation has updated
        this.orientationTimeout = setTimeout(() => {
          this.checkOrientation();
        }, 300); // Adjust delay as necessary
      });
    });
  }

  private checkOrientation() {
    const isPortrait = window.innerHeight > window.innerWidth;
    this.isPortrait$.next(isPortrait);
  }
}
