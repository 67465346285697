<nav class="mt-5 mb-32 flex justify-center">
  <ul class="flex items-center -space-x-px h-8 text-sm">
    <li>
      <a (click)="pageClick(currentPage - 1)"
         class="cursor-pointer flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span class="sr-only">Previous</span>
        <svg aria-hidden="true" class="w-2.5 h-2.5 rtl:rotate-180" fill="none" viewBox="0 0 6 10"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M5 1 1 5l4 4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </svg>
      </a>
    </li>
    @if (currentPage >= 1) {
      <li>
        <a (click)="pageClick(1)"
           [ngClass]="{
            'text-blue-600 border-blue-300 bg-blue-50': currentPage === 1,
            'text-gray-500 border-gray-300 bg-white': currentPage !== 1
          }"
           class="flex items-center justify-center px-3 h-8 leading-tight border cursor-pointer hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
      </li>
    }
    @if (currentPage > 4) {
      <li>
        <span
            class="flex items-center justify-center px-3 h-8 leading-tight border cursor-pointer hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-gray-500 border-gray-300 bg-white">...</span>
      </li>
    }
    @for (page of getPagesToShow(); track $index) {
      <li (click)="pageClick(page)">
        <a [ngClass]="{
            'text-blue-600 border-blue-300 bg-blue-50': currentPage === page,
            'text-gray-500 border-gray-300 bg-white': currentPage !== page
          }"
           class="flex items-center justify-center px-3 h-8 leading-tight border cursor-pointer hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{ page }}</a>
      </li>
    }
    @if (currentPage < totalPages - 3) {
      <li>
        <span
            class="flex items-center justify-center px-3 h-8 leading-tight border cursor-pointer hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white text-gray-500 border-gray-300 bg-white">...</span>
      </li>
    }
    @if (currentPage <= totalPages) {
      <li>
        <a (click)="pageClick(totalPages)"
           [ngClass]="{
            'text-blue-600 border-blue-300 bg-blue-50': currentPage === totalPages,
            'text-gray-500 border-gray-300 bg-white': currentPage !== totalPages
          }"
           class="flex items-center justify-center px-3 h-8 leading-tight border cursor-pointer hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{ totalPages }}</a>
      </li>
    }
    <li>
      <a (click)="pageClick(currentPage + 1)"
         class="cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
        <span class="sr-only">Next</span>
        <svg aria-hidden="true" class="w-2.5 h-2.5 rtl:rotate-180" fill="none" viewBox="0 0 6 10"
             xmlns="http://www.w3.org/2000/svg">
          <path d="m1 9 4-4-4-4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </svg>
      </a>
    </li>
    <li>
      <label class="mx-3" for="goto">Go to page: </label>
      <input [(ngModel)]="goto" [max]="totalPages" id="goto" min="1" type="number"> / {{ totalPages }}
      <button (click)="onClick.emit(goto)" [disabled]="!goto || goto > totalPages"
              class="bg-black disabled:cursor-not-allowed disabled:opacity-25 text-white py-1 px-3 ml-4 rounded-md">
        Confirm
      </button>
    </li>
  </ul>
</nav>
