import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {fadeTransition} from '../../../animations/fade-in-animation';

@Component({
  selector: 'sbz-landing-modal',
  templateUrl: './landing-modal.component.html',
  styleUrl: './landing-modal.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingModalComponent {
  constructor(public modalService: ModalService) {
  }
}
