import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'sbz-chat-final-song',
  templateUrl: './chat-final-song.component.html',
  styleUrl: './chat-final-song.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatFinalSongComponent extends BaseChatComponent {
  noResultsBug = false;
  downloadLink: string | undefined = undefined;
  isLoadingResult$ = new BehaviorSubject<boolean>(true);
  isDownloadingSpinnerVisible$ = new BehaviorSubject<boolean>(false); // New spinner control
  songIsPlaying$ = new BehaviorSubject<boolean>(false);

  private cdr = inject(ChangeDetectorRef);

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.audioService.getFinalProduction();

    this.makeFinalCall();

    this.audioService.singleButcherResultBug$.subscribe({
      next: (bugFound) => {
        if (bugFound) {
          this.audioService.singleButcherResultBug$.next(false);
          this.noResultsBug = true;
          this.finishLoading();
        }
      }
    });
  }

  tryAgain() {
    this.noResultsBug = false;
    this.isLoadingResult$.next(true);
    this.makeFinalCall();
  }

  downloadSong() {
    if (this.downloadLink) {
      // Show the spinner when download starts
      this.isDownloadingSpinnerVisible$.next(true);

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (isIOS) {
        window.location.href = this.downloadLink;
        this.isDownloadingSpinnerVisible$.next(false); // Hide the spinner after redirect
      } else {
        fetch(this.downloadLink, {mode: 'cors', credentials: 'omit'})
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
            }
            this.isDownloadingSpinnerVisible$.next(false); // Hide the spinner after download starts
            return response.blob();
          })
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = this.audioService.songName + this.downloadLink!.slice(-4);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
            this.isDownloadingSpinnerVisible$.next(false); // Hide spinner in case of error
          });
      }
    }
  }

  toggleSong() {
    if (this.songIsPlaying$.getValue()) {
      this.songIsPlaying$.next(false);
      this.audioService.togglePlayPause();
    } else {
      this.audioService.playSong('', true).then();
      this.songIsPlaying$.next(true);
    }
  }

  private detectChanges() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  private finishLoading() {
    this.isLoadingResult$.next(false);
    this.detectChanges();
  }

  private makeFinalCall() {
    this.audioService.singleButcherResult$.subscribe({
      next: (link) => {
        if (link) {
          this.downloadLink = this.isDemoSite
            ? 'https://chord-analyser-public-s3bucket-dev.s3.amazonaws.com/10_Artifichial.wav'
            : link;
          this.audioService.createAudioFileFromUrl(link);
          this.finishLoading();
        }
      },
    });
  }
}
