@if (modalService.isReferralModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container final-result-modal !h-fit !w-[90%] sm:!w-[560px]">
      @if (!referralModalObject.hideClose) {
        <div class="x-container">
          <img (click)="modalService.closeReferralModal()" alt="close modal" fill ngSrc="assets/images/close.webp">
        </div>
      }
      <h2 class="modal-header text-center !text-[18px] md:!text-[26px] px-4 !font-bold mt-2"
          [innerHTML]="referralModalObject.title | safeHtml"></h2>
      <p class="modal-content mt-4" [innerHTML]="referralModalObject.body | safeHtml"></p>

      <div class="w-full max-w-md mx-auto relative mt-8 px-4"
           [class.blur-sm]="isLoading$ | async">
        <div>
          <label class="block text-sm font-medium text-gray-700 text-center">Invite friends via email</label>
          <div class="flex flex-col gap-2 mt-2">
            @for (control of emailControls; track $index) {
              <div class="flex flex-col sm:flex-row email-style overflow-hidden">
                <input type="email"
                       [id]="'email' + $index"
                       [placeholder]="'friend' + ($index + 1) + '@example.com'"
                       [formControl]="control"
                       (input)="emailError$.next(false)"
                       class="flex-1 p-3 sm:p-4 border border-gray-200 focus:ring-transparent focus:border-gray-200 text-gray-700">
              </div>
            }

            <button (click)="shareViaEmail()"
                    class="mt-4 px-6 py-3 sm:rounded-[46px] h-[44px] rounded-[21.2px]
                     gradient-purple-button flex items-center justify-center gap-2 !w-full text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"/>
              </svg>
              Send Invites
            </button>
          </div>
          @if (emailError$ | async) {
            <p class="mt-1 text-sm text-red-500">{{ errorMessage$ | async }}</p>
          }
        </div>

        <div class="relative flex items-center justify-center w-full gap-2 py-5">
          <div class="flex-grow h-px bg-gray-200"></div>
          <span class="text-gray-500 text-sm font-medium bg-white px-4">OR</span>
          <div class="flex-grow h-px bg-gray-200"></div>
        </div>

        <span class="block text-sm font-medium text-gray-700 text-center">Share link via</span>
        <!--        <div class="flex flex-col sm:flex-row shadow-sm rounded-lg overflow-hidden">-->
        <!--          <input type="text" id="shareLink" [value]="link" readonly-->
        <!--                 class="email-style !h-[44px] flex-1 p-3 sm:p-4 border border-gray-200 focus:ring-gray-200 focus:border-gray-200 text-gray-700">-->
        <!--        </div>-->

        <div class="flex justify-center flex-wrap items-center gap-4 sm:gap-8">
          <a (click)="copyToClipboard()" class="p-3 rounded-full hover:bg-gray-100 transition-colors group">
            <svg class="w-7 h-7 text-gray-700 group-hover:text-purple-500 transition-colors" viewBox="0 0 32 32"
                 fill="currentColor">
              <path
                  d="M 4 4 L 4 24 L 11 24 L 11 22 L 6 22 L 6 6 L 18 6 L 18 7 L 20 7 L 20 4 Z M 12 8 L 12 28 L 28 28 L 28 8 Z M 14 10 L 26 10 L 26 26 L 14 26 Z"/>
            </svg>
          </a>

          <a (click)="shareToFacebook()" class="p-3 rounded-full hover:bg-gray-100 transition-colors group">
            <svg class="w-7 h-7 text-gray-700 group-hover:text-[#0866ff] transition-colors" viewBox="0 0 24 24"
                 fill="currentColor">
              <path
                  d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"/>
            </svg>
          </a>

          <a (click)="shareToTwitter()" class="p-3 rounded-full hover:bg-gray-100 transition-colors group">
            <svg class="w-6 h-6 text-gray-700 group-hover:text-black transition-colors" viewBox="0 0 24 24"
                 fill="currentColor">
              <path
                  d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
          </a>

          <a (click)="shareToWhatsApp()" class="p-3 rounded-full hover:bg-gray-100 transition-colors group">
            <svg class="w-6 h-6 text-gray-700 group-hover:text-[#25d366] transition-colors" viewBox="0 0 24 24"
                 fill="currentColor">
              <path
                  d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
            </svg>
          </a>

          <a (click)="shareToLinkedIn()" class="p-2 rounded-full hover:bg-gray-100 transition-colors group">
            <svg class="w-6 h-6 text-gray-700 group-hover:text-[#0077b5] transition-colors" viewBox="0 0 24 24"
                 fill="currentColor">
              <path
                  d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
            </svg>
          </a>
        </div>
      </div>

      @if (isLoading$ | async) {
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div class="loading-spinner"></div>
        </div>
      }

      @if (referralModalObject.hideClose) {
        <div class="w-full max-w-md mx-auto relative mt-8 px-4">
          <button (click)="goToHomePage()"
                  class="mt-4 px-6 py-3 sm:rounded-[46px] h-[44px] rounded-[21.2px] mx-auto
                     gradient-purple-button flex items-center justify-center gap-2 !w-fit text-white">
            HITCRAFT HOMEPAGE
          </button>
        </div>
      }

      <div id="toast" class="toast">
        {{ toastMessage$ | async }}
      </div>
    </div>
  </div>
}
