export interface Article {
  id: number;
  link: string;
  title: string;
  description: string;
}

export const articles: Article[] = [
  {
    id: 1,
    title: 'How to Upload a New Song to streaming platforms:',
    description: 'Watch our short video guide on how to upload a new song for distribution.',
    link: 'https://www.youtube.com/watch?v=bPpRl7g63LQ'
  },
  {
    id: 2,
    title: 'Setting Up Direct Pay with Tipalti:',
    description: 'Get your earnings directly. Here\'s\ a step-by-step video on setting up a direct pay account.',
    link: 'https://www.youtube.com/watch?v=sFkeoekdiFU'
  },
  {
    id: 3,
    title: 'Getting Authorization for Your Spotify Artist Page:',
    description: 'Want to take control of your Spotify artist page? Watch this short video guide.',
    link: 'https://www.youtube.com/watch?v=gZWa5rLNRM4'
  },
  {
    id: 4,
    title: 'Creating a Playlist on Your Personal Artist Page:',
    description: 'Engage your fans with a curated playlist. Check out our guide to learn how.',
    link: 'https://www.youtube.com/watch?v=3MM1ml2NgOY'
  },
  {
    id: 5,
    title: 'A Pitch Guide for Streaming Platforms:',
    description: 'Increase your chances of success with effective pitching. Learn how with our guide.',
    link: 'https://www.youtube.com/watch?v=Dwo42-7Xjis'
  },
  {
    id: 6,
    title: 'Uploading Canvas on Spotify:',
    description: 'Make your artist page more engaging with Canvas. Learn how to upload it on Spotify.',
    link: 'https://www.youtube.com/watch?v=iD0mLVdCmJM'
  }
];
