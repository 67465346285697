import {AfterViewInit, Component, OnInit} from '@angular/core';
import {initFlowbite} from 'flowbite';
import {SubscriptionService} from './services/subscription.service';
import {MobileDetectionService} from './services/mobile-detection.service';
import {UserStateService} from './services/user-state.service';
import Hotjar from '@hotjar/browser';
import {ModalService} from './services/modal.service';

@Component({
  selector: 'sbz-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  private siteId = 5170357;
  private hotjarVersion = 6;

  constructor(private subscriptionService: SubscriptionService,
              private mobileDetectionService: MobileDetectionService,
              private userStateService: UserStateService) {
  }

  ngOnInit() {
    if (window.location.href.includes('referral_id=')) {
      this.extractParams(window.location.href);
    }

    this.addChristmasSnow();
    Hotjar.init(this.siteId, this.hotjarVersion);

    initFlowbite();

    this.subscriptionService.fetchSubscriptionPackages().then();

    this.mobileDetectionService.startBreakpointObserver();
  }

  ngAfterViewInit() {
    this.userStateService.changeImage();
  }

  addChristmasSnow(): void {
    const canvas = document.querySelector<HTMLCanvasElement>('.canvas');

    if (!canvas) {
      console.error('Canvas element not found');
      return;
    }

    // Transfer the canvas to OffscreenCanvas
    const offscreen = canvas.transferControlToOffscreen();
    const worker = new Worker(new URL('./snow-worker.ts', import.meta.url), {type: 'module'});

    // Send initial canvas data
    worker.postMessage(
      {type: 'init', canvas: offscreen, width: window.innerWidth, height: window.innerHeight},
      [offscreen]
    );

    // Handle window resizing
    window.addEventListener('resize', () => {
      worker.postMessage({
        type: 'resize',
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }

  private extractParams(url: string) {
    const urlObj = new URL(url); // Parse the URL
    const params = new URLSearchParams(urlObj.search); // Extract the query string parameters

    const referralId = params.get('referral_id')!; // Get the value of 'referral_id'
    const source = params.get('source')!; // Get the value of 'source'
    localStorage.setItem('referral_id', referralId);
    localStorage.setItem('source', source);
  }
}
