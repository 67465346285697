@if (modalService.isMaintenanceModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container maintenance-modal">
      <h2 class="modal-header">Under <strong class="font-bold">Maintenance</strong></h2>
      <p class="modal-content">
        "🚧 We're currently performing maintenance to improve your experience.
        We'll be back online shortly. Thank you for your patience! 🚧"
      </p>
    </div>
  </div>
}
