<div #queueBlock [@fadeTransition] class="w-full flex justify-center px-7">
  <div class="queue-container text-center pt-24 rounded-3xl shadow-lg shadow-zinc-500 bg-white relative">
    <!-- <sbz-robot></sbz-robot> -->
    <div class="title text-4xl font-bold mb-6 px-7">
      You’re On The Waitlist!
    </div>
    <div class="subtitle mb-2 text-3xl text-center font-light px-7">
      Your current position is <span class="font-bold italic">{{ userQueueNumber }}</span>
      @if (totalQueueNumber) {
        out of <span class="font-bold italic">{{ totalQueueNumber }}</span> Musicians.
      }
      <p class="mb-2 mt-2 text-base text-center font-light px-7">Share your waitlist position on social media and
        increase your chances of joining our Alpha version!</p>
    </div>
    <div class="share">
      <div class="networks flex items-center justify-center relative top-10">
        <a [href]="'https://www.facebook.com/sharer/sharer.php?u='+url+'&quote=My spot in the line is '+userQueueNumber"
           class="mx-4"
           target="_blank">
          <img alt="facebook" src="../../../assets/images/queue/fb.webp" width="80px">
        </a>
        <a [href]="'https://twitter.com/intent/tweet?url='+url+'&text=My spot in the line is '+userQueueNumber"
           class="mx-4"
           target="_blank">
          <img alt="x" src="../../../assets/images/queue/x.webp" width="80px">
        </a>
        <!-- <a href="../../../assets/images/queue/waitlist-share.png" class="mx-4 cursor-pointer">
            <img width="80px" src="../../../assets/images/queue/inst.webp" alt="instagram">
        </a>
        <a href="../../../assets/images/queue/waitlist-share.png" class="mx-4 cursor-pointer">
            <img width="80px" src="../../../assets/images/queue/tik.webp" alt="tiktok">
        </a> -->
        <a [href]="'https://www.linkedin.com/sharing/share-offsite/?url='+url" class="mx-4 cursor-pointer"
           target="_blank">
          <img alt="linkedin" src="../../../assets/images/queue/ln.png" width="80px">
        </a>
      </div>
      <div (click)="copyToClipBoard(inputValue)" class="copy-link rounded-3xl pt-24 pb-16">
        <div class="copy-input relative">
          <input #inputValue [value]="url"
                 class="rounded-[29px] cursor-pointer focus:outline-none focus:border-none py-4 px-7" readonly
                 type="text">
          <img alt="" class="cursor-pointer relative right-10" src="../../../assets/images/queue/copy.png" width="22px">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="hidden" id="download">
  <img #canvas>
  <a #downloadLink></a>
</div>
