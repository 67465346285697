import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.dev';
import {Observable} from 'rxjs';
import {QueueInfo} from '../interfaces/queue-Info';


@Injectable({
  providedIn: 'root'
})
export class VirtualQueueService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getTotalQeueu(userId: string): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/queue/${userId}/total`);
  }

  getQueueInfo(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/queue/info`);
  }

  getUserQueueInfo(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/queue/info`);
  }

  setQueueInfo(data: QueueInfo): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/queue/info`, data);
  }
}
