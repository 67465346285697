import {Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-after-lyrics',
  templateUrl: './chat-after-lyrics.component.html',
  styleUrl: './chat-after-lyrics.component.scss'
})
export class ChatAfterLyricsComponent extends BaseChatComponent {
  protected override afterViewInit() {
    setTimeout(() => {
      this.goToNext('song-loading');
    }, 2500);
  }
}
