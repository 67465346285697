<p class="chat-text">{{ title$ | async }}</p>

<div class="chat-blue-container !mb-0">
  <button (click)="startFileUpload()"
          [class.chat-button-generic-disabled]="!currentActiveComponent || (isUploadingFile$ | async)|| (isAnalyzingFile$ | async)"
          [disabled]="!currentActiveComponent || (isUploadingFile$ | async) || (isAnalyzingFile$ | async)"
          [ngClass]="{'file-uploaded': fileName$ | async}"
          class="gradient-button">
    upload sketch
  </button>

  @if (fileName$ | async) {
    <p class="font-bold">{{ fileName$ | async }}</p>
  }
</div>


@if (isUploadingFile$ | async) {
  <sbz-chat-loading-bar [fileUploadProgress$]="fileUploadProgress$" text="Uploading your file..."
                        [isLoading]="(fileUploadProgress$ | async) === 100"></sbz-chat-loading-bar>
}

@if (isAnalyzingFile$ | async) {
  <sbz-chat-loading-bar [loadingTimeout]="2" text="Your song has been uploaded. We are now analyzing it..."
                        [isLoading]="currentActiveComponent"></sbz-chat-loading-bar>
}
