<div [@fadeTransition] class="flex flex-col items-center">
  <div class="text-center xl:mt-[53px] lg:mt-[66px] mt-[20px]">
    <p class="main-title">Welcome to <strong class="font-bold">Hitcraft</strong></p>
    <p class="sub-title">
      We’re revolutionizing music production by putting the power of creativity <br> back in the hands of creators.
      Elevate your sound, explore endless possibilities, and create hits like never before!</p>
    <button (click)="checkLogin()" class="main-button-design gradient-button 3xl:mt-[64px] lg:mt-[51px] mt-[31px]">
      Start Using Hitcraft
    </button>
  </div>

  <div class="vision-container">
    <div class="eye-image">
      <img alt="Image distribute 1" fill ngSrc="/assets/images/about-us/eye.webp" priority>
    </div>
    <p class="main-title !text-white 3xl:mt-[66px] lg:mt-[53px] mt-[19px]">
      Our <strong class="font-bold">Mission</strong>
    </p>
    <p class="about-us-text">
      At HitCraft, our mission is to democratize music production by leveraging advanced AI technology…
    </p>

    <button class="read-more-text" routerLink="/articles/read-more">
      Read More
    </button>
  </div>

  <p class="main-title bottom-title">Experience <strong class="font-bold">the Future of Music </strong>
    Production with HitCraft!
  </p>
  <button (click)="checkLogin()" class="bottom-button main-button-design gradient-button">
    Start Using Hitcraft
  </button>
  <sbz-footer></sbz-footer>
</div>
