<div class="button-wrapper block lg:hidden">
  <div (click)="triggerFileInput()" class="file-button-design gradient-button">
    Add a profile picture
  </div>
  <input #fileInput (change)="handleFileInput(fileInput)" [attr.accept]="fileType" [multiple]="multiple"
         hidden type="file">
</div>
<div (dragleave)="onDragLeave($event)" (dragover)="onDragOver($event)" (drop)="onDrop($event)"
     [class.drag-drop-area-darkened]="isDraggingOver" class="file-upload-wrapper hidden lg:block">
  <div (click)="triggerFileInput()" class="drag-drop-area flex flex-col justify-between h-full">
    @if (userImage || (fileType === 'image/*' && files.length > 0)) {
      <img [src]="getImageUrl()" alt="user image" class="w-full h-full object-contain">
    } @else {
      <span class="drag-drop-text">Press here to add a profile picture</span>
      <span class="drag-drop-text small-text">or</span>
      <span class="drag-drop-text">drag any picture here</span>
    }
  </div>
  <input #fileInput (change)="handleFileInput(fileInput)" [attr.accept]="fileType" [multiple]="multiple"
         hidden type="file">
</div>
<!--<div *ngIf="files.length > 0">-->
<!--  <div *ngFor="let file of files" class="file-preview">-->
<!--    <span>{{ file.name }} ({{ file.size / 1024 / 1024 | number: '1.2-2' }} MB)</span>-->
<!--  </div>-->
<!--  <div *ngIf="uploadProgress > 0">-->
<!--    <progress max="100" value="{{ uploadProgress }}"></progress>-->
<!--    <span>{{ uploadProgress }}%</span>-->
<!--  </div>-->
<!--</div>-->
