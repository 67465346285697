import {Component} from '@angular/core';

@Component({
  selector: 'sbz-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  isVisible = false;
  name: string = '';
  email: string = '';
  multiplyProvide: { state: boolean, from: number, to: number } | undefined = {
    state: false,
    from: 0,
    to: 0
  };

  openDialog(user: any | null, multiplyProvide?: { state: boolean, from: number, to: number }): void {
    this.isVisible = true;
    this.name = user?.name;
    this.email = user?.email;
    this.multiplyProvide = multiplyProvide;
  }

  closeDialog(confirmed: boolean): void {
    this.isVisible = false;
    this.name = '';
    this.email = '';
    this.multiplyProvide = undefined;
  }
}
