<p class="chat-text">
  <strong>IMPORTANT:</strong>
  Please ensure your upload includes both a vocal recording and a playback with an
  instrument or harmonic beat. (Vocals-only option coming soon.)</p>

<div class="chat-button-container">
  <button (click)="createChatBubbleWIthText('start-session', 'I understand, will only upload vocals AND playback')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="gradient-purple-button sm:mr-auto sm:!max-w-fit sm:px-8">
    I understand
  </button>
</div>
