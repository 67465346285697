import {Injectable} from '@angular/core';
import {DescopeAuthService} from '@descope/angular-sdk';
import {jwtDecode} from 'jwt-decode';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../interfaces/user';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {LoadingService} from './loading.service';
import {ModalService} from './modal.service';
import {map, tap} from 'rxjs/operators';

export enum Roles {
  Admin = 'Tenant Admin'
}

const ADMINS = ['yadin@session-42.com', 'sandman@session42.ai', 'illia.h@5bsoftware.com'];

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  startNewChatFromModal$ = new BehaviorSubject<boolean>(false);
  userNeedToVerifyEmail$ = new BehaviorSubject<boolean>(false);
  userNeedToRegister$ = new BehaviorSubject<boolean>(true);
  user$ = new BehaviorSubject<User | null>(null);
  descopeUser$ = new BehaviorSubject<any | null>(null);
  sessionToken$ = new BehaviorSubject<string | null>(null);
  referralId$ = new BehaviorSubject<string | null>(null);
  isWhiteList$ = new BehaviorSubject<boolean | null>(null);
  logo$ = new BehaviorSubject<string>('assets/images/christmas-logo.png')!;
  isSuperAdmin = new BehaviorSubject<boolean>(false);
  totalUserUploads$ = new BehaviorSubject(0);
  isUserSignUpRunning$ = new BehaviorSubject<boolean>(false);
  private dontRoute = false;

  constructor(private authService: DescopeAuthService,
              private router: Router,
              private httpClient: HttpClient,
              private loadingService: LoadingService,
              private modalService: ModalService) {
    this.authService.session$.subscribe((session) => {
      this.authService.user$.pipe(
        map(user => user)
      ).subscribe(user => {
        this.descopeUser$.next(user.user);
      });
      console.log('session.sessionToken: ', session);
      if (session.sessionToken) {
        this.updateUserFromDescope(session.sessionToken);
      }
    });

    this.user$.subscribe((user) => {
      if (user && user.email) {
        const isLoginRoute = location.href.includes('/login') || location.href.includes('/register');

        if (!isLoginRoute) {
          this.checkIfSignUpRunning(user.email);
        }
      }
    });
  }

  changeImage() {
    const imageElement = document.querySelector('#logo')! as HTMLImageElement;
    const navBarContainer = document.querySelector('.nav-bar-container')! as HTMLDivElement;
    let currentPaddingBottom = parseFloat(window.getComputedStyle(navBarContainer).paddingBottom);

    imageElement.style.opacity = '0';

    const currentHeight = imageElement.offsetHeight;
    setTimeout(() => {
      navBarContainer.style.paddingBottom = (currentPaddingBottom - 20) + 'px';
      // imageElement.style.height = `${currentHeight + 20}px`;
      this.logo$.next('assets/images/christmas-logo.png');
      imageElement.style.opacity = '1';
    }, 300);
  }

  updateUserFromDescope(sessionToken: string, pictureUrl: string | null = null) {
    this.sessionToken$.next(sessionToken);
    const decodedJwt: any = jwtDecode(sessionToken);
    const verifiedEmail = this.descopeUser$.getValue()?.verifiedEmail;

    const userData = {
      ...decodedJwt,
      ...this.descopeUser$.getValue(),
      name: decodedJwt.displayName || this.descopeUser$.getValue()?.name,
      pictureUrl: decodedJwt.pictureUrl || this.descopeUser$.getValue()?.picture,
      roles: decodedJwt.roles || this.descopeUser$.getValue()?.roleNames,
      phone: decodedJwt.phone || this.descopeUser$.getValue()?.phone,
      customAttributes: {
        ...this.descopeUser$.getValue()?.customAttributes,
        isWhiteList: false,
        // isWhiteList: decodedJwt.isWhiteList || this.descopeUser$.getValue()?.isWhiteList,
        finishedRegistration: decodedJwt.finishedRegistration || this.descopeUser$.getValue()?.finishedRegistration || this.descopeUser$.getValue()?.customAttributes?.finishedRegistration,
        pendingVerification: decodedJwt.pendingVerification || this.descopeUser$.getValue()?.customAttributes?.pendingVerification,
        customEmail: this.descopeUser$.getValue()?.customAttributes?.customEmail,
      },
    };

    console.log('decodedJwt: ', decodedJwt);
    console.log('this.descopeUser$.getValue(): ', this.descopeUser$.getValue());
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   'event': 'login'
    // });
    this.user$.next({
      loginId: this.descopeUser$.getValue()?.loginIds[0],
      email: userData?.customAttributes?.customEmail || userData.email,
      phone: userData.phone,
      displayName: userData.name,
      youtubeChannel: userData.youtubeChannel,
      instagramMusicProfile: userData.instagramMusicProfile,
      spotifyProfileLink: userData.spotifyProfileLink,
      appleMusicProfile: userData.appleMusicProfile,
      origin: userData.customAttributes.origin,
      pictureUrl: pictureUrl ? pictureUrl : userData.pictureUrl,
      finishedRegistration: userData.customAttributes.finishedRegistration,
      token: userData.sub,
      userHasSubscribed: userData.userHasSubscribed,
      selectedPackageId: userData.selectedPackageId,
      isWhiteList: userData.customAttributes.isWhiteList,
      queuePosition: userData.queuePosition,
      roles: userData.roles,
      userRoles: userData.userRoles,
      userAcceptedEula: userData.customAttributes.userAcceptedEula,
      verifiedEmail,
    });

    // if ((!userData.email || userData.customAttributes.pendingVerification) && !userData.customAttributes.finishedRegistration) {
    if ((!(userData.email || userData.customAttributes.customEmail) || userData.customAttributes.pendingVerification)) {
      this.userNeedToVerifyEmail$.next(true);
    }

    // const userNeedToRegister = !decodedJwt.email || !decodedJwt.phone || !decodedJwt.finishedRegistration || !verifiedEmail;
    let userNeedToRegister = !userData.email || !userData.phone || !userData.customAttributes.finishedRegistration;
    if (userData.customAttributes.finishedRegistration) {
      userNeedToRegister = false;
    }

    this.userNeedToRegister$.next(userNeedToRegister);
    this.isWhiteList$.next(userData.customAttributes.isWhiteList);
    this.isSuperAdmin.next(ADMINS.indexOf(userData.email) >= 0);

    if (this.dontRoute) {
      this.dontRoute = false;
      return;
    }

    const url = location.href;
    if (url.includes('verify-email')) {
      return;
    }

    if (localStorage.getItem('t')) {
      console.log('t: ', localStorage.getItem('t'));
      return;
    }

    const isLoginRoute = location.href.includes('/login') || location.href.includes('/register');
    if (isLoginRoute) {
      if (userNeedToRegister) {
        // this.router.navigate(['/register']).then();
        return;
      }

      this.router.navigate(['']).then();
    }

    console.log('userNeedToRegister: ', userNeedToRegister);
  }

  updateUserFromRegister(user: User) {
    if (user) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'register'
      });
    }
    this.user$.next(user);
    this.userNeedToRegister$.next(false);
    location.reload();
  }

  hasUserAcceptedEula(): boolean {
    const user = this.user$.getValue();
    if (!user) {
      return false;
    }
    return user.userAcceptedEula;
  }

  loginWithEmail(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.signIn(email, password)
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Login error: ', error);
            observer.error(error);
          }
        });
    });
  }

  registerWithEmail(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.signUp(
        email,
        password,
        {
          email,
        },
        {
          templateOptions: {
            subject: 'Verify Your Email for Our App',
            text: 'Click the link below to verify your email for Our App:',
            domain: location.origin,
            email: encodeURIComponent(email),
          }
        }
      )
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Registration error: ', error);
            observer.error(error);
          }
        });
    });
  }

  updatePassword(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.update(email, password)
        .subscribe({
          next: (response) => {
            console.log('updatePassword response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Update password error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendMfaCodeToEmail(loginId: string, email: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.otp.update.email(loginId, email, undefined, {
        templateOptions: {
          domain: location.origin,
          'deviceOS': 'Value',
          'startHostName': 'Value2',
          email: encodeURIComponent(email),
        }
      })
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('MFA code error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendMfaCodeToRegisteredEmail(email: string): Observable<any> {
    const url = `${environment.baseUrl}/users/mail/verify`;
    const payload = {email};

    return new Observable((observer) => {
      // First, send the PATCH request using fetch
      fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then((patchResponse) => {
          if (!patchResponse.ok) {
            // If PATCH request fails, throw an error to be caught below
            throw new Error(`PATCH request failed with status ${patchResponse.status}`);
          }
          // After successful PATCH request, send the reset email
          return this.authService.descopeSdk.password.sendReset(
            email,
            location.origin + '/login',
            {
              redirectUrl: location.origin + '/login',
              'deviceOS': 'Value',
              'startHostName': 'Value2',
              email: encodeURIComponent(email),
            }
          ).toPromise();
        })
        .then((response: any) => {
          console.log('response: ', response);
          if (!response.ok) {
            observer.error(response);
            return response;
          }
          observer.next(response);
        })
        .catch((error) => {
          console.error('MFA code error: ', error);
          observer.error(error);
        });
    });
  }

  // sendMfaCodeToRegisteredEmail(email: string): Observable<any> {
  //   return new Observable((observer) => {
  //     this.authService.descopeSdk.password.sendReset(
  //       email,
  //       location.origin + '/login',
  //       {
  //         redirectUrl: location.origin + '/login',
  //         'deviceOS': 'Value',
  //         'startHostName': 'Value2',
  //         email: encodeURIComponent(email),
  //       })
  //       .subscribe({
  //         next: (response) => {
  //           console.log('response: ', response);
  //           if (!response.ok) {
  //             observer.error(response);
  //             return response;
  //           }
  //           observer.next(response);
  //           return response;
  //         },
  //         error: (error) => {
  //           console.error('MFA code error: ', error);
  //           observer.error(error);
  //         }
  //       });
  //   });
  // }

  sendPasswordResetEmail(email: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.sendReset(email)
        .subscribe({
          next: (response) => {
            alert('Password reset email sent! Please check your inbox.');
          },
          error: (error: any) => {
            console.error('Error sending password reset email:', error);
            alert('Failed to send password reset email.');
          }
        });
    });
  }

  verifyEmailCode(loginId: string, code: string): Observable<any> {

    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/verify/confirm-email?loginId=${encodeURIComponent(loginId)}&code=${code}`;


    const headers = {
      'Authorization': `Bearer ${this.sessionToken$.getValue()}`,
      'Content-Type': 'application/json'
    };

    return new Observable(observer => {
      fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({}), // Empty body, as per the original code
      })
        .then(response => {
          if (!response.ok) {
            return response.json().then(err => {
              throw err; // Throw the error to be caught below
            });
          }
          return response.json();
        })
        .then(data => {
          console.log('Response:', data);
          observer.next(data);
          observer.complete();
        })
        .catch(err => {
          console.error('Error:', err);
          observer.error(err);
        })
        .finally(() => {
          this.loadingService.toggleLoading(false); // Ensure loading is turned off
        });
    });

    // return this.httpClient.post(url, {}, options).pipe(
    //   tap((response) => {
    //     console.log('Response:', response);
    //     // Optionally perform additional actions, such as updating the user
    //     // localStorage.setItem('userAcceptedEula', 'true');
    //     // this.authService.refreshSession();
    //     // @ts-ignore
    //     // this.user$.next({...this.user$.getValue(), userAcceptedEula: true});
    //   }),
    //   catchError((err: any) => {
    //     console.error('Error:', err);
    //     // this.modalService.openErrorModal('We could not update user info. Please try again later.');
    //     return err; // Ensure error is re-thrown after being handled
    //   }),
    //   finalize(() => {
    //     this.loadingService.toggleLoading(false); // Ensure loading is turned off
    //   })
    // );
    // return this.authService.descopeSdk.otp.verify.email(loginId, code);
  }

  verifyMfaCode(email: string, code: string): Observable<any> {
    return this.authService.descopeSdk.otp.verify.email(email, code);
  }

  verifyMagicLink(code: string): Observable<any> {
    const data = this.authService.descopeSdk.magicLink.verify(code);
    console.log('data: ', data);

    return data;
  }

  loginWithFacebook() {
    this.authService.descopeSdk.oauth.start('facebook', location.origin + '/login').subscribe({
      next: (response) => {
        window.location.href = response?.data?.['url'];
      },
      error: (error) => {
        console.error('error: ', error);
      }
    });
  }

  loginWithGoogle() {
    return this.authService.descopeSdk.oauth.start('google', location.origin + '/login').subscribe({
      next: (response) => {
        window.location.href = response?.data?.['url'];
      },
      error: (error) => {
        console.error('error: ', error);
      }
    });
  }

  handleOAuthResponse(code: string) {
    return new Promise((resolve, reject) => {
      this.authService.descopeSdk.oauth.exchange(code).subscribe({
        next: (response) => {
          console.log('handleOAuthResponse response: ', response);
          if (!response.ok) {
            console.error('Error during OAuth response handling:', response);
            reject(response);
          }
          resolve(response);
        },
        error: (error) => {
          console.error('Error during OAuth response handling:', error);
          reject(error);
        }
      });
    });
  }

  sendVerificationLink(email: string) {
    // this.authService.descopeSdk.password.sendSignUpLink(email, { redirectURL: 'https://your-app.com/verify-email' })
    //   .then(() => {
    //     console.log('Verification link sent successfully');
    //   })
    //   .catch((error) => {
    //     console.error('Error sending verification link: ', error);
    //   });
  }

  logoUt() {
    this.authService.descopeSdk.logout();
    this.user$.next(null);
  }

  checkUserInfoAndNavigate(): boolean {
    this.loadingService.toggleLoading(true);
    const userNeedToRegister = this.userNeedToRegister$.getValue();
    const user = this.user$.getValue();

    if (user?.userHasSubscribed) {
      this.loadingService.toggleLoading(false);
      return false;
    }

    this.loadingService.toggleLoading(false);

    if (location.href.includes('verify-email')) {
      return true;
    }

    if (userNeedToRegister || !user) {
      this.router.navigate(['/login']).then();
      return true;
    }

    if (user?.isWhiteList) {
      this.router.navigate(['']).then(); // changed to navigate user from queue to home page, prevously was '/queue' target
      return true;
    }

    // this.router.navigate(['/subscribe']).then();
    if (user && !user.isWhiteList) {
      this.router.navigate(['/subscribe']).then();
    }
    return true;
  }

  updateUserInfo(user: User) {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, user, options)
      .subscribe({
        next: () => {
          this.updateUserFromRegister(user);

          // registration event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'login'
          });

          this.authService.refreshSession();
          this.router.navigate(['']).then();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }

  acceptedEula() {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/acceptedEula`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, {}, options)
      .subscribe({
        next: () => {
          localStorage.setItem('userAcceptedEula', 'true');
          this.authService.refreshSession();
          // @ts-ignore
          this.user$.next({...this.user$.getValue(), userAcceptedEula: true});
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }

  verifyNewEmail(email: string, token: string): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/mail/${token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.patch(url, {email}, options).pipe(
      tap({
        next: () => {
          this.loadingService.toggleLoading(false);
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not verify your email. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      })
    );
  }

  updateUserMetadata() {
    const url = `${environment.baseUrl}/users/location/${this.user$.getValue()?.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, {}, options)
      .subscribe({
        next: () => {
          this.authService.refreshSession();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          // this.loadingService.toggleLoading(false);
        }
      });
  }

  getUserUploads() {
    if (this.user$.getValue()) {
      const user_id = this.user$.getValue()!.email;
      const url = `https://sketch-upload.session42.xyz/get-user-uploads?user_id=${encodeURIComponent(user_id)}`;
      return this.httpClient.get(url);
    }
    return null;
  }

  getUsersWhiteList(page: number): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/users/whitelist?page=${page}`);
  }

  updateUserWhiteList(user: any, sendEmail: boolean, access: boolean): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.userId}/whitelist`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.patch(url, {customAttributes: user.customAttributes, sendEmail, access}, options);
  }

  searchWhiteListUsers(query: string): Observable<any> {
    const url = `${environment.baseUrl}/users/whitelist/search`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.post(url, {query}, options);
  }

  refreshDescopeToken() {
    this.dontRoute = true;
    return this.authService.refreshSession();
  }

  grandAccesses(range: { from: number, to: number }, sendEmail: boolean): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/grand-access`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.post(url, {range, sendEmail}, options);
  }

  updateSubscriptionStatus(packageId: string) {
    const user = this.user$.getValue();
    if (user) {
      user.userHasSubscribed = true;
      user.selectedPackageId = packageId;
    }
  }

  startFileUpload(): Promise<File> {
    return new Promise((resolve, reject) => {
      // Create a hidden file input element
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'audio/mpeg, audio/wav'; // Accept audio files only
      // fileInput.accept = 'audio/*';
      fileInput.style.display = 'none'; // Hide the input element

      // Append the file input to the body
      document.body.appendChild(fileInput);

      // Add an event listener to handle the file selection
      fileInput.addEventListener('change', (event: any) => {
        const file = event.target.files[0];
        if (file) {
          // Check file size
          const MAX_SIZE = 50 * 1024 * 1024; // 50 MB in bytes
          if (file.size > MAX_SIZE) {
            reject(new Error('The file is too large. Maximum allowed size is 50 MB.'));
            fileInput.remove();
            return;
          }

          // Check audio duration
          this.getAudioDuration(file)
            .then((duration) => {
              const MIN_DURATION = 30;     // 30 seconds
              const MAX_DURATION = 6 * 60; // 6 minutes in seconds

              if (duration < MIN_DURATION) {
                reject(new Error('The audio file is too short. Minimum required duration is 30 seconds.'));
              } else if (duration > MAX_DURATION) {
                reject(new Error('The audio file is too long. Maximum allowed duration is 6 minutes.'));
              } else {
                // Modify the file name by replacing spaces with underscores
                this.modifyFileName(file)
                  .then((modifiedFile) => {
                    resolve(modifiedFile);
                  })
                  .catch((error) => {
                    console.error('Error modifying file name:', error);
                    reject(new Error('The file name has spaces. please remove them.'));
                  });
              }
              fileInput.remove();
            })
            .catch((error) => {
              console.error('Error getting audio duration:', error);
              reject(new Error('Could not read the audio file. Please try again with a valid audio file.'));
              fileInput.remove();
            });
        } else {
          reject(new Error('No file selected.'));
          fileInput.remove();
        }
      });

      // Programmatically click the file input
      fileInput.click();
    });
  }

  uploadSketch(file: File) {
    // Prepare form data
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('full_analysis', 'false');
    formData.append('user_id', this.user$.getValue()!.email);

    // Set the headers if needed
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.sessionToken$.getValue()}`,
      'enctype': 'multipart/form-data'
    });

    // Send the POST request with progress reporting enabled
    return this.httpClient.post('https://sketch-upload.session42.xyz/sketch-upload', formData, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
  }

  sendRegInfoToDb() {
    this.isUserSignUpRunning$.next(true);

    const url = 'https://sketch-upload.session42.xyz/user-signup';

    // Prepare form data
    const formData = new FormData();
    const user = this.user$.getValue()!;
    formData.append('user_id', user.email);
    // formData.append('user_name', user.displayName);

    const referral_id = localStorage.getItem('referral_id');
    const source = localStorage.getItem('source')!;
    if (referral_id) {
      formData.append('referral_id', referral_id);
      formData.append('source', source);
    }

    this.httpClient.post(url, formData).subscribe({
      next: (response: any) => {
        localStorage.removeItem('referral_id');
        localStorage.removeItem('source');
        this.getUserReferralId(this.user$.getValue()!.email);
      },
      error: (error) => {
        console.error('Error registering user:', error);
      },
      complete: () => {
        this.isUserSignUpRunning$.next(false);
      }
    });
  }

  private modifyFileName(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      try {
        // Replace spaces with underscores in the file name
        const newFileName = file.name.replace(/\s+/g, '_');

        // Create a new File object with the modified name
        const modifiedFile = new File([file], newFileName, {
          type: file.type,
          lastModified: file.lastModified,
        });

        resolve(modifiedFile);
      } catch (error) {
        reject(error);
      }
    });
  }

  private getAudioDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        const audio = document.createElement('audio');
        audio.preload = 'metadata';

        // Create a URL for the audio file
        const objectUrl = URL.createObjectURL(file);
        audio.src = objectUrl;

        audio.onloadedmetadata = () => {
          // Chrome bug: audio.duration is NaN for certain audio types
          if (isNaN(audio.duration) || audio.duration === Infinity) {
            // Try to force the browser to calculate the duration
            audio.currentTime = Number.MAX_SAFE_INTEGER;
            audio.ontimeupdate = () => {
              audio.ontimeupdate = null;
              resolve(audio.duration);
              URL.revokeObjectURL(objectUrl);
            };
          } else {
            resolve(audio.duration);
            URL.revokeObjectURL(objectUrl);
          }
        };

        audio.onerror = (error) => {
          reject(error);
          URL.revokeObjectURL(objectUrl);
        };
      } catch (error) {
        reject(error);
      }
    });
  }

  private checkIfSignUpRunning(email: string) {
    const isProcessing = this.isUserSignUpRunning$.getValue();
    if (this.isUserSignUpRunning$.getValue()) {
      console.log('Waiting for sendRegInfoToDb to finish...');
      const interval = setInterval(() => {
        if (!isProcessing) {
          clearInterval(interval);
          this.getUserReferralId(email);
        }
      }, 100); // Check every 100ms
    } else {
      this.getUserReferralId(email);
    }
  }

  private getUserReferralId(email: string) {
    const url = `https://sketch-upload.session42.xyz/get-referral-id?user_id=${encodeURIComponent(email)}`;
    this.httpClient.get(url)
      .subscribe({
        next: (response: any) => {
          this.referralId$.next(response.referral_id);
        },
        error: (event: HttpErrorResponse) => {
          this.referralId$.next(null);
        }
      });
  }
}
