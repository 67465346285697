import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {AudioService} from '../../services/audio.service';
import {Router} from '@angular/router';
import {UserStateService} from '../../services/user-state.service';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit, OnDestroy {
  isPlayingDemo: boolean = false;
  isPlayingPrompt: boolean = false;
  request: boolean = false;
  hasSong: boolean = false;
  prompt: string = '';
  currentlyPlayingSong: string = '';
  // hadPrompt: boolean = false;
  listenFinal: boolean = false;
  listenFinalTrack: string = '';
  limit: number = 120;
  @ViewChild('loaderBar') loaderBar?: ElementRef;
  private popUpShown = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private audioService: AudioService,
    private router: Router,
    private userStateService: UserStateService
  ) {
    // this.hadPrompt = !!localStorage.getItem('audioUrl');
  }

  ngOnInit() {
    this.userStateService.user$
      .subscribe(user => {
        if (user) {
          setTimeout(() => {
            this.checkAndOpenModal();
          }, 1500);
        }
      });
  }

  ngOnDestroy() {
    this.popUpShown = false;
  }

  scrollToElement($element: Element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
  }

  async produceSong() {
    try {
      this.request = true;
      const body = {prompt: this.prompt};
      const response = await fetch('https://5opishwffgp5rtcjzkvgoev7ya0nfchq.lambda-url.us-east-1.on.aws', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      // const data: {sub_genres: string[], sub_genres_urls: string[]} = await response.json();
      const data: { demo_num: number, url: string } = await response.json();
      if (!response.ok) {
        this.modalService.openErrorModal('Sorry, we seem to be napping at the moment. Please try again in a few moments.');
        this.request = false;
        this.cdr.detectChanges();
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        let interval = setInterval(() => {
          if (this.loaderBar) {
            const width = parseInt(this.loaderBar.nativeElement.style.width);
            if (width !== 100) {
              const val = width ? width + 20 : 0 + 20;
              this.loaderBar.nativeElement.style.width = val + '%';
            } else {
              // if(!localStorage.getItem('audioUrl')) {
              //   localStorage.setItem('audioUrl', data.sub_genres_urls[0])
              // }
              this.audioService.createAudioFileFromUrl(data.url);
              this.listenFinalTrack = data.url;
              this.listenFinal = true;
              clearInterval(interval);
              if (response.ok) {
                this.request = false;
                // this.hadPrompt = true;
                this.hasSong = true;
                this.cdr.detectChanges();
              }
            }
          }
        }, 1000);
      }
    } catch {
      this.request = false;
      // this.hadPrompt = false;
      this.hasSong = false;
      this.modalService.openErrorModal('Sorry, we seem to be napping at the moment. Please try again in a few moments.');
    }
  }

  playSketch() {
    this._playPause('demo', false);
    this.isPlayingDemo = !this.isPlayingDemo;
    this.isPlayingPrompt = false;
  }

  playPromptSong() {
    this.isPlayingDemo = false;
    this.isPlayingPrompt = !this.isPlayingPrompt;
    this._playPause(this.listenFinalTrack, true);
    // const storedAudio = localStorage.getItem('audioUrl');
    // if(storedAudio) {
    //   this.audioService.createAudioFileFromSketch(storedAudio);
    //   this._playPause(storedAudio, true);
    //   this.currentlyPlayingSong = storedAudio ? storedAudio : '';
    // }
  }

  changeLimit(value: string) {
    let limit = 120;
    if (this.limit >= 0) {
      limit -= value.length;
      this.limit = limit;
    }
  }

  navigate() {
    const USER = this.userStateService.user$.getValue();
    if (USER) {
      this.router.navigate(['chat']);
      // if (USER.isWhiteList) {
      //   this.router.navigate(['queue']);
      //   console.log('landing - navigate - user.isWhiteList');
      // } else {
      //   if (!location.href.includes('/chat')) {
      //     this.router.navigate(['']).then();
      //   }
      //   console.log('landing - navigate - !user.isWhiteList');
      // }
    } else {
      this.router.navigate(['login']);
      console.log('landing - navigate - !user');
    }
  }

  _playPause(song: string, sketch: boolean) {
    if (this.currentlyPlayingSong !== song) {
      this.audioService.playSong(song, sketch);
      this.currentlyPlayingSong = song;
    } else {
      this.audioService.togglePlayPause();
    }
  }

  buttonText(): string {
    const signedIn = this.userStateService.user$.getValue()?.token;
    if (signedIn) {
      return 'let\'s go!';
    } else {
      return 'Sign up for free now';
    }
  }

  showModal() {
    this.modalService.openLandingPromptModal(`
      <br/>
      We’re sorry you didn’t get the production you were hoping for! HitCraft is still in alpha and is learning about different musical styles.<br/><br/>
      In the meantime, please sign up for our waitlist to stay updated on new features!<br/><br/>
      The HitCraft Team`
    );
  }

  private checkAndOpenModal() {
    const isProcessing = this.userStateService.isUserSignUpRunning$.getValue();
    if (this.userStateService.isUserSignUpRunning$.getValue()) {
      console.log('Waiting for sendRegInfoToDb to finish...');
      const interval = setInterval(() => {
        if (!isProcessing) {
          clearInterval(interval);
          this.openModalIfApplicable();
        }
      }, 100); // Check every 100ms
    } else {
      this.openModalIfApplicable();
    }
  }

  private openModalIfApplicable() {
    if (!this.popUpShown) {
      this.popUpShown = true;
      this.modalService.openReferralModal(1);
    }
  }
}

