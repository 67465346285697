import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading$ = new BehaviorSubject<boolean>(false);

  toggleLoading(isLoading: boolean): void {
    setTimeout(() => {
      this.isLoading$.next(isLoading);
    }, isLoading ? 0 : 1000);
  }
}
