import {Component, ViewChild} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {ChatLoadingBarComponent} from '../chat-loading-bar/chat-loading-bar.component';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-chat-song-loading',
  templateUrl: './chat-song-loading.component.html',
  styleUrl: './chat-song-loading.component.scss'
})
export class ChatSongLoadingComponent extends BaseChatComponent {
  @ViewChild(ChatLoadingBarComponent) loadingBarComponent!: ChatLoadingBarComponent;

  protected override afterViewInit() {
    if (this.isDemoSite) {
      this.loadingBarComponent.finishLoading();
      this.audioService.getPreviewsCompleteAndFailedMockData();
      setTimeout(() => {
        this.goToNext('results-players');
      }, 3000);
    } else {
      this.audioService.firstDynamicButcherResponseCame$
        .subscribe((firstDynamicButcherResponseCame) => {
          if (firstDynamicButcherResponseCame) {
            this.loadingBarComponent.finishLoading();
            setTimeout(() => {
              this.goToNext('results-players');
            }, 3000);
          }
        });
    }
  }
}
