import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {teamMembers} from '../../../interfaces/team-member';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'sbz-about-us',
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsComponent implements OnInit {
  teamMembers = teamMembers;
  currentSet: number = 0;
  activeIndex: number | null = null;

  constructor(private userStateService: UserStateService) {
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.team-member-image')) {
      this.activeIndex = null;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  changeSet(setNumber: number): void {
    this.currentSet = setNumber;
  }

  checkLogin() {
    this.userStateService.checkUserInfoAndNavigate();
  }

  toggleInfo(index: number): void {
    this.activeIndex = this.activeIndex === index ? null : index;
  }
}
