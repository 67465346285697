import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'sbz-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectComponent {
  @Input() options: Array<string> = [];
  @Output() selectedChange = new EventEmitter<string[]>();
  @ViewChild('dropdown') dropdown!: ElementRef;
  selectedValues: string[] = [];
  dropdownOpen: boolean = false;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: PointerEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  isSelected(value: string): boolean {
    return this.selectedValues.includes(value);
  }

  toggleSelection(value: string) {
    if (this.isSelected(value)) {
      this.selectedValues = this.selectedValues.filter(val => val !== value);
    } else {
      this.selectedValues.push(value);
    }
    this.selectedChange.emit(this.selectedValues);
  }

  removeSelected(event: Event, option: string) {
    event.stopPropagation();
    this.selectedValues = this.selectedValues.filter(val => val !== option);
    this.selectedChange.emit(this.selectedValues);
  }
}

