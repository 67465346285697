import {animate, state, style, transition, trigger} from '@angular/animations';

export const bounce = trigger('bounce', [
  state('in', style({transform: 'scale(1)', opacity: 1})),
  transition('void => *', [
    style({transform: 'scale(0.3) translateY(-100vh)', opacity: 0}),
    animate('1s cubic-bezier(.8,-0.6,0.2,1.5)', style({
      transform: 'scale(1) translateY(0) translateX(-50%)',
      opacity: 1
    }))
  ]),
  transition('* => void', [
    animate('1s cubic-bezier(.8,-0.6,0.2,1.5)', style({transform: 'scale(0.3) translateY(-100vh)', opacity: 0}))
  ])
]);
