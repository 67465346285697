@if (isRobotVisible$ | async) {
  <div #robotContainer @bounce [@.disabled]="!(shouldBounce$ | async)" class="robot-container robot-center"
       [ngClass]="(robotPosition$ | async)">
    <div class="hat-container">
      <div class="christmas-hat"></div>
    </div>

    <div class="robot-background relative">
      @if (isLoading$ | async) {
        <div class="loader"></div>
      } @else {
        @if ((robotImageSrc$ | async)) {
          <section class="flex justify-center items-center h-full">
            <img [src]="(robotImageSrc$ | async)" class="robot-sent-image" alt="image">
          </section>
        } @else {
          <div #leftEye class="open-eye left animation-blink" [ngClass]="(robotAnimation$ | async)"></div>
          <div #rightEye class="open-eye right animation-blink" [ngClass]="(robotAnimation$ | async)"></div>
        }
      }
    </div>
  </div>
}
