import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {Roles, UserStateService} from '../services/user-state.service';
import {environment} from '../../environments/environment.prod';

export const ManagementGuard: CanActivateFn = (route, state) => {
  const userStateService = inject(UserStateService);
  const router = inject(Router);
  if (!environment.production && window.location.href.includes('http://localhost:4200/') || userStateService.isSuperAdmin.getValue()) {
    return true;
  }

  const approved = userStateService.user$.getValue()?.email?.includes('blakazulu@gmail.com');
  if (approved) {
    return true;
  }

  if (userStateService.user$.getValue()?.roles.includes(Roles.Admin)) {
    return true;
  } else {
    if (!location.href.includes('/chat')) {
      router.navigate(['']).then();
    }
    console.log('ManagementGuard');
    return false;
  }
};
