<div [@fadeTransition] class="w-full flex justify-center">
  <div [ngClass]="'lg:!w-[845px] sm:!w-[677px]'"
       class="white-container sm:w-[896px] sm:max-w-none w-[90%] max-w-[896px] !rounded-[30.3px] md:!rounded-[42px]">
    <div class="white-container-wrapper">
      @if (verificationStatus === 'pending') {
        <div>
          <p>Verifying your email, please wait...</p>
        </div>
      }

      @if (verificationStatus === 'success') {
        <div class="text-center relative">
          <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light"><strong
            class="font-bold">Email Verified Successfully!</strong></p>
            <br />
          <p class="font-prop-normal font-prop-normal-desc text-center text-[14px] leading-[25.5px] font-poppins">
            Your email has been verified. You can now log in.
          </p>
          <div class="flex justify-center space-x-2">
            <div class="parent-container">
              <a (click)="navigateToLogin()" class="btn btn-primary">
                <button
                    class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[312px] lg:!w-[312px] sm:!w-[312px] !w-[312px] main-button-design mx-auto"
                    type="submit">
                  Finish Registration
                </button>
              </a>
            </div>
          </div>
        </div>
      }

      @if (verificationStatus === 'error') {
        <div class="text-center relative">
          <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light"><strong
            class="font-bold">Email Verification Failed</strong></p>
            <br />
          <p class="font-prop-normal font-prop-normal-desc text-center text-[14px] leading-[25.5px] font-poppins">
            {{ errorMessage }}
          </p>
          <div class="flex justify-center space-x-2">
            <div class="parent-container">
              <a (click)="navigateToLogin()" routerLink="/resend-verification" class="btn btn-primary">
                <button
                    class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[201px] main-button-design mx-auto"
                    type="button">
                  Resend Verification Email
                </button>
              </a>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
