<div class="landing pt-[42vw] md:pt-[350px]">
  <section class="top flex flex-col items-center px-[10vw]">
    <p class="title text-5xl text-center font-light">Your Music Creation - Empowered by AI</p>
    <p class="subtitle font-medium text-[28.5px] text-center mt-[7vw] lg:mt-3 mb-[11vw] sm:mb-16 3xl:mb-20">Transform
      your demo songs into standout tracks with HitCraft&nbsp;-<br>
      where AI supercharges human creativity</p>
    <button (click)="navigate()" class="main-button-design gradient-purple-button 2xl:w-[290px]" id="sign-up-1">
      {{ buttonText() }}
    </button>
  </section>
  <div (click)="scrollToElement(demo)" class="try-demo-link text-center mt-28 lg:mt-9 3xl:mt-12">
    <span
        class="font-medium text-[3.26vw] sm:text-2xl lg:text-base 2xl:text-lg cursor-pointer pb-[1px] border-b-[1px] border-black">See it in action</span>
  </div>
  <div
      class="video relative flex justify-center items-center mt-9 lg:mt-28 3xl:mt-12 w-4/5 lg:w-1/2 ml-auto mr-auto lg:mb-36 md:mb-24 sm:mb-24 mb-12">
    <div class="container">
      <div class="laptop">
        <div class="laptop__screen">
          <iframe
            #videoPlayer
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            class="rounded-xl"
            frameborder="0"
            referrerpolicy="strict-origin-when-cross-origin"
            src="https://www.youtube.com/embed/60LRvZBTJ3k?si=iK4GwBNQM4ZhyU6h"
            title="YouTube video player">
          </iframe>
        </div>
        <div class="laptop__shadow"></div>
      </div>
    </div>
  </div>
  <section class="instant grid grid-cols-1 lg:grid-cols-2 lg:pl-[104px] xl:pl-[202px] 2xl:pl-[253px]">
    <div
        class="left-block flex flex-col justify-center px-[10vw] lg:px-0 order-2 sm:order-2 lg:order-1 items-center md:items-center lg:items-start text-center md:text-center lg:text-left">
      <p class="title sm:text-4xl 3xl:text-5xl font-light mb-[7.2vw] sm:mb-10 3xl:mb-12">Seamless <br>
        <span class="font-bold">Music Crafting</span></p>
      <p class="subtitle text-lg sm:text-lg 3xl:text-2xl font-medium pr-0 lg:pr-32 mb-[17.3vw] sm:mb-11 3xl:mb-14">
        Whether you’re a professional seeking creative ideas or a hobbyist seeking release-ready sounds, HitCraft turns
        sketches into polished songs in minutes.</p>
      <button (click)="navigate()" class="main-button-design gradient-purple-button 2xl:w-[290px]" id="sign-up-2">
        {{ buttonText() }}
      </button>
    </div>
    <div class="right-block text-right order-1 sm:order-1 lg:order-2">
      <img alt="" class="w-8/12 lg:w-full" src="assets/images/landing/layer-3433@3x.png">
    </div>
  </section>
  <section class="effortless grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:pr-[104px] xl:pr-[202px] 2xl:pr-[253px]">
    <div class="left-block text-right sm:order-1">
      <img alt="" class="w-8/12 lg:w-full" src="assets/images/landing/layer-3429@3x.png">
    </div>
    <div
        class="right-block flex flex-col justify-center lg:pl-40 px-[10vw] lg:pr-0 sm:order-2 items-center md:items-center lg:items-start text-center md:text-center lg:text-left lg:-mt-32">
      <p class="title sm:text-4xl 3xl:text-5xl font-light mb-[7.2vw] sm:mb-10 3xl:mb-12">Absolute <br>
        <span class="font-bold">Creative Control</span></p>
      <p class="subtitle text-lg sm:text-lg 3xl:text-2xl font-medium mb-[17.3vw] sm:mb-11 3xl:mb-14">With HitCraft, you
        can download full project files to continue work on the production that's yours to own, distribute, license, and
        sell.</p>
      <button (click)="navigate()" class="main-button-design gradient-purple-button 2xl:w-[290px]" id="sign-up-3">
        {{ buttonText() }}
      </button>
    </div>
  </section>
  <section
      class="inspiration grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:pl-[104px] xl:pl-[202px] 2xl:pl-[253px] mb-[22vw] lg:mb-16 3xl:mb-20">
    <div
        class="left-block flex flex-col px-[10vw] lg:pl-0 justify-center order-2 sm:order-2 lg:order-1 items-center md:items-center lg:items-start text-center md:text-center lg:text-left">
      <p class="title sm:text-4xl 3xl:text-5xl font-light mb-[7.2vw] sm:mb-10 3xl:mb-12">Endless <br>
        <span class="font-bold">Sound Exploration</span></p>
      <p class="subtitle text-lg sm:text-lg 3xl:text-2xl font-medium pr-0 mb-[17.3vw] sm:mb-11 3xl:mb-14">HitCraft
        allows you to explore your sound in 30+ sub-genres. From classic hip-hop to thrash metal or reggae, the
        possibilities are limitless. Discover your style in every sound.</p>
      <button (click)="navigate()" class="main-button-design gradient-purple-button 2xl:w-[290px]" id="sign-up-4">
        {{ buttonText() }}
      </button>
    </div>
    <div class="right-block text-right order-1 sm:order-1 lg:order-2">
      <img alt="" class="w-8/12 lg:w-full" src="assets/images/landing/layer-538@3x.png">
    </div>
  </section>
  <section #demo class="how-it-works px-[10vw]">
    <p class="title sm:text-4xl 3xl:text-5xl text-center font-light mb-[9vw] lg:mb-20 3xl:mb-24">See How <span
        class="font-bold">HitCraft</span> Works</p>
    <div class="demo mt-8">
      <div
          class="produce-song text-white flex flex-col justify-start w-3/5 py-[70px] lg:py-16 px-[8vw] lg:px-[74px] 3xl:px-[93px]">
        <p class="text-[4vw] mb-7 lg:mb-[42px] text-center lg:text-left lg:text-3xl font-light">From Sketch To Song:
          Create Your Own Unique Style</p>
        <div class="action">
          <p class="label-field mb-5 lg:text-lg font-medium">1. Listen to our demo sketch</p>
          <div class="listen-sketch mb-10 sm:mb-8">
            <div class="sketch-container flex justify-start items-center">
              <div (click)="playSketch()"
                   class="play flex justify-center items-center ml-[7px] sm:ml-[10px] cursor-pointer">
                <fa-icon [icon]="isPlayingDemo ? 'pause' : 'play'" class="text-white"></fa-icon>
              </div>
              <span class="ml-2 font-medium lg:ml-6 lg:text-lg">Play the demo</span>
            </div>
          </div>
          <p class="label-field mb-5 lg:text-lg font-medium">2. Describe the musical style for the song</p>
          <textarea (ngModelChange)="changeLimit($event)" [(ngModel)]="prompt" [maxlength]="120"
                    class="resize-none text-[9px] sm:text-[19px] lg:text-base leading-[9px] lg:leading-4 p-6 sm:p-5"
                    id="prompt" name="prompt" placeholder="Example: 90’s grunge style rock with acoustic guitars"
                    rows="5"></textarea>
          <p class="limit text-right opacity-50 mt-2 lg:mt-5 lg:text-sm mb-6 sm:mb-8 lg:mb-11">{{ limit }} characters
            left</p>
        </div>
        @if (!hasSong && !request) {
          <button id="produce-song" (click)="produceSong()" [disabled]="!prompt" [ngClass]="{'disabled': !prompt}"
                  class="main-button-design black-button">create the song!
          </button>
        }
        @if (request) {
          <button class="main-button-design black-button loader flex justify-center items-center cursor-wait">
            <div class="box-shadow absolute left-0 top-0"></div>
            <div #loaderBar class="progress absolute left-0 top-0"></div>
            <div id="circularG">
              <div id="circularG_1" class="circularG">
              </div>
              <div id="circularG_2" class="circularG">
              </div>
              <div id="circularG_3" class="circularG">
              </div>
              <div id="circularG_4" class="circularG">
              </div>
              <div id="circularG_5" class="circularG">
              </div>
              <div id="circularG_6" class="circularG">
              </div>
              <div id="circularG_7" class="circularG">
              </div>
              <div id="circularG_8" class="circularG">
              </div>
            </div>
          </button>
        }
        @if (listenFinal && hasSong) {
          <button id="listen-final-track" (click)="listenFinal = false" class="main-button-design black-button">
            <fa-icon class="text-white mr-5" icon="play"></fa-icon>
            Listen the final track!
          </button>
        }
        @if (!listenFinal && hasSong) {
          <div class="final-step flex gap-4">
            <div class="inline-block">
              <div id="play-pause-final-track" (click)="playPromptSong()"
                   class="play-final flex justify-center items-center cursor-pointer">
                <fa-icon class="text-white" [icon]="isPlayingPrompt ? 'pause' : 'play'"></fa-icon>
              </div>
            </div>
            <button id="sign-up-5" (click)="navigate()"
                    class="main-button-design black-button inline-block">{{ buttonText() }}
            </button>
          </div>
          <div class="flex justify-center cursor-pointer">
            <span (click)="showModal()" class="helper text-white underline text-xs pt-4 sm:pt-8">Not what you were expecting?</span>
          </div>
        }
      </div>
    </div>
  </section>
  <section class="what-users-say px-[10vw] mt-[19vw] sm:mt-44 3xl:mt-56">
    <p class="title sm:text-4xl 3xl:text-5xl text-center font-light mb-[10vw] sm:mb-16 lg:mb-20 3xl:mb-24">What Our
      <span class="font-bold">Users Say</span></p>
    <div class="users-feedbacks grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-12">
      <div class="feedback bg-white rounded-3xl p-[6vw] lg:p-6 3xl:p-8 text-center">
        <div class="bg-gray-50 rounded-3xl px-[6vw] py-[8vw] lg:p-5">
          <div class="rating mb-8">
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
          </div>
          <div class="response font-medium text-[27px] lg:text-sm xl:text-lg">
            "HitCraft is a game-changer! As a producer, the efficiency and precision it offers in transforming basic
            sketches into polished tracks is unparalleled. It’s like having a top-tier production team at your
            fingertips."
          </div>
        </div>
        <div class="user mt-8 sm:mt-16 lg:mt-9">
          <p class="font-bold text-[6vw] lg:text-2xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl">Samuel Pesci</p>
          <p class="font-light text-[4vw] lg:text-lg 2xl:text-xl">Music Producer</p>
        </div>
      </div>
      <div class="feedback bg-white rounded-3xl p-[6vw] lg:p-6 3xl:p-8 text-center">
        <div class="bg-gray-50 rounded-3xl px-[6vw] py-[8vw] lg:p-5">
          <div class="rating mb-8 gap-1">
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
          </div>
          <div class="response font-medium text-[27px] lg:text-sm xl:text-lg">
            "As a musician who’s trying to build her way up in the business I can testify that HitCraft has been a real
            lifesaver for me. It’s so easy to use and helps me turn my ideas into real songs try and again. Honestly, I
            already feel like a pro…"
          </div>
        </div>
        <div class="user mt-12">
          <p class="font-bold text-[6vw] lg:text-2xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl">Jess Penny</p>
          <p class="font-light text-[4vw] lg:text-lg 2xl:text-xl">Singer-songwriter</p>
        </div>
      </div>
      <div class="feedback bg-white rounded-3xl p-[6vw] lg:p-6 3xl:p-8 text-center">
        <div class="bg-gray-50 rounded-3xl px-[6vw] py-[8vw] lg:p-5">
          <div class="rating mb-8">
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
            <fa-icon class="text-yellow-300 cursor-pointer mx-1" icon="star"></fa-icon>
          </div>
          <div class="response font-medium text-[27px] lg:text-sm xl:text-lg">
            "I’ve seen a few things in the music industry, but this thing still managed to impress me. As opposed to all
            those complex softwares, HitCraft is intuitive, smart and makes the daunting production process so slik.”
          </div>
        </div>
        <div class="user mt-12">
          <p class="font-bold text-[6vw] lg:text-2xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl">Christopher Peter</p>
          <p class="font-light text-[4vw] lg:text-lg 2xl:text-xl">Musician and Sound Engineer</p>
        </div>
      </div>
    </div>
    <div class="text-center mt-[17vw] mb-[12vw] lg:my-28">
      <button (click)="navigate()" class="main-button-design gradient-purple-button 2xl:w-[290px]" id="sign-up-6">
        {{ buttonText() }}
      </button>
    </div>
  </section>
</div>
<sbz-footer></sbz-footer>  
