import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {VirtualQueueService} from '../../services/virtual-queue.service';
import {QueueInfo} from '../../interfaces/queue-Info';
import {ToastService} from '../../services/toast.service';
import {UserStateService} from '../../services/user-state.service';
import {LoadingService} from '../../services/loading.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ModalService} from '../../services/modal.service';
import {ConfirmDialogComponent} from '../modal/confirm-dialog/confirm-dialog.component';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-management-queue',
  templateUrl: './management-queue.component.html',
  styleUrl: './management-queue.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagementQueueComponent implements OnInit {
  public editSettings: boolean = false;
  public editUserId: string = '';
  public users: any = [];
  public pagination: any = {
    currentPage: 1,
    limit: 0,
    totalItems: 0,
    totalPages: 0
  };
  public queueInfo: QueueInfo = {
    queue: 0,
    fakeQueue: 0,
    totalQueue: 0,
    bufferQueue: 0,
    randomFactor: 0,
    showTotalQueue: false
  };
  public grandAccess: { from: number, to: number } = {
    from: 0,
    to: 0
  };
  public bufferQueue: number = 0;
  public sendEmail: boolean = false;
  public usersPerPage: number = 15;
  public query: string = '';

  @ViewChild(ConfirmDialogComponent) confirmDialog!: ConfirmDialogComponent;

  constructor(
    private virtualQueueService: VirtualQueueService,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private userStateService: UserStateService,
    private loadingService: LoadingService,
    private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    this.loadingService.toggleLoading(true);
    this.userStateService.getUsersWhiteList(this.pagination.currentPage).subscribe((res: any) => {
      this.users = res.data;
      this.pagination = res.pagination;
      this.virtualQueueService.getQueueInfo().subscribe((res: { code: number, data: QueueInfo, success: boolean }) => {
        this.queueInfo = res.data;
        this._calculateTotalQueue();
        this.cdr.detectChanges();
        this.loadingService.toggleLoading(false);
      });
    });
  }

  saveInfo(): void {
    this.loadingService.toggleLoading(true);
    this.queueInfo.bufferQueue = this.bufferQueue;
    this.virtualQueueService.setQueueInfo(this.queueInfo).subscribe({
      next: () => {
        this.toastService.showToast('Qeueu setting successfully saved!', 'success');
        this.editSettings = false;
        this.cdr.detectChanges();
        this.loadingService.toggleLoading(false);

      },
      error: () => {
        this.toastService.showToast('Something wrong!', 'error');
      }
    });
  }

  searchUser() {
    this.loadingService.isLoading$.next(true);
    this.userStateService.searchWhiteListUsers(this.query).subscribe(res => {
      this.users = res.data;
      this.loadingService.isLoading$.next(false);
      this.cdr.detectChanges();
    });
  }

  updateUserWhiteList(user: any, access: boolean): void {
    this.openConfirmationDialog(user, undefined).then((confirmed: boolean) => {
      if (confirmed) {
        if (access) {
          user.customAttributes.isWhiteList = false;
          user.customAttributes.queuePosition = 0;
        }
        this.userStateService.updateUserWhiteList(user, this.sendEmail, access)
          .subscribe({
            next: (res: { code: number, success: boolean }) => {
              this.toastService.showToast(`${user.name} successfully updated!`, 'success');
              this.virtualQueueService.getQueueInfo().subscribe((res: {
                code: number,
                data: QueueInfo,
                success: boolean
              }) => {
                this.queueInfo = res.data;
                this._calculateTotalQueue();
                this.cdr.detectChanges();
              });
            },
            error: (err: any) => {
              this.modalService.openErrorModal('We could not update user info. Please try again later.');
            },
            complete: () => {
              this.users = this.users.filter((u: any) => u.userId !== user.userId);
              this.cdr.detectChanges();
              this.loadingService.toggleLoading(false);
            }
          });

        this.editUserId = '';
      }
    });
  }

  grandAccesses() {
    this.openConfirmationDialog(null, {state: true, ...this.grandAccess}).then((confirmed: boolean) => {
      if (confirmed) {
        this.userStateService.grandAccesses(this.grandAccess, this.sendEmail)
          .subscribe({
            next: (res: { code: number, data: any[], success: boolean }) => {
              console.log(res);
              this.toastService.showToast(`Successfully updated!`, 'success');
              this.users = res.data;
              this.virtualQueueService.getQueueInfo().subscribe((res: {
                code: number,
                data: QueueInfo,
                success: boolean
              }) => {
                this.queueInfo = res.data;
                this._calculateTotalQueue();
                this.cdr.detectChanges();
              });
            },
            error: (err: any) => {
              this.modalService.openErrorModal('We could not update user info. Please try again later.');
            },
            complete: () => {
              this.loadingService.toggleLoading(false);
            }
          });
      }
    });
  }

  changePage(page: number) {
    this.pagination.currentPage = page;
    this.loadingService.toggleLoading(true);
    this.userStateService.getUsersWhiteList(page).subscribe((res: any) => {
      console.log(res.data);
      this.users = res.data;
      this.pagination = res.pagination;
      this.cdr.detectChanges();
      this.loadingService.toggleLoading(false);
    });
  }

  _calculateTotalQueue(): void {
    this.bufferQueue = this.queueInfo.bufferQueue + Math.round(this.queueInfo.bufferQueue * (this.queueInfo.randomFactor / 100));
  }

  async openConfirmationDialog(user: any, multiplyProvide: {
    state: boolean,
    from: number,
    to: number
  } | undefined): Promise<boolean> {
    this.confirmDialog.openDialog(user, multiplyProvide);

    const result = await new Promise<boolean>((resolve) => {
      const originalCloseDialog = this.confirmDialog.closeDialog.bind(this.confirmDialog);

      this.confirmDialog.closeDialog = (confirmed: boolean) => {
        originalCloseDialog(confirmed);
        resolve(confirmed);
      };
    });
    return result;
  }
}
