import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {BehaviorSubject, combineLatest, map} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileDetectionService {
  isMobile$ = new BehaviorSubject<boolean>(false);
  isDesktop$ = new BehaviorSubject<boolean>(false);
  shouldBounce$ = new BehaviorSubject<boolean>(false);

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  public isMobileDevice(): boolean {
    return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  startBreakpointObserver() {
    const mobileBreakpoint$ =
      this.breakpointObserver.observe(['(max-width: 768px)'])
        .pipe(map(result => result.matches));

    const desktopBreakpoint$ =
      this.breakpointObserver.observe(['(min-width: 1280px)'])
        .pipe(map(result => result.matches));

    const bounceBreakpoint$ =
      this.breakpointObserver.observe(['(max-width: 1279px)'])
        .pipe(map(result => !result.matches));

    combineLatest([mobileBreakpoint$, desktopBreakpoint$, bounceBreakpoint$])
      .subscribe(([isMobile, isDesktop, shouldBounce]) => {
        this.isMobile$.next(isMobile);
        this.isDesktop$.next(isDesktop);
        this.shouldBounce$.next(shouldBounce);
      });
  }
}
