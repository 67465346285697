@if (modalService.isEulaModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background !bg-opacity-90">
    <div class="modal-container !py-0 eula-modal">
      <p class="modal-content h-1/2 !pt-4 flex justify-center items-center">Before we continue you must accept our <br>Terms
        Of Service</p>
      <div class="flex flex-col items-center h-1/2 !pb-4">
        <div class="flex justify-around items-center w-full flex-grow gap-x-10">
          <button (click)="acceptEula(true)"
                  class="main-button-design gradient-purple-button !text-[18px] !h-[51px] !w-fit px-8">
            Accept
          </button>
          <button (click)="acceptEula(false)"
                  class="main-button-design black-button !text-[18px] !h-[51px] !w-fit px-8">
            Cancel
          </button>
        </div>
        <a class="mt-auto text-blue-600 text-xs hover:underline cursor-pointer"
           (click)="readEula()">Read Terms</a>
      </div>
    </div>
  </div>
}
