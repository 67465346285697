<p class="chat-text">Start a new session, or explore more about HitCraft</p>

<div class="chat-button-container">
  <button (click)="checkPopup()"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="gradient-purple-button">
    start the session
  </button>

  <button (click)="goToLink('articles/terms-of-use')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="chat-button-generic">
    terms of use
  </button>

  <button (click)="goToLink('about')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="chat-button-generic">
    about us
  </button>

  <button (click)="goToLink('contact')"
          [class.chat-button-generic-disabled]="!currentActiveComponent"
          class="chat-button-generic">
    contact us
  </button>
</div>
