@if (modalService.chatErrorModal$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container final-result-modal"
         [ngClass]="{'!w-[600px]': messages[(modalService.chatErrorModal$ | async)!].ctaLink}">
      @if (messages[(modalService.chatErrorModal$ | async)!].ctaLink) {
        <div class="x-container">
          <img (click)="closeModal()" alt="close modal" fill ngSrc="assets/images/close.webp">
        </div>
      }
      <h2 class="modal-header text-center">
        <strong class="font-bold"
                [innerHTML]="messages[(modalService.chatErrorModal$ | async)!].title | safeHtml"></strong>
      </h2>
      <p [innerHTML]="messages[(modalService.chatErrorModal$ | async)!].body | safeHtml" class="modal-content"></p>
      @if (messages[(modalService.chatErrorModal$ | async)!].ctaLink) {
        <button (click)="openCtaLink()"
                class="main-button-design gradient-purple-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px]">
          UPGRADE
        </button>
      } @else {
        <button (click)="closeModal()"
                class="main-button-design black-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px] !bg-[#d92525]">
          OK
        </button>
      }
    </div>
  </div>
}
