import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject, takeUntil} from 'rxjs';
import {ChatLoadingBarComponent} from '../chat-loading-bar/chat-loading-bar.component';

@Component({
  selector: 'sbz-chat-song-name',
  templateUrl: './chat-song-name.component.html',
  styleUrl: './chat-song-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatSongNameComponent extends BaseChatComponent {
  songName: string = '';
  isUploading$ = new BehaviorSubject<boolean>(false);
  @ViewChild(ChatLoadingBarComponent) loadingBarComponent!: ChatLoadingBarComponent;

  send(): void {
    if (this.songName !== '' && !this.sendClicked) {
      console.log('Total User Uploads: ', this.userStateService.totalUserUploads$.getValue());

      if (this.userStateService.totalUserUploads$.getValue() === 0) {
        console.log('openReferralModal from song name');
        this.modalService.openReferralModal(1);
      }
      this.sendClicked = true;
      this.isUploading$.next(true);
      this.scrollService.scrollToBottomClicked$.next(true);

      if (this.isDemoSite) {
        setTimeout(() => {
          this.isUploading$.next(false);
          this.audioService.songName = this.songName;
          this.goToNext('song-loading');
          // this.goToNext('lyrics-input');
        }, this.fakeCallsTimeout);
      } else {
        this.audioService.setSongName(this.songName)
          .pipe(takeUntil(this.audioService.getCancelHttpRequest$())) // Attach cancellation stream
          .subscribe({
            next: (data: any) => {
              this.loadingBarComponent.finishLoading();
              setTimeout(() => {
                this.isUploading$.next(false);
                this.audioService.songName = this.songName;
                this.goToNext('song-loading');
                // this.goToNext('lyrics-input');
              }, 3000);
            },
            error: (event: any) => {
              this.isUploading$.next(false);
              this.sendClicked = false;
              this.modalService.openErrorModal('We could not save the song name. Please try again.');
            }
          });
      }
    }
  }

  getPlaceholder() {
    return this.audioService.isChristmasGenre ? '(Jingle Bells is already taken...)' : '(Every baby needs a name...)';
  }
}
