import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ChatErrorCodes} from '../interfaces/chat-error-codes';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public isPromptModalVisible$ = new BehaviorSubject<boolean>(false);
  public isMaintenanceModalVisible$ = new BehaviorSubject<boolean>(false);
  public isErrorModalVisible$ = new BehaviorSubject<boolean>(false);
  public isEulaModalVisible$ = new BehaviorSubject<boolean>(false);
  public isCouponModalVisible$ = new BehaviorSubject<boolean>(false);
  public isPromptLandingModalVisible$ = new BehaviorSubject<boolean>(false);
  public isReferralModalVisible$ = new BehaviorSubject<boolean>(false);
  public isFinalResultModalVisible$ = new BehaviorSubject<boolean>(false);
  public isNewChatModalVisible$ = new BehaviorSubject<boolean>(false);
  public isEmailVerificationModalVisible$ = new BehaviorSubject<boolean>(false);
  public chatErrorModal$ = new BehaviorSubject<number | undefined>(undefined);
  public promptMessage: string = '';
  public promptLandingMessage: string = '';
  public referralPopUpType$ = new BehaviorSubject<1 | 2 | 3 | 4>(1);

  public errorMessage: string = '';

  constructor() {
  }

  openChatErrorModal(errorCode: ChatErrorCodes) {
    this.chatErrorModal$.next(errorCode.errorNumber);
  }

  closeChatErrorModal(): void {
    this.chatErrorModal$.next(undefined);
  }

  openPromptModal(message: string): void {
    this.promptMessage = message;
    this.isPromptModalVisible$.next(true);
  }

  openFinalResultModal(): void {
    this.isFinalResultModalVisible$.next(true);
  }

  closeFinalResultModal(): void {
    this.isFinalResultModalVisible$.next(false);
  }

  openLandingPromptModal(message: string): void {
    this.promptLandingMessage = message;
    this.isPromptLandingModalVisible$.next(true);
  }

  closeLandingPromptModal(): void {
    this.isPromptLandingModalVisible$.next(false);
  }

  openReferralModal(type: 1 | 2 | 3 | 4 = 1): void {
    this.referralPopUpType$.next(type);
    this.isReferralModalVisible$.next(true);
  }

  closeReferralModal(): void {
    this.isReferralModalVisible$.next(false);
  }

  closePromptModal(): void {
    this.isPromptModalVisible$.next(false);
  }

  openErrorModal(message: string): void {
    this.errorMessage = message;
    this.isErrorModalVisible$.next(true);
  }

  closeErrorModal(): void {
    this.isErrorModalVisible$.next(false);
  }

  openCouponModal(): void {
    this.isCouponModalVisible$.next(true);
  }

  closeCouponModal(): void {
    this.isCouponModalVisible$.next(false);
  }

  openNewChatModal(): void {
    this.isNewChatModalVisible$.next(true);
  }

  closeNewChatModal(): void {
    this.isNewChatModalVisible$.next(false);
  }

  openEulaModal(): void {
    this.isEulaModalVisible$.next(true);
  }

  closeEulaModal(): void {
    this.isEulaModalVisible$.next(false);
  }

  openEmailVerificationModal(): void {
    this.isEmailVerificationModalVisible$.next(true);
  }

  closeEmailVerificationModal(): void {
    this.isEmailVerificationModalVisible$.next(false);
  }

  openMaintenanceModal(): void {
    this.isMaintenanceModalVisible$.next(true);
  }

  closeMaintenanceModal(): void {
    this.isMaintenanceModalVisible$.next(false);
  }
}
