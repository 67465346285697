import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject, takeUntil} from 'rxjs';
import {ChatLoadingBarComponent} from '../chat-loading-bar/chat-loading-bar.component';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'sbz-chat-prompt-input',
  templateUrl: './chat-prompt-input.component.html',
  styleUrl: './chat-prompt-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatPromptInputComponent extends BaseChatComponent {
  isUploading$ = new BehaviorSubject<boolean>(false);
  genres = [
    'CHRISTMAS SONG!',
    'Pop',
    'Hip - Hop',
    'Rock',
    'Metal',
    'Electronic',
    'Country',
    'World Music',
    'Rhythm & Soul',
    'Surprise Me'
  ];
  selectedGenre = '';
  @ViewChild(ChatLoadingBarComponent) loadingBarComponent!: ChatLoadingBarComponent;

  selectGenre(genre: string): void {
    this.selectedGenre = genre;

    if (!this.sendClicked) {
      this.sendClicked = true;
      this.isUploading$.next(true);
      this.scrollService.scrollToBottomClicked$.next(true);

      this.audioService.isChristmasGenre = this.selectedGenre === 'CHRISTMAS SONG!';

      if (this.isDemoSite) {
        setTimeout(() => {
          this.isUploading$.next(false);
          this.createChatBubbleWIthText('song-name', this.getProductionText(genre));
        }, this.fakeCallsTimeout);
        return;
      }

      this.audioService.uploadPrompt(this.selectedGenre, this.isPreAlphaSite)
        .pipe(takeUntil(this.audioService.getCancelHttpRequest$())) // Attach cancellation stream
        .subscribe({
          next: (data: any) => {
            this.audioService.butcherTaskArn = data.arn;
            if (!this.isPreAlphaSite) {
              this.audioService.getDynamicButcher(data.arn);
            }
            this.loadingBarComponent.finishLoading();
            setTimeout(() => {
              this.isUploading$.next(false);
              const goToSongLoading = this.audioService.songName !== '';
              this.createChatBubbleWIthText(goToSongLoading ? 'song-loading' : 'song-name', this.getProductionText(genre));
            }, 3000);
          },
          error: (event: HttpErrorResponse) => {
            console.log('Total User Uploads: ', this.userStateService.totalUserUploads$.getValue());

            if (event.status === 421) {
              console.log('openReferralModal from prompt');

              // this.modalService.openChatErrorModal({errorNumber: 421});
              this.modalService.openReferralModal(2);
            }
            this.isUploading$.next(false);
            this.sendClicked = false;
          }
        });
    }
  }

  private getProductionText(genre: string): string {
    if (genre === 'CHRISTMAS SONG!') {
      genre = 'CHRISTMAS SONG';
    }
    // Check if the first character of the genre is a vowel (A, E, I, O, U)
    const vowels = ['A', 'E', 'I', 'O', 'U'];
    const firstLetter = genre.trim().charAt(0).toUpperCase();

    // Use "an" if the genre starts with a vowel, otherwise use "a"
    const article = vowels.includes(firstLetter) ? 'an' : 'a';


    // Return the complete sentence
    return `I'd like ${article} ${genre} style production.`;
  }
}
