<p class="text-center text-[#252931] title">Let's Finish the Sign Up - <strong>It's Free</strong></p>
<!--<div class="stepper-container hidden lg:flex">-->
<!--  <div [ngClass]="{'completed-step': activeStep > 1, 'active-step': activeStep === 1}" class="step">-->
<!--    <span class="step&#45;&#45;badge">1</span>-->
<!--    <span class="step&#45;&#45;text">Your Personal Details</span>-->
<!--  </div>-->
<!--  <span [ngClass]="{'active-splitter': activeStep > 1}" class="line-splitter"></span>-->
<!--  <div [ngClass]="{'completed-step': activeStep > 2, 'active-step': activeStep === 2}" class="step">-->
<!--    <span class="step&#45;&#45;badge">2</span>-->
<!--    <span class="step&#45;&#45;text">Social Presence</span>-->
<!--  </div>-->
<!--  <span [ngClass]="{'active-splitter': activeStep > 2}" class="line-splitter"></span>-->
<!--  <div [ngClass]="{'active-step': activeStep === 3}" class="step">-->
<!--    <span class="step&#45;&#45;badge">3</span>-->
<!--    <span class="step&#45;&#45;text">Profile Picture and Description</span>-->
<!--  </div>-->
<!--</div>-->

<!--Stepper for 360-780 px screens-->
<!--<div class="stepper-container flex lg:hidden">-->
<!--  <div class="step active-step">-->
<!--    @switch (activeStep) {-->
<!--      @case (1) {-->
<!--        <span class="step&#45;&#45;badge">1</span>-->
<!--        <span class="step&#45;&#45;text">Your Personal Details</span>-->
<!--      }-->
<!--      @case (2) {-->
<!--        <span class="step&#45;&#45;badge">2</span>-->
<!--        <span class="step&#45;&#45;text">Social Presence</span>-->
<!--      }-->
<!--      @case (3) {-->
<!--        <span class="step&#45;&#45;badge">3</span>-->
<!--        <span class="step&#45;&#45;text">Profile Picture and Description</span>-->
<!--      }-->
<!--    }-->
<!--  </div>-->
<!--</div>-->
<form (ngSubmit)="onSubmit()" [formGroup]="form"
      [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}">
  @switch (activeStep) {
    @case (1) {
      <div class="reg-input-container">
        <div class="active-one-container mb-6 lg:mb-0">
          <input type="text" placeholder="Full Name" formControlName="fullName"
                 class="input-style placeholder-[#25293166]">
          @if (getErrorForField('fullName')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">Full Name is required</span>
            </div>
          }
        </div>
        <div class="active-one-container mb-6 lg:mb-0">
          <input #phoneField type="text" placeholder="Phone Number" formControlName="phone"
                 class="input-style placeholder-[#25293166]">
          @if (getErrorForField('phone')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">A valid Phone Number is required</span>
            </div>
          }
        </div>
      </div>
      <div class="reg-input-container mb-[13px] lg:mb-0">
        <div class="active-one-container mb-6 lg:mb-0">
          <input type="email" placeholder="Email" formControlName="email" class="input-style placeholder-[#25293166]">
          @if (getErrorForField('email')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">A valid Email is required</span>
            </div>
          }
        </div>
        <div class="active-one-container">
          <!-- <select formControlName="role" class="input-style">
            <option value="">What’s your main music focus?</option>
            @for (validRole of validRoles; track validRole) {
              <option [value]="validRole" (change)="setOption(validRole)">{{ validRole }}</option>
            }
          </select> -->
          <sbz-multi-select
              [options]="validRoles"
              (selectedChange)="onSelectionChange($event)">
          </sbz-multi-select>
          @if (getErrorForField('userRoles')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">Please select your musical focus</span>
            </div>
          }
        </div>
      </div>
    }
    @case (2) {
      <!-- First Line of Inputs -->
      <div class="reg-input-container justify-between">
        <!-- Youtube Channel Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-youtube.webp" alt="YouTube"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="Youtube channel" formControlName="youtubeChannel"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('youtubeChannel')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
        <!-- Instagram URL Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-instagram.webp" alt="Instagram"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="Instagram URL" formControlName="instagramUrl"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('instagramUrl')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
      </div>

      <!-- Second Line of Inputs -->
      <div class="reg-input-container justify-between mb-[13px] lg:mb-0">
        <!-- Spotify Artist Page Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-spotify.webp" alt="Spotify"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="Spotify artist page" formControlName="spotifyArtistPage"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('spotifyArtistPage')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
        <!-- Apple Music Profile Input -->
        <div class="active-two-container">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-applemusic.webp" alt="Apple Music"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="Apple music profile" formControlName="appleMusicProfile"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('appleMusicProfile')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
      </div>
    }
    @case (3) {
      <div class="reg-input-container justify-between">
        <div class="mb-6 px-2 lg:w-[168px] lg:me-[25px] w-full">
          <sbz-file-upload fileType="image/*" [userImage]="userImage"
                           (base64ImageCreated)="base64ImageCreated($event)"></sbz-file-upload>
        </div>
        <div class="mb-6 px-2 lg:w-[535px] w-full">
           <textarea placeholder="Tell us something about yourself" formControlName="description"
                     class="input-style placeholder-[#25293166] lg:!h-[155px] sm:!h-[371px] !h-[172px] sm:pt-[40px] lg:pt-0"
                     style="resize: none;"></textarea>
        </div>
      </div>
    }
  }
</form>
