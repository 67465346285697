<div [@fadeTransition] class="article-container">
  <div class="flex flex-col lg:flex-row lg:pt-[51px] pt-[80px]">
    <div class="article-content">
      <!-- Title -->
      <p class="hidden md:block article-content__title xl:-mt-[11px] -mt-[50px]">
        Subscription Agreement
      </p>

      <!-- Introduction -->
      <p class="article-content__text">
        BY CLICKING “I AGREE”, “ACCEPT” OR OTHER SIMILAR BUTTON, OR BY INSTALLING, ACCESSING
        AND/OR USING THE SERVICE, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOU, OR THE
        COMPANY YOU REPRESENT, (“YOU” OR “CUSTOMER”) ARE ENTERING INTO A LEGAL AGREEMENT WITH
        COMPANY, AND HAVE UNDERSTOOD AND AGREE TO COMPLY WITH, AND BE LEGALLY BOUND BY, THE
        TERMS AND CONDITIONS OF THIS AGREEMENT. FURTHERMORE, YOU HEREBY WAIVE ANY RIGHTS OR
        REQUIREMENTS UNDER ANY LAWS OR REGULATIONS IN ANY JURISDICTION WHICH REQUIRE AN
        ORIGINAL (NON-ELECTRONIC) SIGNATURE OR DELIVERY OR RETENTION OF NON-ELECTRONIC
        RECORDS, TO THE EXTENT PERMITTED UNDER APPLICABLE LAW. YOU ASSUME ALL RESPONSIBILITY
        FOR THE SELECTION OF THE SOFTWARE TO ACHIEVE YOUR INTENDED RESULTS AND FOR THE
        INSTALLATION, USE AND RESULTS OBTAINED FROM THE SOFTWARE.
      </p>

      <!-- Section 1: Definitions -->
      <p class="article-content__subtitle">
        <strong>1. Definitions.</strong>
      </p>
      <p class="article-content__text">
        The following capitalized terms have the meanings set forth below:
      </p>
      <ul class="list-disc pl-5">
        <li>
          <strong>1.1 “Customer Data”</strong> means electronic data and content
          submitted to the Service by Customer and Users in
          connection with their use of the Service, excluding
          Analytics Data (as defined herein).
        </li>
        <li>
          <strong>1.2 “Feature”</strong> means any module, tool, functionality, or
          feature of the Service.
        </li>
      </ul>

      <!-- Section 2: Subscription -->
      <p class="article-content__subtitle">
        <strong>2. Subscription.</strong>
      </p>

      <!-- Subsection 2.1: Access Right -->
      <p class="article-content__subtitle">
        <strong>2.1 Access Right.</strong>
      </p>
      <p class="article-content__text">
        Subject to the terms and conditions of this Agreement, and any terms and conditions applicable to
        any service Customer elects to purchase from the Company, Company hereby grants Customer a limited,
        worldwide, non-exclusive, non-sublicensable, non-transferable and revocable right to remotely access (i.e., on
        a SaaS basis) the Company’s software-as-a-service (the “Service”) solely for Customer's internal purposes.
        Unless otherwise indicated, the term “Service” also includes any appliance and any manual or documentation
        provided or made available to Customer in connection with the operation of the Service (“Documentation”).
        Customer may use the Service subject to the use limitations specified in this Agreement and the specific
        terms and conditions of any Service offered by the Company and applicable laws and regulations.
        Customer shall be solely responsible for providing all equipment, systems, assets, access, and ancillary goods
        and services needed to access and use the Service, for ensuring their compatibility with the Service.
      </p>
      <p class="article-content__text">
        Customer is responsible for maintaining the confidentiality of their Account information and password
        and for restricting access to such information and to Customer’s computer. Customer agrees to accept
        responsibility for all activities that occur under Customer’s Account or password.
      </p>

      <!-- Subsection 2.2: Account Setup -->
      <p class="article-content__subtitle">
        <strong>2.2 Account Setup.</strong>
      </p>
      <p class="article-content__text">
        In order to access the Service, Customer is required to set up an administrative account with
        Company, by submitting the information requested in the applicable Service interface (“Account”) and to provide
        a
        user name and password. Customer warrants that all information submitted during the registration process is,
        and will thereafter remain, complete and accurate. Customer shall be responsible and liable for all activities
        that occur under or in the Account. Customer will be fully responsible and liable for any breach of this
        Agreement by
        anyone on Customer’s behalf. Without derogating from the generality of the foregoing, any unauthorized access or
        use of the Service must be immediately reported to the Company.
      </p>

      <!-- Subsection 2.3: Hosting -->
      <p class="article-content__subtitle">
        <strong>2.3 Hosting.</strong>
      </p>
      <p class="article-content__text">
        The Service is hosted by a third-party hosting services provider selected by Company (currently AWS)
        (“Hosting Provider”), and accordingly, the availability of the Service shall be in accordance with the Hosting
        Provider's then-current uptime commitments.
      </p>

      <!-- Subsection 2.4: Support and Maintenance -->
      <p class="article-content__subtitle">
        <strong>2.4 Support and Maintenance.</strong>
      </p>
      <p class="article-content__text">
        The support and maintenance services may be performed by Company, and/or Company’s certified third-party
        providers. Company shall be responsible for such service providers' performance of the support and maintenance
        services. The term Subscription shall include the services provided under the SLA and any Professional Services
        (as
        defined below). Customer acknowledges and agrees that Company may from time to time, during the Subscription
        Term, develop bug fixes, patches (“Updates”) which may remotely and automatically update and maintain the
        Service components (including if installed on Customer’s premises). For clarity, such Updates do not include any
        generally-available release of the Service that typically includes new features, functionality and/or
        enhancements
        which is subject to the payment of separate fees.
      </p>

      <!-- Section 3: Fees -->
      <p class="article-content__subtitle">
        <strong>3. Fees.</strong>
      </p>

      <!-- Subsection 3.1: Fees -->
      <p class="article-content__subtitle">
        <strong>3.1 Fees.</strong>
      </p>
      <p class="article-content__text">
        Customer shall pay Company the Subscription fees specified for the Service (the “Subscription Fees”).
      </p>

      <!-- Subsection 3.2: Other Fees -->
      <p class="article-content__subtitle">
        <strong>3.2 Other Fees.</strong>
      </p>
      <p class="article-content__text">
        Customer shall pay Company whatever other fees or charges are specified (“Other Fees”, and together
        with the Subscription Fees, the “Fees”).
      </p>

      <!-- Subsection 3.3: General -->
      <p class="article-content__subtitle">
        <strong>3.3 General.</strong>
      </p>
      <p class="article-content__text">
        Unless expressly stated otherwise herein:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a) All Fees are stated, and are to be paid, in U.S. Dollars;
        </li>
        <li>
          (b) All payments under this Agreement are non-refundable, and are without any right of set-off or
          cancellation;
        </li>
        <li>
          (c) All Fees are payable, by credit card and shall be paid immediately for use of the Service.
        </li>
      </ul>

      <!-- Subsection 3.4: Suspension -->
      <p class="article-content__subtitle">
        <strong>3.4 Suspension.</strong>
      </p>
      <p class="article-content__text">
        Company reserves the right to temporarily suspend provision of Service:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a);
        </li>
        <li>
          (b) If Company deems such suspension necessary as a result of Customer’s breach under Section 4 (Subscription
          Restrictions);
        </li>
        <li>
          (c) If Company reasonably determines suspension is necessary to avoid material harm to Company or its other
          customers, including if the Service's cloud infrastructure is experiencing denial of service attacks or other
          attacks or disruptions outside of Company’s control; or
        </li>
        <li>
          (d) As required by law or at the request of governmental entities or judicial tribunals.
        </li>
      </ul>

      <!-- Subsection 3.5: Taxes -->
      <p class="article-content__subtitle">
        <strong>3.5 Taxes.</strong>
      </p>
      <p class="article-content__text">
        Amounts payable under this Agreement are exclusive of all applicable sales, use, consumption, VAT,
        GST, and other taxes, duties or governmental charges, except for taxes based upon Company's net income. In the
        event that Customer is required by any law applicable to it to withhold or deduct taxes for any payment under
        this
        Agreement, then the amounts due to Company shall be increased by the amount necessary so that Company
        receives and retains, free from liability for any deduction or withholding, an amount equal to the amount it
        would
        have received had Customer not made any such withholding or deduction.
      </p>

      <!-- Section 4: Subscription Restrictions -->
      <p class="article-content__subtitle">
        <strong>4. Subscription Restrictions.</strong>
      </p>
      <p class="article-content__text">
        As a condition to the Subscription, and except as expressly permitted otherwise under this Agreement, Customer
        shall not do (or permit or encourage to be done) any of the following subscription restrictions (in whole or in
        part):
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a) Copy, "frame" or "mirror" the Service;
        </li>
        <li>
          (b) Sell, assign, transfer, lease, rent, sublicense, or otherwise distribute or make available the Service to
          any third party (such as offering it as part of a time-sharing, outsourcing or service bureau environment);
        </li>
        <li>
          (c) Publicly perform, display or communicate the Service;
        </li>
        <li>
          (d) Modify, alter, adapt, arrange, or translate the Service;
        </li>
        <li>
          (e) Decompile, disassemble, decrypt, reverse engineer, extract, or otherwise attempt to discover the source
          code or non-literal aspects (such as the underlying structure, sequence, organization, file formats,
          non-public APIs, ideas, or algorithms) of, the Service;
        </li>
        <li>
          (f) Remove, alter, or conceal any proprietary rights notices displayed on or in the Service;
        </li>
        <li>
          (g) Circumvent, disable or otherwise interfere with security-related or technical features or protocols of the
          Service;
        </li>
        <li>
          (h) Make a derivative work of the Service, or use it to develop any service or product that is the same as (or
          substantially similar to) it;
        </li>
        <li>
          (i) Store or transmit any robot, malware, Trojan horse, spyware, or similar malicious item intended (or that
          has the potential) to damage or disrupt the Service; or
        </li>
        <li>
          (j) Take any action that imposes or may impose (as determined in Company’s reasonable discretion) an
          unreasonable or disproportionately large load on the servers, network, bandwidth, or other cloud
          infrastructure which operate or support the Service, or otherwise systematically abuse or disrupt the
          integrity of such servers, network, bandwidth, or infrastructure.
        </li>
      </ul>

      <!-- Section 5: Mutual Warranties -->
      <p class="article-content__subtitle">
        <strong>5. Mutual Warranties.</strong>
      </p>
      <p class="article-content__text">
        Each Party represents and warrants that the execution and performance of this Agreement will not conflict with
        other agreements to which it is bound or violate applicable law.
      </p>

      <!-- Section 6: Intellectual Property Rights -->
      <p class="article-content__subtitle">
        <strong>6. Intellectual Property Rights.</strong>
      </p>

      <!-- Subsection 6.1: Service -->
      <p class="article-content__subtitle">
        <strong>6.1 Service.</strong>
      </p>
      <p class="article-content__text">
        As between the Parties, Company is, and shall be, the sole and exclusive owner of all intellectual property
        rights in and to:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a) The Service and all related software and intellectual property; and
        </li>
        <li>
          (b) Any and all improvements, derivative works, and/or modifications of/to the foregoing, regardless of
          inventorship or authorship.
        </li>
      </ul>
      <p class="article-content__text">
        Customer shall make, and hereby irrevocably makes, all assignments necessary or reasonably requested by Company
        to ensure and/or provide Company the ownership rights set forth in this paragraph. Company shall be entitled,
        from time to time, to modify and replace the Features (but not material functionalities, unless it improves the
        material functionality) and user interface of the Service. Nothing herein constitutes a waiver of Company’s
        intellectual property rights under any law.
      </p>

      <!-- Subsection 6.2: Feedback -->
      <p class="article-content__subtitle">
        <strong>6.2 Feedback.</strong>
      </p>
      <p class="article-content__text">
        If Company receives any feedback (which may consist of questions, comments, suggestions or the like) regarding
        any of the Services (collectively, “Feedback”), all rights, including intellectual property rights in such
        Feedback shall belong exclusively to Company and such shall be considered Company's Confidential Information.
        Customer hereby irrevocably and unconditionally transfers and assigns to Company all intellectual property
        rights it has in such Feedback and waives any and all moral rights that Customer may have in respect thereto. It
        is further understood that use of Feedback, if any, may be made by Company at its sole discretion, and that
        Company in no way shall be obliged to make use of the Feedback.
      </p>

      <!-- Subsection 6.3: Analytic Information -->
      <p class="article-content__subtitle">
        <strong>6.3 Analytic Information.</strong>
      </p>
      <p class="article-content__text">
        Any anonymous information, which is derived from the use of the Service (i.e., metadata, aggregated and/or
        analytics information and/or intelligence relating to the operation, support, and/or Customer’s use, of the
        Service) which is not personally identifiable information and which does not identify Customer (“Analytics
        Information”) may be used for providing the Service, for development, and/or for statistical purposes. Such
        Analytics Information is Company's exclusive property.
      </p>

      <!-- Subsection 6.4: Customer Data -->
      <p class="article-content__subtitle">
        <strong>6.4 Customer Data.</strong>
      </p>
      <p class="article-content__text">
        While using the Service, Customer Data may be made available and/or accessible to Company or the Service (the
        “Customer Data”). Customer hereby represents and warrants that Customer Data does not infringe upon any
        third-party rights, including without limitation, any copyright or other intellectual property rights. The
        Company EXPRESSLY excludes any liability on any infringement of third-party rights contained or associated with
        Customer Data, and Customer EXPRESSLY acknowledges that Company shall not be required, and will not, perform any
        scrutiny or verify whether or not Customer Data may infringe upon third-party rights. Customer EXPRESSLY
        confirms that it is the Customer’s exclusive responsibility to ensure that any outputs generated by the Service
        do not infringe on any third-party rights or breach any laws or regulations in any applicable jurisdiction. It
        is the user's responsibility to ensure that any outputs generated by the Service do not infringe on any
        third-party rights or breach any laws or regulations in any applicable jurisdiction. Customer hereby grants
        Company and its affiliates a worldwide, non-exclusive, non-assignable (except as provided herein),
        non-sublicensable (except to Company's subcontractors, if applicable), non-transferable right and license, to
        access and use the Customer Data, including without limitation for Company's provision of the Services and
        related services hereunder. As the exclusive owner of the Customer Data, Customer represents, warrants and
        covenants that to the extent the Customer Data includes any personally identifiable information, Customer has
        received and/or obtained any and all required consents or permits and has acted in compliance with any and all
        applicable privacy laws, including, without limitation privacy laws, as to allow us to receive, transfer and use
        the Customer Data in order to perform the Service as well as improvement of our Service or any other products or
        services we may have now or in the future, including without limitation by way of machine learning and
        artificial intelligence development. Company may however be required to disclose the Customer Data:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a) To satisfy any applicable law, regulation, legal process, subpoena or governmental request; or
        </li>
        <li>
          (b) To collect, store, transfer, and/or process the Customer Data through Company's affiliates, subsidiaries,
          third-party service providers and vendors as reasonably necessary to provide the Service.
        </li>
      </ul>
      <p class="article-content__text">
        Company will maintain commercially reasonable administrative, technical, and physical safeguards designed to
        protect the security, confidentiality, and integrity of Customer Data.
      </p>

      <!-- Subsection 6.5: Rights in Service Output -->
      <p class="article-content__subtitle">
        <strong>6.5 Rights in Service Output.</strong>
      </p>
      <p class="article-content__text">
        Subject to Customer’s compliance with all terms and conditions set forth in this Agreement, and in adherence to
        applicable laws, Customer shall be the owner of any intellectual property rights in the output received by the
        Customer from the Service. However, Customer acknowledges that Company makes no representation or warranty
        regarding the vesting of specific rights, including copyrights, in such output, as laws governing artificial
        intelligence generated works may vary by jurisdiction and may not recognize copyright ownership over artificial
        intelligence generated content.
      </p>
      <p class="article-content__text">
        To the extent that any rights held by Company, such as parts of compositions, arrangements, or other proprietary
        elements, are included in such output, Company retains such rights and may use and/or re-use such rights in
        other outputs provided to other customers of the Company, and Customer shall not have any claim or contention
        against Company in this respect. Nevertheless, Company hereby grants Customer a royalty-free, non-exclusive
        license to use these elements solely within the framework of the Output as received by Customer from the
        Service.
      </p>

      <!-- Subsection 6.6: Third Party Copyright Infringement -->
      <p class="article-content__subtitle">
        <strong>6.6 Third Party Copyright Infringement.</strong>
      </p>
      <p class="article-content__text">
        If you believe your work has been copied through use of our Service and/or posted on or through the Service in a
        way that constitutes copyright infringement, please send our copyright agent a notification of claimed
        infringement with all of the following information:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a) Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works
          are covered by a single notification, a representative list of such works;
        </li>
        <li>
          (b) Identification of the claimed infringing material and information reasonably sufficient to permit us to
          locate the material on our servers;
        </li>
        <li>
          (c) Information reasonably sufficient to permit us to contact you;
        </li>
        <li>
          (d) A statement by you that you have a good faith belief that the disputed use is not authorized by the
          copyright owner, its agent, or the law;
        </li>
        <li>
          (e) A statement by you, made under penalty of perjury, that the above information in your notification is
          accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf; and
        </li>
        <li>
          (f) Your signature (physical or electronic). Our copyright agent for notification of claimed infringement can
          be reached as follows: <a class="underline font-bold" href="mailto:dmca@session-42.com">dmca&#64;session-42.com</a>
        </li>
      </ul>

      <!-- Subsection 6.7: Similar Service for Customers -->
      <p class="article-content__subtitle">
        <strong>6.7 Similar Service for Customers.</strong>
      </p>
      <p class="article-content__text">
        Customer acknowledges that the output of the Service may be similar to different extents with outputs provided
        by the Service to other Customers, and that Company shall not be required to provide the Service or any output
        ensuing or derived therefrom on an exclusive basis to Customer. Customer acknowledges that similar (or even
        dissimilar) prompts given by different customers while using the Service may yield similar results and outputs
        of the Service.
      </p>

      <!-- Section 7: Third Party Components -->
      <p class="article-content__subtitle">
        <strong>7. Third Party Components.</strong>
      </p>
      <p class="article-content__text">
        The Service may use or include third-party open source software, files, libraries or components that may be
        distributed to Customer and are subject to third-party open source license terms. If there is a conflict between
        any open source license and the terms of this Agreement, then the open source license terms shall prevail but
        solely in connection with the related third-party open source software. Company makes no warranty or indemnity
        hereunder with respect to any third-party open source software. The Company makes no warranty or representation
        with respect to any third-party components. In the event the Customer is required to purchase any third-party
        license to use third-party components, then it shall be Customer's sole and absolute liability to purchase same
        from Customer’s own resources, and Company shall need not pay or compensate Customer or any third party in
        relation to such use by Customer of third-party components.
      </p>

      <!-- Section 8: Confidentiality -->
      <p class="article-content__subtitle">
        <strong>8. Confidentiality.</strong>
      </p>
      <p class="article-content__text">
        “Confidential Information” means any information disclosed by or on behalf of one Party (“Discloser”) to the
        other Party (“Recipient”) pursuant to this Agreement that is marked as “confidential,” or in some other manner
        to indicate its confidential nature. Without limiting the foregoing, the Service is Company’s Confidential
        Information. Confidential Information does not include any information which:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (i) Is or becomes generally known and available to the public through no act of the Recipient;
        </li>
        <li>
          (ii) Was already in the Recipient’s possession without a duty of confidentiality owed to the Discloser at the
          time of the Discloser’s disclosure;
        </li>
        <li>
          (iii) Is lawfully obtained by the Recipient from a third party who has the express right to make such
          disclosure; or
        </li>
        <li>
          (iv) Is independently developed by the Recipient without breach of an obligation owed to the Discloser.
        </li>
      </ul>
      <p class="article-content__text">
        The Recipient may use the Discloser’s Confidential Information solely to perform its obligations under this
        Agreement. Except as set forth in the immediately following sentence, the Recipient will not disclose the
        Discloser’s Confidential Information to any third party except to its employees, consultants, affiliates,
        agents, and subcontractors having a need to know such information to perform its obligations under this
        Agreement who have signed a non-disclosure agreement with the Recipient containing terms at least as protective
        of the Discloser’s Confidential Information as those contained herein. The Recipient may disclose the
        Discloser’s Confidential Information to the extent that such disclosure is required by law or by the order of a
        court or similar judicial or administrative body, provided that it notifies the Discloser of such required
        disclosure to enable Discloser to seek a protective order or otherwise seek to prevent or restrict such
        disclosure. All right, title, and interest in and to Confidential Information are and will remain the sole and
        exclusive property of the Discloser. The Recipient will use no less than reasonable efforts to protect the
        Discloser’s Confidential Information from unauthorized access, use, or disclosure. Notwithstanding anything to
        the contrary in this Agreement, Company’s obligations with respect to the protection of Customer Data are solely
        as set forth in Section 9.
      </p>

      <!-- Section 9: Disclaimer of Warranties -->
      <p class="article-content__subtitle">
        <strong>9. Disclaimer of Warranties.</strong>
      </p>
      <p class="article-content__text">
        Company represents and warrants that, under normal authorized use, the Service shall substantially perform in
        conformance with its Documentation. As Customer’s sole and exclusive remedy and Company’s sole liability for
        breach of this warranty, Company shall use commercially reasonable efforts to repair the Service. The warranty
        set forth herein shall not apply if the failure of the Service results from or is otherwise attributable to:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (i) Repair, maintenance or modification of the Service by persons other than Company or its authorized
          contractors;
        </li>
        <li>
          (ii) Accident, negligence, abuse or misuse of the Service;
        </li>
        <li>
          (iii) Use of the Service other than in accordance with the Documentation; or
        </li>
        <li>
          (iv) The combination of the Service with equipment or software not authorized or provided by Company.
        </li>
      </ul>
      <p class="article-content__text">
        OTHER THAN AS EXPLICITLY STATED IN THIS AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICES AND
        THE RESULTS THEREOF ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. COMPANY DOES NOT WARRANT THAT:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (i) THE SERVICES WILL MEET CUSTOMER'S REQUIREMENTS; OR
        </li>
        <li>
          (ii) THE SERVICE WILL OPERATE ERROR-FREE.
        </li>
      </ul>
      <p class="article-content__text">
        EXCEPT AS SET FORTH IN SECTION 5 AND THIS SECTION 9, THE COMPANY EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES,
        INCLUDING MERCHANTABILITY, SATISFACTORY QUALITY, TITLE, NON-INFRINGEMENT, NON-INTERFERENCE, FITNESS FOR A
        PARTICULAR PURPOSE. COMPANY WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS
        INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR FOR ISSUES RELATED TO PUBLIC NETWORKS OR
        CUSTOMER'S HOSTING SERVICES. COMPANY SHALL NOT BE RESPONSIBLE FOR ANY WARRANTIES AND REPRESENTATIONS MADE BY ANY
        PARTNER TO CUSTOMER. COMPANY EXPRESSLY DISCLAIMS ANY LIABILITY FOR ANY OUTCOMES, RESULTS, OR DATA GENERATED,
        OBTAINED, OR DERIVED FROM CUSTOMER’S USE OF THE SERVICE, INCLUDING, WITHOUT LIMITATION, ANY DECISIONS MADE OR
        ACTIONS TAKEN BY CUSTOMER IN RELIANCE ON SUCH OUTCOMES, RESULTS, OR DATA. ALL SUCH OUTCOMES, RESULTS, OR DATA
        ARE PROVIDED “AS IS,” AND CUSTOMER’S USE OF AND RELIANCE ON THE SAME ARE SOLELY AT CUSTOMER’S OWN RISK. COMPANY
        SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES, FOR ANY DAMAGES, LOSSES, OR EXPENSES ARISING OUT OF OR IN
        CONNECTION WITH CUSTOMER’S USE OF, OR RELIANCE ON, THE OUTCOMES, RESULTS, OR DATA DERIVED FROM THE SERVICE.
      </p>
      <p class="article-content__text">
        Customer shall bear the sole responsibility for the content they upload or provide as a prompt. Customer must
        ensure that such content does not infringe on any third-party rights or violate any laws. For any commercial use
        of the Outputs received by Customer from the Service, Customer must verify that there are no conflicting rights
        and that the use does not infringe on any third-party rights, including but not limited to intellectual property
        rights and privacy rights.
      </p>

      <!-- Section 10: Limitation of Liability -->
      <p class="article-content__subtitle">
        <strong>10. Limitation of Liability.</strong>
      </p>
      <p class="article-content__text">
        EXCEPT FOR ANY DAMAGES RESULTING FROM ANY BREACH OF EITHER PARTY’S CONFIDENTIALITY OBLIGATIONS HEREIN, WILLFUL
        MISCONDUCT, AND/OR CUSTOMER'S MISAPPROPRIATION OR OTHERWISE VIOLATION OF COMPANY'S INTELLECTUAL PROPERTY RIGHTS
        (INCLUDING VIOLATION OF THE SUBSCRIPTION RESTRICTIONS BY CUSTOMER); NEITHER PARTY SHALL BE LIABLE FOR ANY
        INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF REVENUE, REPUTATION, PROFITS,
        DATA, OR DATA USE, OR THE COST OF PROCURING ANY SUBSTITUTE GOODS OR SERVICES; (II) THE COMPANY’S MAXIMUM
        LIABILITY FOR ANY DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT OR TORT, OR
        OTHERWISE, SHALL IN NO EVENT EXCEED, IN THE AGGREGATE, THE TOTAL AMOUNTS ACTUALLY PAID OR PAYABLE TO COMPANY BY
        CUSTOMER IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. THIS
        LIMITATION OF LIABILITY IS CUMULATIVE AND NOT PER INCIDENT. FOR CLARITY, THE LIMITATIONS IN THIS SECTION DO NOT
        APPLY TO PAYMENTS DUE TO COMPANY UNDER THIS AGREEMENT (INCLUDING ITS EXHIBITS, IF ANY).
      </p>

      <!-- Section 11: Term and Termination -->
      <p class="article-content__subtitle">
        <strong>11. Term and Termination.</strong>
      </p>

      <!-- Subsection 11.1: Term -->
      <p class="article-content__subtitle">
        <strong>11.1 Term.</strong>
      </p>
      <p class="article-content__text">
        This Agreement commences on the first day of use of the Service by Customer and, unless terminated in accordance
        herewith, shall continue in full force and effect as long as the Customer uses the Service. To avoid doubt,
        provisions in this Agreement that by their nature are meant to apply following termination of this Agreement,
        such as Customer’s liability, intellectual property, etc., shall continue to apply without any time limitation.
      </p>

      <!-- Subsection 11.2: Termination for Breach -->
      <p class="article-content__subtitle">
        <strong>11.2 Termination for Breach.</strong>
      </p>
      <p class="article-content__text">
        The Company may terminate this Agreement immediately upon written notice to the Customer if the Customer commits
        a material breach under this Agreement.
      </p>

      <!-- Subsection 11.3: Termination for Bankruptcy -->
      <p class="article-content__subtitle">
        <strong>11.3 Termination for Bankruptcy.</strong>
      </p>
      <p class="article-content__text">
        The Company may terminate this Agreement upon written notice to the Customer upon the occurrence of any of the
        following events:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a) A receiver is appointed for the Customer or its property, which appointment is not dismissed within
          fifteen (15) days;
        </li>
        <li>
          (b) The Customer makes a general assignment for the benefit of its creditors;
        </li>
        <li>
          (c) The Customer commences, or has commenced against it, proceedings under any bankruptcy, insolvency or
          debtor’s relief law, which proceedings are not dismissed within fifteen (15) days; or
        </li>
        <li>
          (d) The Customer is liquidating, dissolving or ceasing normal business operations.
        </li>
      </ul>

      <!-- Subsection 11.4: Effect of Termination; Survival -->
      <p class="article-content__subtitle">
        <strong>11.4 Effect of Termination; Survival.</strong>
      </p>
      <p class="article-content__text">
        Upon termination of this Agreement for any reason:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (a) Customer shall cease all access and use of the Services hereunder; and
        </li>
        <li>
          (b) Customer shall (as directed) permanently erase and/or return all Confidential Information of Company in
          Customer's possession or control.
        </li>
      </ul>
      <p class="article-content__text">
        Following termination, all outstanding Fees and other charges that accrued as of termination, which become
        immediately due and payable, and if necessary, Company shall issue a final invoice therefor. The provisions of
        this Agreement that, by their nature and content, must survive the termination of this Agreement in order to
        achieve the fundamental purposes of this Agreement (including limitation of liability) shall so survive.
        Termination shall not affect any rights and obligations accrued as of the effective date of termination.
      </p>

      <!-- Section 12: Miscellaneous -->
      <p class="article-content__subtitle">
        <strong>12. Miscellaneous.</strong>
      </p>
      <p class="article-content__text">
        This Agreement represents the entire agreement between the Parties concerning the subject matter hereof,
        replaces all prior and contemporaneous oral or written understandings and statements. The Company reserves the
        right to change this Agreement or its policies relating to the Service at any time and from time to time, and
        such changes will be effective upon being posted herein. The failure of the Company to enforce any rights
        granted hereunder or to take action against the Customer in the event of any breach shall not be deemed a waiver
        by the Company as to subsequent enforcement or actions in the event of future breaches. Any waiver granted
        hereunder must be in writing. If any provision of this Agreement is held by a court of competent jurisdiction to
        be illegal, invalid or unenforceable, the remaining provisions of this Agreement shall remain in full force and
        effect and such provision shall be reformed only to the extent necessary to make it enforceable. Customer hereby
        agrees that:
      </p>
      <ul class="list-disc pl-5">
        <li>
          (i) Company may use Customer’s name and logo to identify Customer as a customer of Company or user of the
          Service, on Company’s website, presentations, marketing materials or otherwise; and
        </li>
        <li>
          (ii) Customer, to the extent requested by Company, shall use commercially reasonable efforts to positively
          address communications it receives from Company potential customers.
        </li>
      </ul>
      <p class="article-content__text">
        In addition, it is hereby agreed that Customer will cooperate with Company for creating a quote/case study that
        will be published on the Company website if requested. Following the termination of this Agreement, Customer may
        request Company to remove such customer reference. Except as stated otherwise herein, this Agreement is for the
        sole benefit of the Parties hereto and nothing herein, express or implied, shall give, or be construed to give,
        any rights hereunder to any other person. Neither Party may assign its rights or obligations under this
        Agreement without the prior written consent of the other Party, which consent may not be unreasonably withheld
        or delayed. Notwithstanding the foregoing, this Agreement may be assigned by the Company in connection with a
        merger, consolidation, sale of all of the equity interests of the Company, or a sale of all or substantially all
        of the assets of the Company. Without derogating from and subject to the abovementioned, this Agreement will
        bind and benefit each Party and its respective successors and assigns. This Agreement is governed and
        interpreted in accordance with the laws of the State of Israel, without regard to conflict of law principles.
        Any action arising out of or in any way connected with this Agreement shall be brought exclusively to the
        applicable courts of Tel-Aviv. Notwithstanding the foregoing, each Party may seek equitable relief in any court
        of competent jurisdiction in order to protect its proprietary rights. This Agreement does not, and shall not be
        construed to create any relationship, partnership, joint venture, employer-employee, agency, or
        franchisor-franchisee relationship between the Parties. Neither Party has any authority to enter into agreements
        of any kind on behalf of the other Party. Company will not be liable for any delay or failure to provide the
        Services resulting from circumstances or causes beyond the reasonable control of Company including, but not
        limited to, on account of strikes, shortages, riots, insurrection, fires, flood, storms, explosions, acts of
        God, war, government or quasi-governmental authorities actions, riot, acts of terrorism, earthquakes,
        explosions, power outages, pandemic or epidemic (or similar regional health crisis), or any other cause that is
        beyond the reasonable control of Company.
      </p>
    </div>

    <!-- Contact Us Section -->
    <div class="articles">
      <div [routerLink]="'/contact'" class="action cursor-pointer">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">Contact Us:</p>
        <p class="hidden lg:block action--subtitle">
          Need assistance? Reach out to us, and we'll be happy to assist!
        </p>
        <p class="block lg:hidden action--subtitle">
          Need assistance?<br>Reach out to us, and we'll be happy to assist!
        </p>
      </div>
      <div [routerLink]="'/articles/read-more'" class="action cursor-pointer !mt-4">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">Read More:</p>
        <p class="hidden lg:block action--subtitle">
          Read more about us and our mission
        </p>
        <p class="block lg:hidden action--subtitle">
          Read more about us and our mission
        </p>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <sbz-footer></sbz-footer>
</div>
