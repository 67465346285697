import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {environment} from './environments/environment.dev';

if (environment.production) {
  // remove console logs from production
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
