import {AfterViewInit, Directive, inject} from '@angular/core';
import {ChatService} from '../../../services/chat.service';
import {UserStateService} from '../../../services/user-state.service';
import {AudioService} from '../../../services/audio.service';
import {ModalService} from '../../../services/modal.service';
import {ScrollService} from '../../../services/scroll.service';
import {Router} from '@angular/router';

@Directive()
export class BaseChatComponent implements AfterViewInit {
  currentActiveComponent = true;
  isDemoSite = false;
  isPreAlphaSite = false;
  fakeCallsTimeout = 1000;
  sendClicked = false;
  private router = inject(Router);

  constructor(public chatService: ChatService,
              public audioService: AudioService,
              public userStateService: UserStateService,
              public modalService: ModalService,
              public scrollService: ScrollService) {
  }

  ngAfterViewInit() {
    const currentUrl = this.router.url;

    this.isDemoSite = currentUrl.includes('/demo');
    this.isPreAlphaSite = currentUrl.includes('/pre-alpha');

    this.afterViewInit();
  }

  goToNext(event: string) {
    this.switchActiveComponent(event);
  }

  createChatBubbleWIthText(event: string, text: string) {
    if (this.userStateService.user$.getValue()) {
      this.currentActiveComponent = false;
      this.chatService.createUserBubbleWIthText$.next({event, text});
    } else {
      this.router.navigate(['/login']).then();
    }
  }

  goToNextComponent(event: string, componentName: string) {
    this.switchActiveComponent(event);
    setTimeout(() => {
      this.goToNext(componentName);
    }, 1600);
  }

  protected afterViewInit(): void {
    // Derived components can implement their own logic here
  }

  private switchActiveComponent(event: string) {
    this.audioService.stopSong();
    this.currentActiveComponent = false;
    this.chatService.currentlyActiveChatPart$.next(event);
  }
}
