<div class="relative group">
  <div class="absolute top-[2px] right-[22px] cursor-pointer">
    <i class="fa-solid fa-circle-info"></i>
  </div>

  <!-- Tooltip content -->
  <div class="absolute z-10 hidden group-hover:block group-focus:block bg-gray-800
    text-white text-sm rounded-lg p-4 w-fit w-max-[400px] right-[0] top-[40px] shadow-lg">
    <h3 class="font-bold mb-2">Lyrics Submission Guidelines:</h3>
    <ul class="list-disc ml-5">
      <li>Do not include section headers or labels:
        <ul class="list-none ml-4">
          <li>* Avoid using titles like "Intro," "Verse," "Chorus," "Bridge," etc.</li>
        </ul>
      </li>
      <li>Exclude chord notations and musical symbols:
        <ul class="list-none ml-4">
          <li>* Do not include chords, tabs, or any musical notations.</li>
        </ul>
      </li>
      <li>Use proper line breaks and spacing:
        <ul class="list-none ml-4">
          <li>* Use single line breaks to separate lines within a section.</li>
          <li>* Use double line breaks to separate different sections.</li>
        </ul>
      </li>
      <li>Formatting tips:
        <ul class="list-none ml-4">
          <li>* Remove extra spaces at the beginning or end of each line.</li>
        </ul>
      </li>
      <li>Content guidelines:
        <ul class="list-none ml-4">
          <li>* Focus solely on the song's lyrics without additional comments or annotations.</li>
          <li>* Do not use ellipses (...) to indicate incomplete lines.</li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<p class="chat-text mr-[22px]">
  {{
  showLyricsChangedMessage
  ? 'We updated your lyrics to fit our format. please review them.'
  : 'Last piece of the puzzle – your lyrics! This helps me fine-tune the production (or type NA to skip this).'
  }}
</p>

@if (errorMessage !== '') {
  <p class="chat-text text-red-500">{{ errorMessage }}</p>
}

<div class="lyrics-container">
  <textarea [(ngModel)]="lyrics"
    [readonly]="!currentActiveComponent"
    rows="8"
  sbzCustomAutoFocus></textarea>

  @if (isValid) {
    <button (click)="submitLyrics()"
      [class.chat-button-generic-disabled]="!currentActiveComponent || (isUploading$ | async)"
      class="gradient-purple-button"
      id="send_lyrics">
      Send
    </button>
  } @else {
    <button (click)="verifyLyrics()"
      [class.chat-button-generic-disabled]="!currentActiveComponent || (isUploading$ | async)"
      class="gradient-purple-button sm:!w-fit"
      id="verify_lyrics">
      Check Lyrics
    </button>
  }
</div>

@if (isUploading$ | async) {
  <div>
    <sbz-chat-loading-bar [isLoading]="currentActiveComponent" text="Analyzing your lyrics..."></sbz-chat-loading-bar>
  </div>
}
