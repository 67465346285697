import {Component} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ToastMessage, ToastService} from '../../services/toast.service';

@Component({
  selector: 'sbz-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({opacity: 0, transform: 'translateY(-100%)'}),
        animate('300ms ease-in', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
      transition('* => void', [
        animate('300ms ease-out', style({opacity: 0, transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class ToastComponent {
  toastMessages: ToastMessage[] = [];

  constructor(private toastService: ToastService) {
  }

  ngOnInit() {
    this.toastService.toastState.subscribe((toast: ToastMessage) => {
      this.toastMessages.push(toast);
      setTimeout(() => this.removeToast(toast), 3000);
    });
  }

  removeToast(toast: ToastMessage) {
    this.toastMessages = this.toastMessages.filter(t => t !== toast);
  }

  getToastClass(type: 'success' | 'error' | 'info' | 'warning') {
    return {
      'bg-green-500': type === 'success',
      'bg-red-500': type === 'error',
      'bg-blue-500': type === 'info',
      'bg-yellow-500': type === 'warning'
    };
  }
}
