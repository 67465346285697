<p class="chat-text !text-center sm:!text-start">
  Hey there! I'm Hiti, your AI music producer. I convert any sketch into a
  nice polished song! Check it out:</p>

<div class="chat-white-container">
  <button (click)="toggleSong('sketch')"
          class="black-button relative">
    <svg fill="#fff" stroke="currentColor" stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2" viewBox="0 0 24 24">
      @if (sketchIsPlaying$ | async) {
        <rect x="6" y="4" width="4" height="16"/>
        <rect x="14" y="4" width="4" height="16"/>
      } @else {
        <polygon points="5 3 19 12 5 21 5 3"/>
      }
    </svg>
    I received this sketch
  </button>

  <button (click)="toggleSong('prompt')"
          class="gradient-button sm:!w-fit sm:!px-2 2xl:!w-full">
    And this prompt
  </button>

  <button (click)="toggleSong('final')"
          class="gradient-purple-button relative sm:!pl-[25px]">
    <svg fill="#fff" stroke="currentColor" stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2" viewBox="0 0 24 24">
      @if (finalIsPlaying$ | async) {
        <rect x="6" y="4" width="4" height="16"/>
        <rect x="14" y="4" width="4" height="16"/>
      } @else {
        <polygon points="5 3 19 12 5 21 5 3"/>
      }
    </svg>
    And produced this song
  </button>
</div>

<p class="chat-text">
  From sparking ideas to crafting demos or a complete track, I’m here to help bring your musical vision to life.
</p>
