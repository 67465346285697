import {AfterViewInit, ChangeDetectorRef, Component, Input, Type, ViewChild, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'sbz-system-chat-bubble',
  templateUrl: './system-chat-bubble.component.html',
  styleUrl: './system-chat-bubble.component.scss'
})
export class SystemChatBubbleComponent implements AfterViewInit {
  @Input() componentType!: Type<any>;
  @ViewChild('bubbleContainer', {read: ViewContainerRef, static: true}) bubbleContainer!: ViewContainerRef;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.loadComponent();
  }

  private loadComponent() {
    this.bubbleContainer.clear();
    this.bubbleContainer.createComponent(this.componentType);
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
}
