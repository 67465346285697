import {ChangeDetectionStrategy, Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Roles, UserStateService} from '../../services/user-state.service';
import {slideMenu} from '../../animations/slide-menu-animation';
import {LoadingService} from '../../services/loading.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  animations: [slideMenu],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  user$ = this.userStateService.user$;
  logo$ = this.userStateService.logo$;
  isLoading$ = this.loadingService.isLoading$;
  isAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  showQueue: boolean = false;

  isMenuOpen: boolean = false;

  constructor(private userStateService: UserStateService,
              private loadingService: LoadingService,
              private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      window.scrollTo(0, 0);
    });
    this.userStateService.user$.subscribe(user => {
      this.isAdmin = user?.roles ? user?.roles?.includes(Roles.Admin) : false;
      this.showQueue = user ? user.isWhiteList : false;
      this.isSuperAdmin = this.userStateService.isSuperAdmin.getValue();
    });
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  logout() {
    this.userStateService.logoUt();
  }

  navigate(): void {
    const user = this.userStateService.user$.getValue();

    if (!user) {
      this.router.navigate(['']).then(() => {
        console.log('navbar - navigate - !user');
      });
      return;
    }

    if (user.isWhiteList) {
      this.router.navigate(['']).then(() => {
        console.log('navbar - navigate - USER.isWhiteList');
      });
      return;
    }

    if (location.href.includes('verify-email')) {
      return;
    }

    if (this.userStateService.userNeedToRegister$.getValue()) {
      this.router.navigate(['/login']).then(() => {
        console.log('navbar - navigate - USER Did not finish registration');
      });
      return;
    }

    this.router.navigate(['']).then(() => {
    });
  }
}
